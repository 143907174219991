import store from "../index";
import axios from "axios";
const teacher = {
  namespaced: true,

  state: () => ({
    isLoading: false,
    announcements: [],
    resources: [],
    for: [],
    announcement: {},
    message: {
      message: "",
      status: "",
    },
    resourcesFor: [],
    error: false,
    schedule: [],
    notices: [],
  }),
  mutations: {
    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_SCHEDULE(state, value) {
      state.schedule = value;
    },
    SET_RESOURCE_FOR(state, value) {
      state.resourcesFor = value;
    },
    SET_ERROR(state, value) {
      state.error = value;
    },
    SET_MESSAGE(state, value) {
      state.message = value;
    },
    SET_RESOURCES(state, value) {
      state.resources = value;
    },
    SET_ANNOUNCEMENT(state, value) {
      state.announcement = value;
    },
    SET_ANNOUNCEMENT_FOR(state, value) {
      state.announcement = value;
    },
    SET_ANNOUNCEMENTS(state, value) {
      state.announcements = value;
    },
    SET_NOTICES(state, value) {
      state.notices = value;
    }
  },
  actions: {
    /**
     * get user created announcement
     */
    async GetMyAnnouncement({ commit }) {
      commit("SET_IS_LOADING", true);
      await axios
        .get("/announcements/teachers", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_ANNOUNCEMENTS", res.data.announcements);
          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },

    /**
     *
     * @param {Number} id
     * get what students have been assigned to the annoucement
     */
    async GetMyAnnouncementFor({ commit }, id) {
      commit("SET_IS_LOADING", true);

      await axios
        .get("/announcements/for/" + id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_ANNOUNCEMENT_FOR", res.data.annoucnements);
          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },

    UploadProfile({ commit }, data) {
      commit("SET_IS_LOADING", true);
      return axios
        // .post("/teachers/profile/" + data.id, data.a, {
        .post("/teachers/profile", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },

    CreateAnnouncement({ commit, dispatch }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .post("/announcements/" + data.type, data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_ANNOUNCEMENT", res.data.annoucnement);
          dispatch("GetMyAnnouncement");
          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },

    UpdateAnnouncement({ commit, dispatch }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .put("/announcements/" + data.id, data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_ANNOUNCEMENT", res.data.annoucnement);
          dispatch("GetMyAnnouncement");
          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },
    DeleteAnnouncement({ commit, dispatch }, id) {
      commit("SET_IS_LOADING", true);

      axios
        .delete("/announcements/" + id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          // commit("SET_MESSAGE", res.data.message);
          dispatch("GetMyAnnouncement");
          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },
    GetNotices({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/notices/for/t", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_NOTICES", res.data.notices);
          commit("SET_IS_LOADING", false);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },
    GetResources({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/teachers/resources", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_RESOURCES", res.data.resources);
          commit("SET_IS_LOADING", false);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
        });
    },
    GetME({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/me", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.auth.state.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_PROFILE", res.data.profile);

          commit("SET_IS_LOADING", false);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          err;
          // commit("SET_ERROR", true);
          // commit("SET_MESSAGE", err);

          commit("SET_IS_LOADING", false);
        });
    },
    postResource({ commit, dispatch }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .post("/teachers/resources", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          dispatch("GetResources");
          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    DeleteResource({ commit, dispatch }, id) {
      commit("SET_IS_LOADING", true);
      axios
        .delete("/teachers/resources/" + id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          // commit("SET_MESSAGE", res.data.message);
          dispatch("GetResources");

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    ResourceSharedFor({ commit }, id) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/teachers/resources/for/" + id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_RESOURCE_FOR", res.data.students);
          // commit("SET_MESSAGE", res.data.message);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    DeleteResourceForStudent({ commit, dispatch }, id) {
      console.log("loading");
      axios
        .delete("/teachers/resources/students/" + id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          // commit("SET_MESSAGE", res.data.message);
          dispatch("GetResources");

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },

    async GetMySchedule({ commit }) {
      commit("SET_IS_LOADING", true);
      await axios
        .get("/teachers/schedule", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          console.log("success");
          commit("SET_SCHEDULE", res.data.schedule);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },
    // GetResource({commit, dispatch})
  },
  getters: {},
};

export default teacher;
