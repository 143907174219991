import AuthLayout from "../../components/auth/Layout/Dashboard.vue";
import Login from "../../views/Auth/Login.vue";
import SignUp from "../../views/Auth/SignUp.vue";
import Term from "../../views/Auth/Term.vue";
import ForgetPassword from "../../views/Auth/ForgetPassword.vue";
import { authPermissions } from "../../libs/permissions";

export const authRoute = {
  path: "/auth",
  component: AuthLayout,
  redirect: { name: "login" },
  children: [
    { 
      path: "login",
      name: "login",
      component: Login,
      meta: {
        permissions: authPermissions,
      },
    },
    {
      path: "signup",
      name: "signup",
      component: SignUp,
      meta: {
        permissions: [...authPermissions],
      },
    },
    {
      path: "forget",
      name: "forget",
      component: ForgetPassword,
      meta: {
        permissions: [...authPermissions],
      },
    },
    {
      path: "terms",
      name: "term",
      component: Term,
      meta: {
        permissions: [...authPermissions],
      },
    },
  ],
};
