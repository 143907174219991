<template>
  <b-card fluid>
    <div class="pagetitle d-flex justify-content-between">
      <h1>Manage Announcements</h1>
      <b-button
        variant="primary"
        class="ml-2"
        size="sm"
        v-b-modal.add-announcement
      >
        <i class="bi bi-plus"></i> Create Announcements</b-button
      >
    </div>
    <b-row>
      <b-col md="2" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-md="12"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            class="form-control"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="8"></b-col>
      <b-col md="2" class="my-1">
        <b-form-group
          label="Initial sort"
          label-for="initial-sort-select"
          label-cols-sm="12"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="initial-sort-select"
            v-model="sortDirection"
            :options="['asc', 'desc', 'last']"
            size="sm"
            class="form-control"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12" class="my-1">
        <b-form-group
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Main table element -->
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          @click="showEditModal(row.item, $event.target)"
          class="mr-2"
        >
          <i class="bi bi-pencil"></i> Edit
        </b-button>
        <b-button
          size="sm"
          class="mx-2"
          variant="info"
          @click="row.toggleDetails"
        >
          <i class="bi bi-pencil"></i>
          {{ row.detailsShowing ? "hide details" : "Show Details" }}
        </b-button>
      </template>

      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template #row-details="row">
        <b-card class="mt-4">
          <h2 class="">{{ row.item.title }}</h2>
          <div v-html="row.item.body"></div>
          <div v-show="row.item.startTime">
            <h4 class="py-2">The Announcment is shared with</h4>
            <div class="mb-3 input-group">
              <multiselect
                id="students"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="newStudents"
                :options="options"
                class="form-control"
                placeholder="select students"
              />
              <b-button variant="primary" class="input-group-text"
                >Add Student</b-button
              >
            </div>
            <b-list-group>
              <b-list-group-item
                v-for="(student, i) in row.item.students"
                class="flex-row d-flex justify-content-between"
                :key="i"
                ><h5 class="pt-1">{{ student.f_name }} {{ student.m_name }}</h5>
                <b-button variant="danger" size="sm"
                  ><i class="ri-close-fill"></i>Remove</b-button
                >
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-card>
      </template>
    </b-table>
    <b-col class="d-flex justify-content-end">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
    </b-col>
    <!-- Info modal -->
    <b-modal
      id="edit-modal"
      title="Edit Announcement"
      hide-footer
      centered
      @hide="resetEditModal"
    >
      <!-- announcement here -->
      <div class="px-4 pb-3">
        <FormulateForm
          @submit="updateHandler"
          #default="{ isLoading }"
          class="row g-3 needs-validation"
          v-model="editModal"
        >
          <FormulateInput
            type="text"
            name="title"
            validation="required"
            label="Announcement Title"
            v-model="editModal.title"
          />

          <!-- <FormulateInput
            type="datetime-local"
            name="startTime"
            v-show="editModal.startTime"
            label="Class Start Time"
            validation="required"
          />

          <FormulateInput
            type="datetime-local"
            name="endTime"
            v-show="editModal.endTime"
            label="Class End time"
            validation="required"
          /> -->

          <div class="form-group">
            <label>Body</label>
            <quill-editor v-model="editModal.body" ref="myQuillEditor">
            </quill-editor>
          </div>
          <div class="form-group">
            <label for="students">Students the announcment is for?</label>
            <multiselect
              v-model="value"
              :options="edit_student_options"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} options selected</span
                ></template
              >
            </multiselect>
          </div>
          <div class="pt-3 form-group d-flex justify-content-between">
            <a class="btn btn-danger" @click="showConfirmDelete(editModal)"
              >Delete announcement</a
            >
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-100"
              :disabled="isLoading"
              :label="isLoading ? 'Loading...' : 'Edit announcement'"
            />
          </div>
        </FormulateForm>
      </div>
    </b-modal>
    <b-modal
      @cancle="hideEditModal"
      id="confirm-delete"
      centered
      hide-footer
      hide-header
    >
      <h4>Are you sure you want to delete this announcement?</h4>
      <div class="flex-row d-flex">
        <div class="p-1">
          <button class="btn btn-danger" @click="confirmDelete(deleteNoticeId)">
            Confirm delete
          </button>
        </div>
        <div class="p-1">
          <button class="btn btn-secondary">Cancel</button>
        </div>
      </div>
    </b-modal>

    <create-announcement :myStudents="myStudents" />
  </b-card>
</template>

<script>
import Multiselect from "vue-multiselect";
import CreateAnnouncement from "./createAnnouncement.vue";
export default {
  components: {
    CreateAnnouncement,
    Multiselect,
  },
  data() {
    return {
      noticeForm: {
        title: null,
        body: null,
      },
      students: [],
      isLoading: false,
      quillValidation: true,
      newStudents: [],
      options: [{ student_id: 1, name: "Student Alemu" }],
      fields: [
        {
          key: "title",
          label: "Title",
          tdClass: "w-75",
        },
        { key: "actions", label: "Actions" },
      ],
      // totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      editModal: {},
      deleteNoticeId: "",
      editStudentOptions: [],
      value: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      return this.$store.state.teacher.announcements;
    },
    totalRows() {
      if (this.$store.state.teacher.announcements == 0) {
        return 0;
      }
      return this.$store.state.teacher.announcements.length;
    },
    myStudents() {
      return this.$store.state.student.myStudents;
    },
    edit_student_options() {
      return this.editStudentOptions;
    },
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length;

    this.$store.dispatch("teacher/GetMyAnnouncement");
    // this.$store.dispatch("teacher/GetMyAnnouncementFor", 1)
  },
  methods: {
    showEditModal(item) {
      this.editModal = item;
      let a = item.students.map((i) => {
        return i.id;
      });
      let selectedStudents = this.myStudents.filter((m) => a.includes(m.id));
      this.value = selectedStudents.map((i) => {
        return { name: `${i.f_name} ${i.m_name} ${i.l_name}`, id: i.id };
      });
      this.editStudentOptions = this.myStudents.map((i) => {
        return { name: `${i.f_name} ${i.m_name} ${i.l_name}`, id: i.id };
      });
      this.$bvModal.show("edit-modal");
    },
    showConfirmDelete(item) {
      // console.log(item)
      this.deleteNoticeId = item.id;
      this.$bvModal.show("confirm-delete");
    },
    confirmDelete(id) {
      console.log(`${id} is deleted`);
      this.$store.dispatch("teacher/DeleteAnnouncement", id);
      this.$bvModal.hide("confirm-delete");
      this.$bvModal.hide("edit-modal");
    },
    updateHandler(data) {
      let students = [];
      for (let i = 0; i < this.value.length; i++) {
        students.push(this.value[i].id);
      }
      data.students = students;
      let q = { ...data, type: "c" };
      this.$store.dispatch("teacher/UpdateAnnouncement", q);
    },
    submitHandler(data) {
      data;
      // this.$store.dispatch("teacher/CreateAnnouncement", q);
    },
    resetEditModal() {
      this.editModal = {};
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    hideEditModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("confirm-delete");
      });
    },
  },
};
</script>