import axios from 'axios'
const utils = {
    namespaced: true,
    actions: {
        async setCSRFSanctumCookie() {
            await axios.get("/csrf-cookie");
        }
    }
}

export default utils; 