<template>
  <div>
    <div class="pagetitle">
      <h1>Home</h1>
    </div>
    <div class="section dashboard">
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col>
              <div class="card info-card revenue-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div
                      class="
                        card-icon
                        rounded-circle
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <i class="ri-account-circle-fill"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{{ stats.student }}</h6>
                      <span class="pt-2 text-muted small ps-1">Students</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="card info-card sales-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div
                      class="
                        card-icon
                        rounded-circle
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <i class="ri-user-2-line"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{{ stats.teacher }}</h6>
                      <span class="pt-2 text-muted small ps-1">Teachers</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col lg="12">
              <div class="card info-card customers-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div
                      class="
                        card-icon
                        rounded-circle
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <i class="bx bxs-notification"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{{ stats.notice }}</h6>
                      <span class="pt-2 text-muted small ps-1">Notices</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col lg="12">
              <div class="card info-card sales-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div
                      class="
                        card-icon
                        rounded-circle
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <i class="bi bi-book"></i>
                    </div>
                    <div class="ps-3">
                      <h6>{{ stats.resource }}</h6>
                      <span class="pt-2 text-muted small ps-1">Resources</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <!-- notice here -->
          <div class="px-4 pb-3 shadow card">
            <div class="pb-1 card-title">New Notice</div>
            <FormulateForm
              @submit="submitHandler"
              class="row g-3 needs-validation"
              v-model="noticeForm"
            >
              <FormulateInput
                type="text"
                name="title"
                validation="required"
                label="Notice title"
                v-model="noticeForm.title"
              />
              <FormulateInput
                type="select"
                name="for"
                :options="[
                  { value: 't', label: 'Teacher' },
                  {
                    value: 's',
                    label: 'Student',
                  },
                  {
                    value: 'a',
                    label: 'All',
                  },
                ]"
                validation="required"
                label="For"
                v-model="noticeForm.for"
              />

              <div class="form-group">
                <label>Body</label>
                <quill-editor
                  v-model="noticeForm.body"
                  @blur="onEditorBlur($event)"
                  ref="myQuillEditor"
                >
                </quill-editor>
              </div>
              <div class="pt-3 form-group d-flex justify-content-end">
                <FormulateInput
                  type="submit"
                  input-class="btn btn-primary w-100"
                  :disabled="
                    isLoading || quillValidation || noticeForm.body === null
                  "
                  :label="isLoading ? 'Loading...' : 'Post'"
                />
              </div>
            </FormulateForm>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="card">
      <div class="card-body">
        <h5 class="pl-2 card-title">Manage Notices</h5>
        <manage-notice :items="notices" />
      </div>
    </div>
  </div>
</template>

<script>
import ManageNotice from "../../components/registrar/manageNotice.vue";
export default {
  components: {
    ManageNotice,
  },
  data() {
    return {
      quillValidation: true,
      noticeForm: {
        title: null,
        body: null,
        for: "a",
      },
    };
  },
  computed: {
    notices() {
      return this.$store.state.registrar.notices;
    },
    message() {
      return this.$store.state.registrar.message;
    },
    stats() {
      return this.$store.state.registrar.stats;
    },
    isLoading() {
      return this.$store.state.registrar.isLoading;
    },
  },
  mounted() {
    this.$store.dispatch("registrar/GetNotices");
    this.$store.dispatch("registrar/GetStats");
  },
  methods: {
    onEditorBlur() {
      if (this.noticeForm.body == "") {
        this.quillValidation = true;
      } else {
        this.quillValidation = false;
      }
    },
    async submitHandler(data) {
      await this.$store
        .dispatch("registrar/PostNotice", { ...data })
        .finally(() => {
          console.log(this.message.status);
          if (this.message.status == 201 || this.message.status == 200) {
            this.$vToastify.success(this.message.message);
            this.noticeForm.title = "";
            this.noticeForm.body = "";
          } else {
            this.$vToastify.error(this.message.message);
          }
        });
    },
  },
};
</script>

<style>
</style>