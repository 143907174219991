<template>
  <Dashboard :dropDownLinks="dropDownLinks" :links="links" :user="user">
    <b-overlay :show="isLoading" rounded="sm">
      <router-view></router-view>
    </b-overlay>
  </Dashboard>
</template>

<script>
import Dashboard from "../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      links: [
        {
          text: "Home",
          icon: "bi bi-house-door",
          pathName: "registrarHome",
        },
        {
          text: "Manage Students",
          icon: "ri-account-circle-fill",
          pathName: "manageStudent",
        },
        {
          text: "Manage Teachers",
          icon: "ri-user-2-line",
          pathName: "manageTeachers",
        },
      ],

      dropDownLinks: [
        {
          text: "My Profile",
          icon: "bi bi-person",
          pathName: "studentProfile",
        },
      ],

      // user: {
      //   f_name: "Michael",
      //   m_name: "Belete",
      //   user_type: "Registrar",
      //   profile_picture:
      //     "https://media.istockphoto.com/photos/pleasant-young-indian-woman-freelancer-consult-client-via-video-call-picture-id1300972573?b=1&k=20&m=1300972573&s=170667a&w=0&h=xuAsEkMkoBbc5Nh-nButyq3DU297V_tnak-60VarrR0=",
      // },
    };
  },
  mounted() {
    this.$store.dispatch("auth/GetMe")
  },
  computed: {
    user() {
      return this.$store.state.auth.me;
    },
    isLoading() {
      return this.$store.state.registrar.isLoading;
    },
  },
};
</script>
