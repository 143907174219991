<template><h1>Hello, world</h1></template>

<script>
export default {};
</script>

<style scoped>
main {
  color: #2c3e50;
  background: #e4edff;
}
</style>