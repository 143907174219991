import Teacher from "../../components/teacher/layouts/Dashboard";
import Classroom from "../../views/teacher/classroom";
import Posts from "../../views/teacher/classroom/Posts.vue";
import Students from "../../views/teacher/classroom/Students";
import Groups from "../../views/teacher/classroom/Groups";
import Profile from "../../views/teacher/Profile";
import Schedule from "../../views/teacher/Schedule";
import Assesment from "../../views/teacher/classroom/assesment";

import { teacherPermissions } from "../../libs/permissions";

export const teacherRoute = {
  path: "/teacher",
  component: Teacher,
  children: [
    {
      path: "",
      name: "teacher-classroom",
      component: Classroom,
      meta: {
        permissions: [...teacherPermissions],
      },
      children: [
        {
          name: "teacher-students",
          path: "students",
          component: Students,
          meta: {
            permissions: [...teacherPermissions],
          },
        },
        {
          name: "teacher-groups",
          path: "groups",
          component: Groups,
          meta: {
            permissions: [...teacherPermissions],
          },
        },
      ],
    },
    {
      name: "teacher-post",
      path: "posts",
      component: Posts,
      meta: {
        permissions: [...teacherPermissions],
      },
    },

    {
      name: "teacher-profile",
      path: "profile",
      component: Profile,
      meta: {
        permissions: [...teacherPermissions],
      },
    },
    {
      name: "teacher-schedule",
      path: "schedule",
      component: Schedule,
      meta: {
        permissions: [...teacherPermissions],
      },
    },

    {
      name: "student-assesment",
      path: "assesment",
      component: Assesment,
      meta: {
        permissions: [...teacherPermissions],
      },
    },
  ],
};
