<template>
  <div>
    <div class="pagetitle">
      <h1>Profile</h1>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="index.html">Admins</a></li>
          <li class="breadcrumb-item active">Profile</li>
        </ol>
      </nav>
    </div>
    <!-- End Page Title -->

    <section class="section profile">
      <div class="row">
        <div class="col-xl-4">
          <div class="card">
            <div
              class="pt-4 card-body profile-card d-flex flex-column align-items-center"
            >
              <img
                src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
                alt="Profile"
                class="rounded-circle"
                v-if="me"
              />

              <h2>{{ me.f_name }} {{ me.m_name }} {{ me.l_name }}</h2>
              <h3>Admin</h3>
            </div>
          </div>
        </div>

        <div class="col-xl-8">
          <div class="card">
            <div class="pt-3 card-body">
              <!-- Bordered Tabs -->
              <ul class="nav nav-tabs nav-tabs-bordered">
                <li class="nav-item">
                  <button
                    class="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-overview"
                  >
                    Overview
                  </button>
                </li>

                <li class="nav-item">
                  <button
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-change-password"
                  >
                    Change Password
                  </button>
                </li>
              </ul>
              <div class="pt-2 tab-content">
                <div
                  class="tab-pane fade show active profile-overview"
                  id="profile-overview"
                >
                  <h5 class="card-title">Profile Details</h5>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">First Name</div>
                    <div class="col-lg-9 col-md-8">{{ me.f_name }}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Middle Name</div>
                    <div class="col-lg-9 col-md-8">{{ me.m_name }}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Last Name</div>
                    <div class="col-lg-9 col-md-8">{{ me.l_name }}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Email</div>
                    <div class="col-lg-9 col-md-8">{{ me.email }}</div>
                  </div>
                </div>

                <div class="pt-3 tab-pane fade" id="profile-change-password">
                  <FormulateForm
                    @submit="submitHandler"
                    #default="{ isLoading }"
                  >
                    <div class="mb-3 row">
                      <label
                        for="currentPassword"
                        class="col-md-4 col-lg-3 col-form-label"
                        >Current Password</label
                      >
                      <div class="col-md-8 col-lg-9">
                        <FormulateInput
                          name="current_password"
                          type="password"
                          placeholder="Your password"
                          validation="required"
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label
                        for="newPassword"
                        class="col-md-4 col-lg-3 col-form-label"
                        >New Password</label
                      >
                      <div class="col-md-8 col-lg-9">
                        <FormulateInput
                          name="password"
                          type="password"
                          placeholder="Your password"
                          validation="required"
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label
                        for="renewPassword"
                        class="col-md-4 col-lg-3 col-form-label"
                        >Re-enter New Password</label
                      >
                      <div class="col-md-8 col-lg-9">
                        <FormulateInput
                          name="password_confirm"
                          type="password"
                          placeholder="Confirm password"
                          validation="required|confirm"
                          validation-name="Confirmation"
                        />
                      </div>
                    </div>

                    <div class="text-center">
                      <FormulateInput
                        type="submit"
                        input-class="btn btn-primary w-100"
                        :disabled="isLoading"
                        :label="isLoading ? 'Loading...' : 'Change Password'"
                      />
                    </div>
                  </FormulateForm>
                  <!-- End Change Password Form -->
                </div>
              </div>
              <!-- End Bordered Tabs -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    me() {
      return this.$store.state.auth.me;
    },
    message() {
      return this.$store.state.auth.message2;
    },
  },
  created() {
    this.$store.dispatch("auth/GetMe");
  },
  methods: {
    async submitHandler(data) {
      await this.$store.dispatch("auth/ChangePassword", data).finally(() => {
        if (this.message.status == 201 || this.message.status == 200) {
          this.$vToastify.success(this.message.message);
        } else {
          alert(this.message.message)
          this.$vToastify.error(this.message.message);
        }
      });
    },
  },
};
</script>

<style></style>
