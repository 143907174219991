<template>
  <FormulateForm
    name="registerStudent"
    @submit="submitHandler"
    class="row g-3 needs-validation"
  >
    <div>
      <div class="row">
        <div class="col-lg-4">
          <FormulateInput
            :disabled="isLoading"
            name="f_name"
            :value="student.f_name"
            validation="required"
            alternative=""
            label="First Name"
            placeholder="First Name"
            input-classes="mb-3"
          />
        </div>
        <div class="col-lg-4">
          <FormulateInput
            :disabled="isLoading"
            name="m_name"
            validation="required"
            :value="student.m_name"
            alternative=""
            label="Father Name"
            placeholder="Father Name"
            input-classes="mb-3"
          />
        </div>
        <div class="col-lg-4">
          <FormulateInput
            :value="student.l_name"
            :disabled="isLoading"
            validation="required"
            alternative=""
            label="Grand Father Name"
            placeholder="Grand Father Name"
            input-classes="mb-3"
            name="l_name"
          />
        </div>
      </div>
      <div class="row">
        <div class="my-2 col-lg-6">
          <FormulateInput
            :disabled="isLoading"
            type="select"
            name="sex"
            :value="student.sex"
            label="Sex"
            validation="required"
            :options="{
              '': 'Select your sex',
              f: 'Female',
              m: 'Male',
            }"
          />
        </div>
        <div class="my-2 col-lg-6">
          <FormulateInput
            :disabled="isLoading"
            validation="required"
            alternative=""
            label="Age"
            name="age"
            :value="student.age"
            placeholder="Age"
            input-classes="mb-3"
          />
        </div>
      </div>
      <div class="row">
        <div class="my-2 col-lg-6">
          <FormulateInput
            :disabled="isLoading"
            type="select"
            name="education_level"
            label="Education Level"
            :value="student.education_level"
            validation="required"
            :options="{
              '': 'Select your education level',
              'No formal education': 'No formal education',
              'Primary education': 'Primary education',
              'Secondary education or high school':
                'Secondary education or high school',
              'Vocational qualification': 'Vocational qualification',
              'Bachelor degree': 'Bachelor degree',
              'Masters degree': 'Masters degree',
              'Doctorate or higher': ' Doctorate or higher',
            }"
          />
        </div>
        <div class="my-2 col-lg-6">
          <FormulateInput
            :disabled="isLoading"
            :value="student.profession"
            validation="required"
            alternative=""
            name="profession"
            label="Profession"
            placeholder="Profession"
            input-classes="mb-3"
          />
        </div>
      </div>
    </div>
    <!-- Address -->
    <h4 class="heading-small text-muted">Contact information</h4>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-12">
          <FormulateInput
            :value="student.address"
            :disabled="isLoading"
            validation="required"
            alternative=""
            label="Address"
            placeholder="Home Address"
            input-classes="mb-3"
            name="address"
          />
        </div>
      </div>

      <div class="row">
        <div class="my-2 col-lg-6">
          <FormulateInput
            :disabled="isLoading"
            :value="student.city"
            validation="required"
            alternative=""
            label="City"
            name="city"
            placeholder="City"
            input-classes="mb-3"
          />
        </div>
        <div class="my-2 col-lg-6">
          <FormulateInput
            :disabled="isLoading"
            validation="required"
            name="country"
            :value="student.country"
            alternative=""
            label="Country"
            placeholder="Country"
            input-classes="mb-3"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <h4 class="text-muted">Lesson Information</h4>
        <div class="my-2 col-lg-12">
          <FormulateInput
            :disabled="isLoading"
            validation="required"
            alternative=""
            label="What time do you prefer to learn?"
            placeholder="Time"
            type="time"
            name="time_of_the_day"
            :value="student.preferred_time"
            input-classes="mb-3"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <FormulateInput
        type="submit"
        input-class="btn btn-primary w-100"
        :disabled="isLoading"
        :label="isLoading ? 'Loading...' : 'Update'"
      />
    </div>
  </FormulateForm>
</template>




<script>
export default {
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  components: {},
  computed: {
    isLoading() {
      return this.$store.state.registrar.isLoading;
    },
    message() {
      return this.$store.state.registrar.message;
    },
  },
  methods: {
    submitHandler(data) {
      this.$store.dispatch("registrar/UpdateStudent", {
        ...data,
        student_id: this.student.id,
        user_id: this.student.user_id,
      });
      this.$bvModal.hide("student-edit-modal")
    },
  },
};
</script>