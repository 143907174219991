import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueRouter from "vue-router";
import VueRouterUserRoles from "vue-router-user-roles";

//import user roles
import { USER_ROLE_ADMIN, USER_ROLE_GUEST, USER_ROLE_REGISTRAR, USER_ROLE_STUDENT, USER_ROLE_TEACHER } from "./libs/roles";

// import Vuex from "vuex";
import VueScheduler from "v-calendar-scheduler";
import "v-calendar-scheduler/lib/main.css";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { routes } from "./router/index.js";
import store from './store/index'
import VueFormulate from "@braid/vue-formulate";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import VueApexCharts from "vue-apexcharts";
import VueToastify from "vue-toastify";
import axios from 'axios';




Vue.prototype.$axios = axios
axios.defaults.baseURL = "https://sisaybegena.com/api/api"
axios.defaults.withCredentials = true

Vue.use(VueApexCharts);
Vue.use(VueQuillEditor);
Vue.config.productionTip = false;
Vue.use(VueToastify);
Vue.use(VueRouter);
// Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueScheduler);
Vue.use(VueQuillEditor);
Vue.use(VueFormulate, {
  classes: {
    outer: "form-group",
    input: "form-control",
    inputHasErrors: "is-invalid",
    help: "form-text text-muted",
    errors: "list-unstyled text-danger",
  },
});

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
});

Vue.use(VueRouterUserRoles, { router })


axios.get("/user", {
  headers: {
    "Authorization": `Bearer ${localStorage.getItem("token") == undefined ? "" : localStorage.getItem("token")}`
  }
}).then(res => {
  let getUser;

  localStorage.setItem("me", JSON.stringify(res.data.user));
  if (res.data.user.u_type == "a") {
    getUser = { role: USER_ROLE_ADMIN }
  }
  else if (res.data.user.u_type == "t") {
    getUser = { role: USER_ROLE_TEACHER }
  }
  else if (res.data.user.u_type == "r") {
    getUser = { role: USER_ROLE_REGISTRAR }
  }
  else if (res.data.user.u_type == "s") {
    console.log("sstudent");
    getUser = { role: USER_ROLE_STUDENT }
  } else {
    getUser = { role: USER_ROLE_GUEST }
  }

  console.log(getUser)
  Vue.prototype.$user.set(Object.assign(res.data.user, getUser));
  console.log(Vue.prototype.$user.get());
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}).catch(err => {
  err;
  Vue.prototype.$user.set({ role: USER_ROLE_GUEST, });
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})
