<template>
  <div id="why-us" class="why-us">
    <div class="content">
      <div class="container">
        <h3 class="mb-0">ተቋማችንን መርዳት ይፈልጋሉ?</h3>
        <b-row>
          <b-col md="9">
            <p class="pt-3">ይርዱ የሚለውን በመጫን ተቋማችን የሚሰራውን ስራ ይደግፉ!</p></b-col
          >
          <b-col>
            <div class="text-center">
              <a href="https://fundly.com/" class="more-btn bg-dark p-3"
                >ይርዱ <i class="bx bx-chevron-right"></i
              ></a></div
          ></b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
