<template>
  <home-layout :isLanding="false" :pageInfo="pageInfo">
    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div data-aos="fade-up">
        <iframe
          style="border: 0; width: 100%; height: 350px"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.3454143956837!2d38.76650489969369!3d9.032221253665599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8589aada16ab%3A0xebe41f24abc11fbb!2z4YmF4Yu14Yi14Ym1IOGIpeGIi-GItCDhiJjhipXhjYjhiLPhi4og4Yqu4YiM4YyF!5e0!3m2!1sen!2set!4v1641247564998!5m2!1sen!2set"
          allowfullscreen
          loading="lazy"
        ></iframe>
      </div>

      <div class="container" data-aos="fade-up">
        <b-alert show variant="info" v-show="message != ''">
          {{ message }}
        </b-alert>
        <div class="row mt-5">
          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>ማሰልጠኛ እና መሸጫ አድራሻ:</h4>
                <p>አዲስ አበባ አራዳ ክፍለ ከተማ ወረዳ 9 4ኪሎ ቅድስት ሥላሴ ዩኒቨርሲቲ ግቢ ውስጥ</p>
              </div>
              <div class="address pt-4">
                <i class="bi bi-geo-alt"></i>
                <h4>ማምረቻ አድራሻ:</h4>
                <p>አዲስ አበባ ቦሌ ክፍለ ከተማ ወረዳ 06 እግዚአብሔር አብ ቤተ-ክርስቲያን ጀርባ</p>
              </div>
              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>ኢሜይል:</h4>
                <p>sisaybegena@gmail.com / sisaybegenamii@gmail.com</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>ስልክ:</h4>
                <p>
                  +251 118106144 / +251 911358560 <br />+251 118120120 / +251
                  932176622
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">
            <form
              @submit.prevent="sendEmail"
              method="post"
              role="form"
              class="php-email-form"
            >
              <div class="row">
                <div class="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    v-model="model.name"
                    placeholder="ስም"
                    required
                  />
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    v-model="model.email"
                    placeholder="ኢሜይል"
                    required
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  v-model="model.subject"
                  placeholder="ርዕሰ ጉዳይ"
                  required
                />
              </div>
              <div class="form-group mt-3">
                <textarea
                  class="form-control"
                  name="message"
                  rows="5"
                  v-model="model.message"
                  placeholder="መልእክት"
                  required
                ></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div class="text-center">
                <button type="submit">መልዕክት ላክ</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Section -->
    <cta />
  </home-layout>
</template>

<script>
import HomeLayout from "../layouts/Home.vue";
import Cta from "../components/home/cta.vue";
import emailjs from "emailjs-com";
export default {
  components: {
    "home-layout": HomeLayout,
    Cta,
  },
  data() {
    return {
      message: '',
      model: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      pageInfo: {
        title: "ያግኙን",
        description: "የተቆሙ አድራሻ",
      },
    };
  },
  methods: {
    async sendEmail(e) {
      try {
        await emailjs.sendForm(
          "service_p64o4qb",
          "template_3jkevto",
          e.target,
          "user_yK9LLvxILvoB52pm51rcB",
          {
            message: this.model.message,
            name: this.model.name,
            subject: this.model.subject,
            from_email: this.model.email,
          }
        ).then((result) => {
          this.message = "መልእክት በተሳካ ሁኔታ ተልኳል።";
          console.log(result)
        });
      } catch (error) {
        this.message = "ያልተጠበቀ ስህተት ተከስቷል እባክዎ እንደገና ይሞክሩ";
        console.log({ error });
      }
      // Reset form field
      this.model = {
        message: '',
        name: '',
        subject: '',
        email: '',
      };
    },
  },
};
</script>

<style></style>
