import store from "../index";
import axios from "axios";
const admin = {
  namespaced: true,

  state: () => ({
    isLoggedin: false,
    error: false,
    message: {
      message: "",
      status: "",
    },
    isLoading: false,
    me: {},
    blogs: [],
    blog: {},
    registrars: [],
  }),
  mutations: {
    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_MESSAGE(state, value) {
      state.message = value;
    },
    SET_BLOGS(state, value) {
      state.blogs = value;
    },
    SET_BLOG(state, value) {
      state.blog = value;
    },
    SET_REGISTRARS(state, value) {
      state.registrars = value;
    },
  },
  actions: {
    registerRegistrar({ commit }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .post("/admin/registrar", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },
    PostBlog({ commit }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .post("/admin/blogs", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    UpdateBlog({ commit, dispatch }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .put("/admin/blogs", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          dispatch("GetBlog");
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    GetBlog({ commit }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/admin/blogs", {
          withCredentials: true,
          // headers: {
          //   Authorization: `Bearer ${store.state.auth.token}`,
          // },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_BLOGS", res.data.blogs);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },
    GetRegistrars({ commit }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/admin/registrars", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_REGISTRARS", res.data.registrars);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    DeleteBlog({ commit, dispatch }, blog_id) {
      commit("SET_IS_LOADING", true);
      axios
        .delete("/admin/blogs/" + blog_id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          dispatch("GetBlog");
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_BLOGS", res.data.blogs);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    ChangeStatus({ commit, dispatch }, data) {
      commit("SET_IS_LOADING", true);

      axios.post("/admin/status", data, {
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${store.state.auth.token}`
        }
      }).then(res => {
        dispatch("GetRegistrars")
        commit("SET_MESSAGE", {
          message: res.data.message,
          status: res.status,
        });
        commit("SET_IS_LOADING", false);

      }).catch(err => {
        commit("SET_MESSAGE", {
          message: err.response,
          status: err.response.status,
        });
        commit("SET_IS_LOADING", false);

      })
    },
    UpdateRegistrar({ commit, dispatch }, data) {
      axios.put("/admin/registrar", data, {
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${store.state.auth.token}`,
          'Content-Type': 'application/json'
        }
      }).then(res => {
        commit("SET_MESSAGE", {
          message: res.data.message,
          status: res.status,
        });
        dispatch("GetRegistrars");
      }).catch(err => {
        commit("SET_MESSAGE", {
          message: err.response,
          status: err.response.status,
        });
      })
    },
    GET_ONE_BLOG({ commit }, id) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/admin/blogs/" + id)
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_BLOG", { ...res.data.blog, description: "" });

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    }
  },
  getters: {},
};

export default admin;
