<template>
  <nav class="header-nav ms-auto">
    <ul class="d-flex align-items-center">
      <li class="nav-item dropdown pe-3">
        <a
          class="nav-link nav-profile d-flex align-items-center pe-0"
          href="#"
          data-bs-toggle="dropdown"
        >
          <!-- <b-img-lazy
            :src="loggedInUser.profile_picture"
            alt="Profile"
            width="40"
            height="40"
            rounded="circle"
          /> -->
          <span class="d-none d-md-block dropdown-toggle ps-2"
            >{{ loggedInUser.f_name }} {{ loggedInUser.m_name }}</span
          > </a
        ><!-- End Profile Image Icon -->

        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
          <li class="dropdown-header">
            <h6>{{ loggedInUser.f_name }} {{ loggedInUser.m_name }}</h6>
            <span>{{ loggedInUser.user_type }}</span>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>

          <li v-for="(link, i) in dropDownLinks" :key="i">
            <router-link
              class="dropdown-item d-flex align-items-center"
              :to="{ name: link.pathName }"
            >
              <i :class="link.icon"></i>
              <span>{{ link.text }}</span>
            </router-link>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <button
              @click="logMeOut"
              class="dropdown-item d-flex align-items-center"
            >
              <i class="bi bi-box-arrow-right"></i>
              <span>Log out</span>
            </button>
          </li>
        </ul>
        <!-- End Profile Dropdown Items -->
      </li>
      <!-- End Profile Nav -->
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    dropDownLinks: {
      type: Array,
      required: true,
    },
    loggedInUser: {
      type: Object,
      required: true,
    },
  },
  methods: {
    logMeOut() {
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
</style>