<template>
  <div>
    <div class="pagetitle">
      <h1>Register Teacher</h1>
    </div>

    <div class="row">
      <div class="mb-4 col-md-4">
        <div class="shadow card">
          <div class="card-header">Profile Picture</div>
          <div class="card-body" v-if="checkUploadImage">
            <div class="">
              <div
                v-show="$refs.upload && $refs.upload.dropActive"
                class="drop-active"
              >
                <h3>Drop files to upload</h3>
              </div>
              <div class="p-2 text-center">
                <label for="avatar">
                  <img
                    :src="
                      files.length
                        ? files[0].url
                        : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
                    "
                    class="rounded-circle img-fluid"
                  />
                  <h4 class="pt-2">or<br />Drop files anywhere to upload</h4>
                </label>
              </div>
              <div class="p-2 text-center" v-show="files.length !== 0">
                <button
                  type="submit"
                  class="mx-2 btn btn-success btn-sm"
                  @click.prevent="confirmProfile"
                >
                  Confirm Profile
                </button>
                <button
                  type="button"
                  class="mx-2 btn btn-danger btn-sm"
                  @click.prevent="cancelUpload"
                >
                  Cancel
                </button>
              </div>
            </div>
            <form enctype="multipart/form-data" @submit="upload">
              <div class="dropbox">
                <input
                  type="file"
                  name="Picture"
                  :disabled="passToForm"
                  @change="filesChange($event.target.name, $event.target.files)"
                  accept="image/*"
                  class="input-file"
                />
                <!-- <p> -->
                <!-- Drag your file(s) here to begin<br /> -->
                <!-- or click to browse -->
                <!-- </p> -->
                <!-- <p v-if="isSaving">Uploading {{ fileCount }} files...</p> -->

                <!-- <button :disabled="passToForm" type="submit">upload</button> -->
              </div>
            </form>
          </div>
          <div v-else>
            <h1>Image Uploaded</h1>
          </div>
        </div>
      </div>
      <div
        v-if="checkUploadImage"
        :class="pass ? 'd-block col-md-8' : 'd-none col-md-8'"
      >
        <div class="shadow card">
          <div class="card-header">Fill Out Your Information</div>
          <div class="card-body d-flex align-items-start">
            <FormulateForm
              @submit="submitHandler"
              #default="{ isLoading }"
              class="row g-3 needs-validation"
            >
              <h4 class="heading-small text-muted">User information</h4>
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <FormulateInput
                      name="f_name"
                      validation="required"
                      alternative=""
                      label="First Name"
                      placeholder="First Name"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="col-lg-4">
                    <FormulateInput
                      name="m_name"
                      validation="required"
                      alternative=""
                      label="Father Name"
                      placeholder="Father Name"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="col-lg-4">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      label="Grand Father Name"
                      placeholder="Grand Father Name"
                      input-classes="mb-3"
                      name="l_name"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      type="select"
                      name="sex"
                      label="Sex"
                      validation="required"
                      :options="{
                        '': 'Select your sex',
                        f: 'Female',
                        m: 'Male',
                      }"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      label="Age"
                      name="age"
                      type="number"
                      placeholder="Age"
                      input-classes="mb-3"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      type="select"
                      name="education_level"
                      label="Education Level"
                      validation="required"
                      :options="{
                        '': 'Select your education level',
                        'No formal education': 'No formal education',
                        'Primary education': 'Primary education',
                        'Secondary education or high school':
                          'Secondary education or high school',
                        'Vocational qualification': 'Vocational qualification',
                        'Bachelor degree': 'Bachelor degree',
                        'Masters degree': 'Masters degree',
                        'Doctorate or higher': ' Doctorate or higher',
                      }"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      name="profession"
                      label="Profession"
                      placeholder="Profession"
                      input-classes="mb-3"
                    />
                  </div>
                </div>
              </div>
              <!-- Address -->
              <h4 class="heading-small text-muted">Contact information</h4>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      label="Address"
                      placeholder="Home Address"
                      input-classes="mb-3"
                      name="address"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="row">
                    <div class="my-2 col-lg-6">
                      <FormulateInput
                        validation="email"
                        alternative=""
                        label="Email"
                        name="email"
                        placeholder="City"
                        input-classes="mb-3"
                      />
                    </div>
                    <div class="my-2 col-lg-6">
                      <FormulateInput
                        name="password"
                        alternative=""
                        type="password"
                        validation="^required|min:6,length"
                        :validation-messages="{
                          matches: 'Passwords must be at least 6 characters.',
                        }"
                        label="Password"
                        placeholder="password"
                        input-classes="mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- :disabled="
                    isLoading || lesson_type == null || lesson_type == ''
                  " -->
              <div class="d-flex justify-content-end">
                <FormulateInput
                  type="submit"
                  input-class="btn btn-primary w-100"
                  :label="isLoading ? 'Loading...' : 'Register'"
                />
              </div>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      files: [],
      edit: false,
      options: ["Begena", "Krar", "Masenqo"],
      lesson_type: "",
      passToForm: false,
      isSaving: false,
      f: [],
    };
  },
  computed: {
    checkUploadImage() {
      return true;
    },
    pass() {
      return this.passToForm;
    },
  },
  methods: {
    alert(message) {
      alert(message);
    },
    submitHandler(data) {
      const sent = {
        ...data,
        // lesson_type: lesson(),
        // lesson: lesson(),
        // type: "p",
      };

      sent;
      this.$store.dispatch("registrar/CreateTeacher", sent).then((res) => {
        console.log(res);
        console.log(res);
        let a = new FormData();
        a.append("profileImg", this.f[0]);
        a.append("user_id", res);
        this.$store.dispatch("teacher/UploadProfile", a);

        this.$router.back();
      });
      // this.$store
      // .dispatch("registrar/CreateTeacher", sent)
      // .finally((res) => {
      //   res;
      //   let a = new FormData();
      //   a.append("profileImg", this.f[0]);
      //   a.append("user_id", res.data.student);
      //   this.$store
      //     .dispatch("teacher/UploadProfile", { data: a, id: res })
      //     .then((res) => {
      //       res;
      //     });

      // })
      // .catch((e) => {
      //   e;
      // });
    },
    // eslint-disable-next-line no-unused-vars
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert("Your choice is not a picture");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    confirmProfile() {
      alert("yes");
    },
    cancelUpload() {
      this.$refs.upload.clear();
      this.files = [];
      this.passToForm = false;
      this.f = [];
    },

    filesChange(e, f) {
      console.log(f);
      this.f = f;
      this.passToForm = true;
    },

    upload(e) {
      e.preventDefault();

      let a = new FormData();
      a.append("profileImg", this.f[0]);
      this.$store.dispatch("student/UploadProfile", a).then((res) => {
        res;
        this.passToForm = true;
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>