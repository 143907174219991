<template>
  <div>
    <div class="pagetitle d-flex justify-content-between">
      <h1>New post</h1>
      <button @click="discardPost" class="btn btn-danger">Discard Post</button>
    </div>

    <b-card>
      <FormulateForm
        @submit="submitHandler"
        #default="{ isLoading }"
        name="create-blog"
        class="row g-3 needs-validation"
        v-model="postForm"
      >
        <FormulateInput
          type="text"
          name="title"
          validation="required"
          label="post title"
          v-model="postForm.title"
        />
        <label for="file">Header Picture</label>
        <input
          type="file"
          @change="filesChange($event.target.name, $event.target.files)"
          name="file"
          id="file"
          label="Select your documents to upload"
          help="Select one PDF document upload"
          required
          validation="mime:application/pdf"
        />
        <div class="form-group">
          <label>Body</label>
          <quill-editor
            v-model="postForm.body"
            @blur="onEditorBlur($event)"
            ref="myQuillEditor"
          >
          </quill-editor>
        </div>
        <div class="pt-3 form-group d-flex justify-content-end">
          <FormulateInput
            type="submit"
            input-class="btn btn-primary w-100"
            :disabled="isLoading || quillValidation || postForm.body === null"
            :label="isLoading ? 'Loading...' : 'Post'"
          />
        </div>
      </FormulateForm>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      quillValidation: true,
      postForm: {
        title: null,
        body: null,
      },
      f: [],
    };
  },
  computed: {
    message() {
      return this.$store.state.admin.message;
    },
  },
  methods: {
    onEditorBlur() {
      if (this.postForm.body == "") {
        this.quillValidation = true;
      } else {
        this.quillValidation = false;
      }
    },

    async submitHandler(data) {
      let a = new FormData();
      a.append("file", this.f[0]);
      a.append("title", data.title);
      a.append("body", data.body);
      this.$store.dispatch("admin/PostBlog", a).finally(() => {
        if (this.message.status == 201 || this.message.status == 200) {
          this.$router.back();
          this.$vToastify.success(this.message.message);
        } else {
          this.$vToastify.error(this.message.message);
        }
        this.f = [];
        this.postForm.title = null;
        this.postForm.body = null;
      });
    },
    filesChange(e, f) {
      console.log(f);
      this.f = f;
      this.passToForm = true;
    },
    discardPost() {
      this.$formulate.reset("create-blog");
    },
  },
};
</script>

<style>
.ql-editor {
    min-height: 200px;
}
</style>
