import store from "../index";
import axios from "axios";
const registrar = {
  namespaced: true,

  state: () => ({
    isLoading: false,
    stats: {},
    students: [],
    unassignedStudents: [],
    student: {},
    teachers: [],
    teacher: {},
    notice: {},
    notices: [],
    message: {
      message: "",
      status: "",
    },
    studentTeachers: [],
    UnassignedTeachers: [],
  }),
  mutations: {
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_STATS(state, stats) {
      state.stats = stats;
    },
    SET_STUDENTS(state, students) {
      state.students = students;
    },
    SET_STUDENT(state, student) {
      state.student = student;
    },
    SET_TEACHERS(state, teachers) {
      state.students = teachers;
    },
    SET_NOTICE(state, notice) {
      state.notice = notice;
    },
    SET_NOTICES(state, notices) {
      state.notices = notices;
    },
    SET_TEACHER(state, teacher) {
      state.teacher = teacher;
    },
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    SET_UNASSIGNED_STUDENTS(state, unassignedStudents) {
      state.unassignedStudents = unassignedStudents;
    },
    SET_STUDENT_TEACHERS(state, teachers) {
      state.studentTeachers = teachers;
    },
    SET_UNASSIGNED_TEACHERS(state, teachers) {
      state.UnassignedTeachers = teachers;
    },
  },
  actions: {
    GetStats({ commit }) {
      axios
        .get("/registrar/stats", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", res.data.message);
          commit("SET_STATS", res.data);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },
    GetNotices({ commit }) {
      commit("SET_LOADING", true);
      axios
        .get("/notices", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", res.data.message);
          commit("SET_NOTICES", res.data.notices);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },
    GetNotice({ commit }, id) {
      axios
        .get("/notices/" + id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", res.data.message);
          commit("SET_NOTICES", res.data.notices);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },
    GetNoticeFor({ commit }, data) {
      // data shoud be in a form of data.id and data.for
      axios
        .get("/notices/" + data.id + "/" + data.for, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", res.data.message);
          commit("SET_NOTICES", res.data.notices);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },
    async PostNotice({ commit, dispatch }, notice) {
      commit("SET_LOADING", true);
      await axios
        .post("/notices", notice, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_NOTICE", res.data.notices);
          commit("SET_LOADING", false);
          dispatch("GetNotices");
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response.data.message.title.pop(),
            status: err.response.status,
          });
          commit("SET_LOADING", false);
        });
    },
    async UpdateNotice({ commit, dispatch }, notice) {
      commit("SET_LOADING", true);
      await axios
        .put("/notices", notice, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_NOTICE", res.data.notices);
          commit("SET_LOADING", false);
          dispatch("GetNotices");
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_LOADING", false);
        });
    },
    async DeleteNotice({ commit, dispatch }, id) {
      commit("SET_LOADING", true);
      await axios
        .delete(`/notices/${id}`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_NOTICE", res.data.notices);
          commit("SET_LOADING", false);
          dispatch("GetNotices");
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_LOADING", false);
        });
    },
    async CreateStudent({ commit }, student) {
      commit("SET_LOADING", true);
      return await axios
        .post("/students", student, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_STUDENT", res.data.student);
          commit("SET_LOADING", false);

          return res;
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_LOADING", false);
        });
    },
    async CreateTeacher({ commit }, teacher) {
      commit("SET_LOADING", true)
      return await axios
        .post("/teachers", teacher, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_LOADING", false)
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          // commit("SET_TEACHER", res.data.teacher);
          return res.data.id;
        })
        .catch((err) => {
          err;
          commit("SET_LOADING", false)
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
        });
    },
    GetTeachers({ commit }) {
      axios
        .get("/teachers", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", res.data.message);
          commit("SET_TEACHERS", res.data.teachers);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },
    GetStudents({ commit }) {
      commit("SET_LOADING", true);
      axios
        .get("/students", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("----------Success---------------");
          console.log(res.data);
          console.log("----------Success---------------");
          commit("SET_MESSAGE", res.data.message);
          commit("SET_STUDENTS", res.data.students);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
          commit("SET_LOADING", false);
        });
    },

    GetStudent({ commit }, student_id) {
      commit("SET_LOADING", true);
      axios
        .get("/students/" + student_id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("GetStudent");
          console.log(res.data);
          console.log("GetStudent");
          commit("SET_MESSAGE", res.data.message);
          commit("SET_STUDENT", res.data.student);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
          commit("SET_LOADING", false);
        });
    },
    GetUnassignedTeacher({ commit }) {
      axios
        .get("/teachers/unassigned", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("GetStudent");
          console.log(res.data);
          console.log("GetStudent");
          commit("SET_MESSAGE", res.data.message);
          commit("SET_STUDENT", res.data.student);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },
    GetUnassignedStudents({ commit }) {
      axios
        .get("/students/unassigned", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", res.data.message);
          commit("SET_UNASSIGNED_STUDENTS", res.data.students);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },
    AssignTeacher({ commit }, student_id) {
      return axios
        .get("/students/assign/" + student_id, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("GetStudent");
          console.log(res.data);
          console.log("GetStudent");
          commit("SET_MESSAGE", res.data.message);
          commit("SET_STUDENT", res.data.student);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },

    GetStudentTeachers({ commit }, studentId) {
      commit("SET_LOADING", true);

      return axios
        .get("/students/teachers/" + studentId, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", res.data.message);
          commit("SET_STUDENT_TEACHERS", res.data.teachers);
          commit("SET_LOADING", false);
        })
        .catch((e) => {
          e;
          commit("SET_MESSAGE", "Something went wrong");
          commit("SET_LOADING", false);
        });
    },
    GetUnassignedTeachers({ commit }, studentId) {
      commit("SET_LOADING", true);

      return axios
        .get("/teachers/unassigned/" + studentId, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", res.data.message);
          commit("SET_UNASSIGNED_TEACHERS", res.data.teachers);
          commit("SET_LOADING", false);
        })
        .catch((e) => {
          e;
          commit("SET_MESSAGE", "Something went wrong");
          commit("SET_LOADING", false);
        });
    },

    ChangeStatus({ commit,
      // dispatch 
    }, data) {
      axios.post("/admin/status", data, {
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${store.state.auth.token}`
        }
      }).then(res => {
        commit("SET_MESSAGE", {
          message: res.data.message,
          status: res.status,
        });
        // dispatch("GetStudents")
      }).catch(err => {
        err;
        commit("SET_MESSAGE", {
          message: err.response,
          status: err.response.status,
        });
      })
    },
    UpdateRegistrar({ commit, dispatch }, data) {
      axios.put("/students", data, {
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${store.state.auth.token}`
        }
      }).then(res => {
        commit("SET_MESSAGE", {
          message: res.data.message,
          status: res.status,
        });
        dispatch("GetStudents")
      }).catch(err => {
        err;
        commit("SET_MESSAGE", {
          message: err.response,
          status: err.response.status,
        });
      })
    },
    UpdateStudent({ commit, dispatch }, data) {
      axios.put("/students", data, {
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${store.state.auth.token}`
        }
      }).then(res => {
        commit("SET_MESSAGE", {
          message: res.data.message,
          status: res.status,
        });
        dispatch("GetStudents")
      }).catch(err => {
        err;
        commit("SET_MESSAGE", {
          message: err.response,
          status: err.response.status,
        });
      })
    },
    updateTeacher({ commit, dispatch }, data) {
      axios.put("/teachers", data, {
        withCredentials: true,
        headers: {
          "Authorization": `Bearer ${store.state.auth.token}`
        }
      }).then(res => {
        commit("SET_MESSAGE", {
          message: res.data.message,
          status: res.status,
        });
        dispatch("GetStudents")
      }).catch(err => {
        err;
        commit("SET_MESSAGE", {
          message: err.response,
          status: err.response.status,
        });
      })
    },


  },
  getters: {},
};

export default registrar;
