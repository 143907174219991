<template>
  <home-layout :isLanding="false" :pageInfo="blog">
    <section id="about" class="about">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="row">
          <div
            class="col-lg-12 d-flex item-center justify-content-center mb-4"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <img
              :src="
                'https://sisaybegena.com/api/api/file/blogImg/' +
                blog.image_name
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div v-html="blog.body" class="col-lg-12 content py-4"></div>
        </div>
      </div>
    </section>
  </home-layout>
</template>

<script>
import HomeLayout from "../layouts/Home.vue";
export default {
  components: {
    "home-layout": HomeLayout,
  },
  created() {
    this.$store.dispatch("admin/GET_ONE_BLOG", this.$route.params.id);
  },
  computed: {
    blog() {
      // if (this.$store.state.admin.blog) {
      // return {
      // title: this.$store.state.admin.blog.,
      // description: "",
      // };
      // }
      return this.$store.state.admin.blog;
    },
  },
  data() {
    return {
      pageInfo: {
        title: "Blog title",
        description: "",
      },
    };
  },
};
</script>

<style>
</style>