<template>
  <div class="section dashboard">
    <div class="shadow card">
      <div>
        <ul
          class="nav nav-tabs d-flex justify-content-center"
          id="myTab"
          role="tablist"
        >
          <li class="nav-item flex-full" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#announcement"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Announcement
            </button>
          </li>
          <li class="nav-item flex-full" role="presentation">
            <button
              class="nav-link"
              id="resource-tab"
              data-bs-toggle="tab"
              data-bs-target="#resource"
              type="button"
              role="tab"
              aria-controls="resource"
              aria-selected="false"
            >
              Resources
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="pt-2 tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="announcement"
            role="tabpanel"
            aria-labelledby="announcement-tab"
          >
            <announcements />
          </div>
          <div
            class="tab-pane fade"
            id="resource"
            role="tabpanel"
            aria-labelledby="resource-tab"
          >
            <resources />
          </div>
        </div>
        <!-- End Default Tabs -->
      </div>
    </div>
  </div>
</template>

<script>
import Announcements from "../../../components/student/Announcement.vue";
import Resources from "../../../components/student/Resources.vue";
export default {
  components: {
    Announcements,
    Resources,
  },

  // mounted()
};
</script>

<style scoped>
.nav-tabs {
  border: none;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 5px solid #666;
  font-weight: bold;
}
</style>