import RegistrarLayout from "../../components/registrar/Layout/Dashboard.vue";
import Home from "../../views/Registrar/index.vue";
import ManageStudent from "../../views/Registrar/ManageStudent.vue";
import ManageTeacher from "../../views/Registrar/ManageTeacher.vue";
import RegisterStudent from "../../views/Registrar/RegisterStudent.vue";
import RegisterTeacher from "../../views/Registrar/RegisterTeacher.vue";

import { registrarPermissions } from "../../libs/permissions";

export const registrarRoute = {
  path: "/registrar",
  component: RegistrarLayout,
  children: [
    {
      path: "",
      name: "registrarHome",
      component: Home,
      meta: {
        permissions: [...registrarPermissions],
      },
    },
    {
      path: "students",
      name: "manageStudent",
      component: ManageStudent,
      meta: {
        permissions: [...registrarPermissions],
      },
    },
    {
      path: "teachers",
      name: "manageTeachers",
      component: ManageTeacher,
      meta: {
        permissions: [...registrarPermissions],
      },
    },
    {
      path: "registerStudent",
      name: "registerStudent",
      component: RegisterStudent,
      meta: {
        permissions: [...registrarPermissions],
      },
    },
    {
      path: "registerTeacher",
      name: "registerTeacher",
      component: RegisterTeacher,
      meta: {
        permissions: [...registrarPermissions],
      },
    },
  ],
};
