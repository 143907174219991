<template>
  <Dashboard :dropDownLinks="dropDownLinks" :links="links" :user="user">
    <b-overlay :show="isLoading" rounded="sm">
      <router-view></router-view>
    </b-overlay>
  </Dashboard>
</template>

<script>
import Dashboard from "../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      links: [
        {
          text: "Class Rooms",
          icon: "bi bi-door-open",
          pathName: "classRooms",
        },
        {
          text: "Schedule",
          icon: "bi bi-calendar-week",
          pathName: "schedule",
        },
        {
          text: "Profile",
          icon: "bi bi-person",
          pathName: "studentProfile",
        },
      ],

      dropDownLinks: [
        {
          text: "My Profile",
          icon: "bi bi-person",
          pathName: "studentProfile",
        },
        {
          text: "My Schedule",
          icon: "bi bi-calendar-date",
          pathName: "schedule",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("auth/GetMe");
  },
  computed: {
    user() {
      return this.$store.state.auth.me;
    },
    isLoading() {
      return this.$store.state.student.isLoading;
    },
  },
};
</script>

<style scoped>
.sidebar {
  padding: 0px;
}
.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  text-align: center;
  font-family: "Nunito", sans-serif;
}
</style>
