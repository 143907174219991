

<template>
  <div>
    <div class="pagetitle">
      <h1>Profile</h1>
    </div>
    <!-- End Page Title -->

    <section class="section profile">
      <div class="row">
        <div class="col-xl-4">
          <div class="card">
            <div
              class="
                pt-4
                card-body
                profile-card
                d-flex
                flex-column
                align-items-center
              "
              v-if="user != undefined"
            >
              <img
                v-if="user.profile_url"
                :src="
                  'https://sisaybegena.com/api/api/file/' +
                  'studentProfile' +
                  '/' +
                  user.profile_url
                "
                alt="Profile"
                class="rounded-circle"
              />
              <img
                v-else
                src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
                alt="Profile"
                class="rounded-circle"
              />

              <h2>{{ user.f_name }} {{ user.m_name }} {{ user.l_name }}</h2>
              <h3>Student</h3>
            </div>
          </div>
        </div>

        <div class="col-xl-8">
          <div class="card">
            <div class="pt-3 card-body">
              <!-- Bordered Tabs -->
              <ul class="nav nav-tabs nav-tabs-bordered">
                <li class="nav-item">
                  <button
                    class="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-overview"
                  >
                    Overview
                  </button>
                </li>

                <li class="nav-item">
                  <button
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-change-password"
                  >
                    Change Password
                  </button>
                </li>
              </ul>
              <div v-if="user != undefined" class="px-2 pt-2 tab-content">
                <div
                  class="tab-pane fade show active profile-overview"
                  id="profile-overview"
                >
                  <h5 class="card-title">Profile Details</h5>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Full Name</div>
                    <div class="col-lg-9 col-md-8">
                      {{ user.f_name }} {{ user.m_name }} {{ user.l_name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Sex</div>
                    <div class="col-lg-9 col-md-8">Male</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Age</div>
                    <div class="col-lg-9 col-md-8">{{ user.age }}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Education Level</div>
                    <div class="col-lg-9 col-md-8">
                      {{ user.education_level }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Profession</div>
                    <div class="col-lg-9 col-md-8">{{ user.profession }}</div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-lg-3 col-md-4 label">Country</div>
                    <div class="col-lg-9 col-md-8">USA</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">City</div>
                    <div class="col-lg-9 col-md-8">Addis Ababa</div>
                  </div> -->
                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Address</div>
                    <div class="col-lg-9 col-md-8">
                      {{ user.address }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Phone</div>
                    <div class="col-lg-9 col-md-8">{{ user.phone }}</div>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-4 label">Email</div>
                    <div class="col-lg-9 col-md-8">{{ user.email }}</div>
                  </div>
                </div>

                <div class="pt-3 tab-pane fade" id="profile-change-password">
                  <!-- Change Password Form -->
                  <FormulateForm
                    @submit="submitHandler"
                    #default="{ isLoading }"
                  >
                    <div class="mb-3 row">
                      <label
                        for="currentPassword"
                        class="col-md-4 col-lg-3 col-form-label"
                        >Current Password</label
                      >
                      <div class="col-md-8 col-lg-9">
                        <FormulateInput
                          name="current_password"
                          type="password"
                          placeholder="Your password"
                          validation="required"
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label
                        for="newPassword"
                        class="col-md-4 col-lg-3 col-form-label"
                        >New Password</label
                      >
                      <div class="col-md-8 col-lg-9">
                        <FormulateInput
                          name="password"
                          type="password"
                          placeholder="Your password"
                          validation="required"
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label
                        for="renewPassword"
                        class="col-md-4 col-lg-3 col-form-label"
                        >Re-enter New Password</label
                      >
                      <div class="col-md-8 col-lg-9">
                        <FormulateInput
                          name="password_confirm"
                          type="password"
                          placeholder="Confirm password"
                          validation="required|confirm"
                          validation-name="Confirmation"
                        />
                      </div>
                    </div>

                    <div class="text-center">
                      <FormulateInput
                        type="submit"
                        input-class="btn btn-primary w-100"
                        :disabled="isLoading"
                        :label="isLoading ? 'Loading...' : 'Change Password'"
                      />
                    </div>
                  </FormulateForm>
                  <!-- End Change Password Form -->
                </div>
              </div>
              <!-- End Bordered Tabs -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch("auth/GetMe");
  },
  data: () => ({}),
  methods: {
    submitHandler(data) {
      // data

      this.$store.dispatch("auth/ChangePassword", data).finally(() => {
        if (this.message.status == 201 || this.message.status == 200) {
          this.$vToastify.success(this.message.message);
          this.$bvModal.hide("confirm-delete");
          this.$bvModal.hide("edit-modal");
        } else {
          this.$vToastify.error(this.message.message);
        }
      });
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.me;
    },
    message() {
      return this.$store.state.auth.message2;
    },
  },
};
</script>

