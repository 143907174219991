<template>
  <div>
    <div class="pagetitle">
      <h1>Fill out to register Registrar</h1>
    </div>
    <div class="shadow card">
      <div class="card-header">Fill Out Your Information</div>
      <div class="card-body d-flex align-items-start">
        <FormulateForm
        name = "register-registrar-form"
          @submit="submitHandler"
          #default="{ isLoading }"
          class="row g-3 needs-validation"
        >
          <h4 class="heading-small text-muted">User information</h4>
          <div>
            <div class="row">
              <div class="col-lg-4">
                <FormulateInput
                  name="f_name"
                  validation="required"
                  alternative=""
                  label="First Name"
                  placeholder="First Name"
                  input-classes="mb-3"
                />
              </div>
              <div class="col-lg-4">
                <FormulateInput
                  name="m_name"
                  validation="required"
                  alternative=""
                  label="Father Name"
                  placeholder="Father Name"
                  input-classes="mb-3"
                />
              </div>
              <div class="col-lg-4">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Grand Father Name"
                  placeholder="Grand Father Name"
                  input-classes="mb-3"
                  name="l_name"
                />
              </div>
            </div>
            <div class="row">
              <div class="my-2 col-lg-6">
                <FormulateInput
                  type="select"
                  name="sex"
                  label="Sex"
                  validation="required"
                  :options="{
                    '': 'Select your sex',
                    f: 'Female',
                    m: 'Male',
                  }"
                />
              </div>
              <div class="my-2 col-lg-6">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Age"
                  type="number"
                  name="age"
                  placeholder="Age"
                  input-classes="mb-3"
                />
              </div>
            </div>
          </div>
          <!-- Address -->
          <h4 class="heading-small text-muted">Contact information</h4>
          <div class="pl-lg-4">
            <div class="row">
              <div class="col-md-12">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Address"
                  placeholder="Home Address"
                  input-classes="mb-3"
                  name="address"
                />
              </div>
            </div>
            <div class="row">
              <div class="my-2 col-lg-6">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="City"
                  name="city"
                  placeholder="City"
                  input-classes="mb-3"
                />
              </div>
              <div class="my-2 col-lg-6">
                <FormulateInput
                  validation="required"
                  name="country"
                  alternative=""
                  label="Country"
                  placeholder="Country"
                  input-classes="mb-3"
                />
              </div>
            </div>

            <div class="row">
              <div class="my-2 col-lg-6">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="email"
                  input-classes="mb-3"
                />
              </div>
              <div class="my-2 col-lg-6">
                <FormulateInput
                  validation="required"
                  name="password"
                  label="password"
                  type="password"
                  placeholder="password"
                  input-classes="mb-3"
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-100"
              :disabled="isLoading"
              :label="isLoading ? 'Loading...' : 'Register'"
            />
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      files: [],
      edit: false,
      options: ["Begena", "Krar", "Masenqo"],
      lesson_type: "",
    };
  },
  methods: {
    async submitHandler(data) {
      const sent = { ...data };
      this.$store.dispatch("admin/registerRegistrar", sent);
      this.$formulate.reset("register-registrar-form");
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>