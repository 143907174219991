<template>
  <Dashboard :dropDownLinks="dropDownLinks" :links="links" :user="user">
    <b-overlay :show="isLoading" rounded="sm">
      <router-view></router-view>
    </b-overlay>
  </Dashboard>
</template>

<script>
import Dashboard from "../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      links: [
        {
          text: "Blog",
          icon: "bi bi-house-door",
          pathName: "admin-blog",
        },
        {
          text: "Manage Registrar",
          icon: "ri-user-2-fill",
          pathName: "admin-registrar",
        },
        {
          text: "Profiles",
          icon: "bi bi-person",
          pathName: "admin-profile",
        },
      ],

      dropDownLinks: [
        {
          text: "My Profile",
          icon: "bi bi-person",
          pathName: "admin-profile",
        },
      ],
    };
  },
   mounted() {
    this.$store.dispatch("auth/GetMe")
  },
  computed: {
    user() {
      return this.$store.state.auth.me;
    },
    isLoading() {
      return this.$store.state.admin.isLoading;
    },
  },
};
</script>