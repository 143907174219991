<template>
  <div>
    <div class="pagetitle">
      <h2>Manage Student</h2>
    </div>
    <b-row>
      <b-col md="2">
        <router-link class="btn btn-primary" :to="{ name: 'registerStudent' }">
          <i class="bi bi-plus"></i> Register</router-link
        >
      </b-col>
      <b-col></b-col>
      <b-col>
        <div class="flex-row d-flex">
          <div class="px-1 form-group">
            <input
              type="search"
              class="form-control"
              v-model="filter"
              placeholder="Search students"
            />
          </div>
          <b-form-group
            label-align-sm="right"
            label-size="sm"
            class="px-1 mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              :options="['asc', 'desc', 'last']"
              size="sm"
              value="null"
              v-model="sortDirection"
              class="form-control"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            label-align-sm="right"
            label-size="sm"
            class="px-1 mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              value="null"
              class="form-control"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <div class="mt-4 shadow card-body card">
      <b-table
        :fields="fields"
        :filter="filter"
        show-empty
        stacked="md"
        :per-page="perPage"
        :current-page="currentPage"
        :items="students"
        empty-text="No students currently"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template #cell(name)="row">
          {{ row.item.f_name }}
          {{ row.item.m_name }}
          {{ row.item.l_name }}
        </template>

        <template #cell(is_active)="row">
          <p class="badge bg-success">
            {{ row.item.is_active == 1 ? "Activated" : "Deactivated" }}
          </p>
        </template>
        <template #cell(sex)="row">
          {{ row.item.sex == "f" ? "Female" : "Male" }}
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #cell(actions)="row">
          <b-button
            size="sm"
            class="mx-2"
            @click="showDetail(row.item.id, row.toggleDetails)"
          >
            <i class="bi bi-eye"></i>
            {{ row.detailsShowing ? "Hide" : "Show" }} Details
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            class="mx-2"
            @click="editStudent(row.item, row.index, $event.target)"
          >
            <i class="bi bi-pencil"></i> Edit</b-button
          >
          <b-button
            size="sm"
            variant="info"
            class="mx-2"
            @click="assignStudent(row.item.user_id)"
          >
            <i class="bi bi-pencil"></i> Assign</b-button
          >
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #row-details="row">
          <b-card class="section profile">
            <b-row>
              <b-col md="4">
                <div class="card">
                  <div
                    class="
                      pt-4
                      card-body
                      profile-card
                      d-flex
                      flex-column
                      align-items-center
                    "
                  >
                    <b-img-lazy
                      :src="'https://sisaybegena.com/api/api/file/studentProfile/' + row.item.profile_url"
                      alt="Profile"
                      rounded="circle"
                      height="130"
                      width="150"
                    />
                    <h2>{{ row.item.f_name }} {{ row.item.m_name }}</h2>
                    <h3>Student</h3>
                    <div class="mt-2 social-links">
                      <b-button
                        size="sm"
                        variant="primary"
                        class="mx-2"
                        @click="editStudent(row.item, row.index, $event.target)"
                      >
                        <i class="bi bi-pencil"></i> Edit</b-button
                      >
                      <button
                        class="btn btn-sm btn-danger"
                        @click="deactivateStudent(row.item, $event.target)"
                      >
                        <div v-if="row.item.is_active == 1">
                          <i class="ri-close-fill"></i> Deactivate
                        </div>
                        <div v-else><i class="ri-open-fill"></i> Activate</div>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <b-card class="pt-3">
                  <div class="profile-overview" id="profile-overview">
                    <h5 class="card-title">Profile Details</h5>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Full Name</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.f_name }} {{ row.item.m_name }}
                        {{ row.item.l_name }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Sex</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.sex == "f" ? "Female" : "Male" }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Age</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.age }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Education Level</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.education_level }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Address</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.address }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">City</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.city }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Country</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.country }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Lesson</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.lesson }}.
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Preferred Time</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.preferred_time }}
                      </div>
                    </div>

                    <!-- <div
                      class="row"
                      v-for="(value, key) in row.item"
                      :key="key"
                    >
                      <div class="col-lg-3 col-md-4 label">{{ key }}</div>
                      <div class="col-lg-9 col-md-8">{{ value }}</div>
                    </div> -->
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
      <b-row>
        <b-col md="8"></b-col>
        <b-col md="4" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <b-modal
        id="student-edit-modal"
        size="lg"
        centered
        title="Edit Student"
        ok-only
        hide-footer
        @hide="resetStudentModal"
      >
        <div class="card-body d-flex align-items-start">
          <EditStudent :student="current_student" />
        </div>
      </b-modal>
      <b-modal
        id="assign-student"
        @hide="resetStudentModal"
        title="Assign Teacher"
        centered
        hide-footer
      >
        <b-row>
          <form @submit="AssignTeacherToStudent">
            <b-col>
              <div class="form-group">
                <label for="teachers">Teacher</label>
                <select
                  name=""
                  v-model="teacher"
                  class="form-control"
                  id="teachers"
                >
                  <option value="null">Select teacher</option>
                  <option
                    v-for="(item, index) in unassignedTeachers"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.f_name }} {{ item._m_name }}
                  </option>
                </select>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <label for="class">Class</label>
                <select
                  name=""
                  v-model="class_type"
                  class="form-control"
                  id="class"
                >
                  <option value="null">Select class</option>
                  <option value="k">Kirar</option>
                  <option value="m">Masinqo</option>
                  <option value="b">Begena</option>
                </select>
              </div>
            </b-col>
            <b-col class="pt-2">
              <button type="submit" class="mt-3 btn btn-block btn-primary">
                Assign
              </button>
            </b-col>
          </form>
        </b-row>
        <b-table
          :show-empty="true"
          empty-text="No teacher currently assigned"
          striped
          hover
          :items="teachers"
          :fields="fields2"
        >
          <template #cell(teacher)="row">
            {{ row.item.f_name }} {{ row.item.m_name }}
          </template>
          <template #cell(class)="row">
            {{
              row.item.class_type == "k"
                ? "Kirar"
                : row.item.class_type == "m"
                ? "Messenko"
                : "Begena"
            }}
          </template>
          <template #cell(manage)="row">
            <b-button
              size="sm"
              variant="danger"
              @click="unassgnTeacher(row.item)"
              class="mx-2"
            >
              <i class="bi bi-pencil"></i> unassign</b-button
            >
          </template>
        </b-table>
      </b-modal>
      <b-modal
        id="student-deactivate"
        size="md"
        centered
        hide-header
        hide-footer
        @hide="resetDeactivateModal"
      >
        <h4>
          Are you sure you want to
          {{ current_student.is_active == 1 ? "deactivate" : "activate" }} this
          account?
        </h4>
        <div class="flex-row d-flex justify-content-end">
          <b-button
            variant="danger"
            class="mx-2"
            @click="deactivateStudentConfirm(deactivateStudentId)"
            >Yes</b-button
          >
          <b-button
            variant="secondary"
            class="mx-2"
            @click="resetDeactivateModal"
            >Cancel</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import EditStudent from "./EditStudent.vue";
export default {
  created() {
    this.$store.dispatch("registrar/GetStudents");
  },

  components: {
    EditStudent,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Full name",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "sex",
          label: "Sex",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],

      items: [
        {
          id: "1",
          f_name: "Michael",
          m_name: "Belete",
          l_name: "Alem",
          phone: "0960021405",
          email: "mike@mike.com",
        },
        {
          id: "2",
          f_name: "Alem",
          m_name: "Belete",
          l_name: "Mike",
          phone: "0915453365",
          email: "a@a.com",
        },
      ],
      filter: null,
      options: ["Begena", "Krar", "Masenqo"],
      studentModal: {},
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      currentPage: 1,
      totalRows: 1,
      assignStudentId: null,
      deactivateStudentId: null,
      fields2: [
        {
          key: "teacher",
          label: "Teacher Name",
        },
        {
          key: "class",
          label: "Class",
        },
        {
          key: "manage",
          label: "Manage",
        },
      ],
      items2: [
        { teacher: "michael belete", class: "Begena" },
        { teacher: "michael belete", class: "Kirar" },
        { teacher: "michael belete", class: "Masinqo" },
      ],
      class_type: null,
      teacher: null,
      current_student: {},
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    AssignTeacherToStudent(e) {
      e.preventDefault();

      console.log(this.class_type);
      console.log(this.teacher);

      this.$store
        .dispatch("student/AssignTeacherToStudent", {
          class_type: this.class_type,
          teacher_id: this.teacher,
          student_id: this.assignStudentId,
        })
        .then((r) => {
          r;
          this.$store.dispatch(
            "registrar/GetStudentTeachers",
            this.assignStudentId
          );
        });
    },
    editStudent(item, index, button) {
      this.studentModal = item;
      this.current_student = item;
      this.$root.$emit("bv::show::modal", "student-edit-modal", button);
    },
    resetStudentModal() {
      this.studentModal = "";
    },
    deactivateStudent(student) {
      this.deactivateStudentId = student.id;
      this.current_student = student;
      this.$bvModal.show("student-deactivate");
    },
    resetDeactivateModal() {
      this.deactivateStudentId = "";
      this.$bvModal.hide("student-deactivate");
    },
    deactivateStudentConfirm(studentId) {
      studentId;
      console.log(this.current_student);
      this.$store.dispatch("registrar/ChangeStatus", {
        id: this.current_student.user_id,
        status: this.current_student.is_active == 1 ? 0 : 1,
      });
      this.resetDeactivateModal();
    },
    assignStudent(studentId) {
      this.assignStudentId = studentId;
      console.log(studentId);
      this.$store.dispatch("registrar/GetStudentTeachers", studentId);
      this.$store.dispatch("registrar/GetUnassignedTeachers", studentId);
      this.$bvModal.show("assign-student");
    },
    resetAssignStudnet() {
      this.assignStudentId = null;
      this.$bvModal.hide("assign-student");
    },
    showDetail(student_id, toggle) {
      toggle();
      this.$store.dispatch("registrar/GetStudent", student_id);
    },
    unassgnTeacher(data) {
      console.log(data);
      this.$store
        .dispatch("student/unassignTeacher", {
          ...data,
          teacher_id: data.user_id,
          student_id: this.assignStudentId,
        })
        .then((r) => {
          r;
          this.$store.dispatch(
            "registrar/GetStudentTeachers",
            this.assignStudentId
          );
        });
    },
  },

  computed: {
    students() {
      return this.$store.state.registrar.students;
    },
    student() {
      return this.$store.state.registrar.student;
    },
    teachers() {
      return this.$store.state.registrar.studentTeachers;
    },
    unassignedTeachers() {
      return this.$store.state.registrar.UnassignedTeachers;
    },
  },
};
</script>

<style></style>
