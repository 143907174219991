<template>
  <div>
    <div class="pagetitle">
      <h2>Manage Registrar</h2>
    </div>
    <b-row>
      <b-col md="2">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'admin-register-registrar' }"
        >
          <i class="bi bi-plus"></i> Register</router-link
        >
      </b-col>
      <b-col></b-col>
      <b-col>
        <div class="flex-row d-flex">
          <div class="px-1 form-group">
            <input
              type="search"
              class="form-control"
              v-model="filter"
              placeholder="Search students"
            />
          </div>
          <b-form-group
            label-align-sm="right"
            label-size="sm"
            class="px-1 mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              :options="['asc', 'desc', 'last']"
              size="sm"
              value="null"
              v-model="sortDirection"
              class="form-control"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            label-align-sm="right"
            label-size="sm"
            class="px-1 mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              value="null"
              class="form-control"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <div class="mt-4 shadow card-body card">
      <b-table
        :fields="fields"
        :filter="filter"
        show-empty
        stacked="md"
        :per-page="perPage"
        :current-page="currentPage"
        :items="registrars"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template #cell(name)="row">
          {{ row.item.f_name }}
          {{ row.item.m_name }}
          {{ row.item.l_name }}
        </template>

        <template #cell(is_active)="row">
          <p class="badge" :class="(row.item.is_active == 1) ? 'bg-success':'bg-danger'">
            {{ row.item.is_active == 1 ? "Active" : "Deactivated" }}
          </p>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #cell(actions)="row">
          <b-button
            size="sm"
            class="mx-2"
            @click="updateValues(row.item, row.toggleDetails)"
          >
            <i class="bi bi-eye"></i>
            {{ row.detailsShowing ? "Hide" : "View" }}
          </b-button>
          <!-- <b-button
            size="sm"
            variant="primary"
            class="mx-2"
            @click="editStudent(row.item, row.index, $event.target)"
          >
            <i class="bi bi-pencil"></i> Edit</b-button
          > -->
        </template>
        <template #row-details="row">
          <b-card>
            <b-row>
              <b-col md="4">
                <div class="card">
                  <div
                    class="
                      pt-4
                      card-body
                      profile-card
                      d-flex
                      flex-column
                      align-items-center
                    "
                  >
                    <b-img-lazy
                      src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
                      alt="Profile"
                      rounded="circle"
                      height="130"
                      width="150"
                    />
                    <h2>
                      {{ row.item.f_name }} {{ row.item.m_name }}
                      <!-- {{ row.item.l_name }} -->
                    </h2>
                    <h3>Registrar</h3>
                    <div class="mt-2 social-links">
                      <b-button
                        size="sm"
                        variant="primary"
                        class="mx-2"
                        v-b-modal.registrar-edit-modal
                      >
                        <i class="bi bi-pencil"></i> Edit</b-button
                      >
                      <button
                        v-b-modal.registrar-deactivate
                        class="btn btn-sm btn-danger"
                        @click="updateCurrent(row.item)"
                      >
                        <div v-if="row.item.is_active == 1">
                          <i class="ri-close-fill"></i> Deactivate
                        </div>

                        <div v-else><i class="ri-open-fill"></i> Activate</div>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <b-card class="pt-3">
                  <div class="profile-overview" id="profile-overview">
                    <h5 class="card-title">Profile Details</h5>

                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">First Name</div>
                      <div class="col-lg-9 col-md-8">{{ row.item.f_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Middle Name</div>
                      <div class="col-lg-9 col-md-8">{{ row.item.m_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Last Name</div>
                      <div class="col-lg-9 col-md-8">{{ row.item.l_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Age</div>
                      <div class="col-lg-9 col-md-8">{{ row.item.age }}</div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Email</div>
                      <div class="col-lg-9 col-md-8">{{ row.item.email }}</div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-4 label">Education Level</div>
                      <div class="col-lg-9 col-md-8">
                        {{ row.item.education_level }}
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
      <b-row>
        <b-col md="8"></b-col>
        <b-col md="4" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <b-modal
        id="registrar-edit-modal"
        size="lg"
        centered
        title="Edit Registrar"
        ok-only
        hide-footer
        @hide="resetStudentModal"
      >
        <EditTeacher :registrar="selectedRegistrar" />
      </b-modal>

      <b-modal
        id="registrar-deactivate"
        size="md"
        centered
        hide-header
        ok-only
        hide-footer
      >
        <h4>Are you sure you want to {{selectedRegistrar.is_active == 1 ? "deactivate" : "activate" }} this account?</h4>
        <div class="flex-row d-flex justify-content-end">
          <b-button variant="danger" @click="changeStatus" class="mx-2"
            >Yes</b-button
          >
          <b-button variant="secondary" @click="hideStatusModal" class="mx-2">Cancel</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import EditTeacher from "../../components/registrar/EditRegistrar.vue";
export default {
  components: {
    EditTeacher,
  },
  data() {
    return {
      selected_registrar: {},
      current: {},
      selected: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],

      fields2: [
        {
          key: "studentName",
          label: "Student Name",
        },
        {
          key: "class",
          label: "Class Type",
        },
        {
          key: "manage",
          label: "Manage",
        },
      ],
      fields: [
        {
          key: "name",
          label: "Full name",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],

      items: [
        {
          id: "1",
          f_name: "Michael",
          m_name: "Belete",
          l_name: "Alem",
          phone: "0960021405",
          email: "mike@mike.com",
          Classes: "5",
        },
        {
          id: "2",
          f_name: "Alem",
          m_name: "Belete",
          l_name: "Mike",
          phone: "0915453365",
          email: "a@a.com",
          Classes: "5",
        },
      ],
      filter: null,
      studentModal: {},
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      currentPage: 1,
      totalRows: 1,
    };
  },
  computed: {
    registrars() {
      return this.$store.state.admin.registrars;
    },
    selectedRegistrar() {
      return this.selected_registrar;
    },
  },
  mounted() {
    // Set the initial number of items
    this.$store.dispatch("admin/GetRegistrars");
    this.totalRows = this.items.length;
  },
  methods: {
    editStudent(item, index, button) {
      this.studentModal = item;
      this.$root.$emit("bv::show::modal", "registrar-edit-modal", button);
    },
    resetStudentModal() {
      this.studentModal = "";
    },
    updateCurrent(data) {
      this.current = data;
    },
    updateValues(data, toggle) {
      toggle();
      this.selected_registrar = data;
    },
    changeStatus() {
      this.$store.dispatch("admin/ChangeStatus", {
        id: this.current.id,
        status: this.current.is_active == 1 ? 0 : 1,
      });

      this.$bvModal.hide("registrar-deactivate")
    },

    hideStatusModal() {
      this.$bvModal.hide("registrar-deactivate")
    }
  },
};
</script>

<style>
</style>