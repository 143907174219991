<template>
  <div id="why-us" class="why-us">
    <div class="content">
      <div class="container">
        <h3 class="mb-0">ኦንላይን መማር ይፋልጋሉ?</h3>
        <b-row>
          <b-col md="9">
            <p class="pt-3">
              ባሉበት ቦታ ሁነው በገና ፤ መሰንቆ ወይም ክራር መማር ከፈለጉ ይምዝገቡ ሚለውን በመጫን መማር ይጀምሩ!!
            </p></b-col
          >
          <b-col>
            <div class="text-center">
              <a href="about.html" class="more-btn p-3 text-black"
                >ይምዝገቡ <i class="bx bx-chevron-right"></i
              ></a></div
          ></b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
