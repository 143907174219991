<template>
  <div id="app">
      <h1>students</h1>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
};
</script>

<style>
</style>
