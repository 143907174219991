<template>
  <div>
    <div class="pagetitle">
      <h1>My Schedule</h1>
    </div>
    <vue-scheduler r :events="events" event-display="title" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // events: [
      //   {
      //     date: new Date(),
      //     startTime: "13:00",
      //     endTime: "15:00",
      //     title: "Begena Lesson with michael belete",
      //   },
      // ],
    };
  },
  created() {
    console.log("scheduling");
    this.$store.dispatch("teacher/GetMySchedule");
  },
  computed: {
    events() {
      return this.$store.state.teacher.schedule;
    },
  },
};
</script>

<style>
</style>