import AdminLayout from "../../components/admin/Layout/Dashboard.vue";
import { adminPermissions } from '../../libs/permissions'
import Blog from "../../views/Admin/Blog.vue";
import ManageRegistrar from "../../views/Admin/ManageRegistrar.vue";
import CreatePost from "../../views/Admin/CreatePost.vue";
import Reports from "../../views/Admin/Reports.vue";
import Profile from "../../views/Admin/Profile.vue";
import RegisterRegistrar from "../../views/Admin/RegisterRegistrar.vue";

export const adminRoute = {
  path: "/admin",
  component: AdminLayout,
  meta: {
    permissions: [...adminPermissions]
  },
  children: [
    {
      path: "",
      component: Blog,
      name: "admin-blog",
      meta: {
        permissions: [...adminPermissions]
      }
    },
    {
      path: "registrar",
      component: ManageRegistrar,
      name: "admin-registrar",
      meta: {
        permissions: [...adminPermissions]
      }
    },
    {
      path: "registerRegistrar",
      component: RegisterRegistrar,
      name: "admin-register-registrar",
      meta: {
        permissions: [...adminPermissions]
      }
    },
    {
      path: "createPost",
      component: CreatePost,
      name: "admin-create-post",
      meta: {
        permissions: [...adminPermissions]
      }
    },
    {
      path: "reports",
      component: Reports,
      name: "admin-reports",
      meta: {
        permissions: [...adminPermissions]
      }
    },
    {
      path: "profile",
      component: Profile,
      name: "admin-profile",
      meta: {
        permissions: [...adminPermissions]
      }
    },
  ],
};
