import StudentLayout from "../../components/student/Layout/Dashboard.vue";

import StudentProfile from "../../views/Student/Profile.vue";
import Announcement from "../../views/Student/Announcement.vue";
import RegisterStudent from "../../views/Student/Register.vue";
import ClassRooms from "../../views/Student/ClassRoom/index.vue";
import ClassRoom from "../../views/Student/ClassRoom/ClassRoom.vue";
import Schedule from "../../views/Student/Schedule.vue";

import { studentPermissions } from "../../libs/permissions";

export const studentRoute = {
  path: "/student",
  component: StudentLayout,
  children: [
    {
      path: "",
      name: "classRooms",
      component: ClassRooms,
      meta: {
        permissions: [...studentPermissions],
      },
    },
    {
      path: "getStarted",
      name: "getStarted",
      component: RegisterStudent,
      meta: {
        permissions: [...studentPermissions],
      },
    },
    {
      path: "classroom/:id",
      name: "classRoom",
      component: ClassRoom,
      meta: {
        permissions: [...studentPermissions],
      },
    },
    {
      path: "schedule",
      name: "schedule",
      component: Schedule,
      meta: {
        permissions: [...studentPermissions],
      },
    },
    {
      path: "profile",
      name: "studentProfile",
      component: StudentProfile,
      meta: {
        permissions: [...studentPermissions],
      },
    },
    {
      path: "announcement/:id",
      name: "Announcement",
      component: Announcement,
      meta: {
        permissions: [...studentPermissions],
      },
    },
  ],
};
