<template>
  <div class="px-1">
    <h3>Terms and condations</h3>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, repudiandae.
      Maxime voluptas non quasi? Corporis dignissimos accusantium molestiae
      necessitatibus delectus veniam, laudantium, a perferendis eveniet sequi
      est labore praesentium recusandae!
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, repudiandae.
      Maxime voluptas non quasi? Corporis dignissimos accusantium molestiae
      necessitatibus delectus veniam, laudantium, a perferendis eveniet sequi
      est labore praesentium recusandae!
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, repudiandae.
      Maxime voluptas non quasi? Corporis dignissimos accusantium molestiae
      necessitatibus delectus veniam, laudantium, a perferendis eveniet sequi
      est labore praesentium recusandae!
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, repudiandae.
      Maxime voluptas non quasi? Corporis dignissimos accusantium molestiae
      necessitatibus delectus veniam, laudantium, a perferendis eveniet sequi
      est labore praesentium recusandae!
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
main {
  color: #2c3e50;
  background: #e4edff;
}
</style>