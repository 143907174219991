<template>
  <div class="top">
    <vue-pdf-app
    
      style="height: 100vh"
      :pdf="pdfFileUrl"
      :config="config"
    ></vue-pdf-app>
  </div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

export default {
  created() {
    this.url = this.$route.params.id;
  },
  data() {
    return {
      url: "",
      // disable "Previous page" button
      config: {
        toolbar: {
          toolbarViewerRight: {
            download: false,
            print: false,
            openFile: true,
          },
        },
      },
    };
  },
  computed: {
    pdfFileUrl() {
      return (
        "https://sisaybegena.com/api/api/file/resources/" + this.$route.params.id
      );
      // return "https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_1MB.pdf";
    },
  },
  components: {
    VuePdfApp,
  },
};
</script>

<style scoped>
.top {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
}
</style>