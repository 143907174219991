<template>
  <b-container fluid>
    <b-row>
      <b-col md="2" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-md="12"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            class="form-control"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="8"></b-col>
      <b-col md="2" class="my-1">
        <b-form-group
          label="Initial sort"
          label-for="initial-sort-select"
          label-cols-sm="12"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="initial-sort-select"
            v-model="sortDirection"
            :options="['asc', 'desc', 'last']"
            size="sm"
            class="form-control"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12" class="my-1">
        <b-form-group
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Main table element -->
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          @click="showEditModal(row.item, $event.target)"
          class="mr-2"
        >
          <i class="bi bi-pencil"></i> Edit
        </b-button>
        <b-button
          size="sm"
          class="mx-2"
          variant="info"
          @click="row.toggleDetails"
        >
          <i class="bi bi-pencil"></i>
          {{ row.detailsShowing ? "hide details" : "Show Details" }}
        </b-button>
      </template>

      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template #row-details="row">
        <b-card class="mt-4">
          <h2 class="">{{ row.item.title }}</h2>
          <div v-html="row.item.body"></div>
        </b-card>
      </template>
    </b-table>
    <b-col class="d-flex justify-content-end">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
    </b-col>
    <!-- Info modal -->
    <b-modal
      id="edit-modal"
      title="Edit Notice"
      hide-footer
      centered
      @hide="resetEditModal"
    >
      <!-- notice here -->
      <div class="px-4 pb-3">
        <FormulateForm class="row g-3 needs-validation" v-model="editModal">
          <FormulateInput
            type="text"
            name="title"
            validation="required"
            label="Notice title"
            v-model="editModal.title"
          />
          <FormulateInput
            type="select"
            name="for"
            :options="[
              { value: 't', label: 'Teacher' },
              { value: 's', label: 'Student', },
              { value: 'a', label: 'All', },
            ]"
            validation="required"
            label="For"
            v-model="editModal.for"
          />
          <div class="form-group">
            <label>Body</label>
            <quill-editor
              v-model="editModal.body"
              @blur="onEditorBlur($event)"
              ref="myQuillEditor"
            >
            </quill-editor>
          </div>
          <div class="pt-3 form-group d-flex justify-content-between">
            <a class="btn btn-danger" @click="showConfirmDelete(editModal)"
              >Delete Post</a
            >
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-100"
              :disabled="editModal.title == '' || editModal.body == ''"
              @click="updateHandler"
              :label="isLoading ? 'Loading...' : 'Update'"
            />
          </div>
        </FormulateForm>
      </div>
    </b-modal>
    <b-modal id="confirm-delete" centered hide-footer hide-header>
      <h4>Are you sure you want to delete this notice?</h4>
      <div class="flex-row d-flex">
        <div class="p-1">
          <button class="btn btn-danger" @click="confirmDelete(deleteNoticeId)">
            Confirm delete
          </button>
        </div>
        <div class="p-1"><button class="btn btn-secondary">Cancel</button></div>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("registrar/GetNotices");
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      noticeForm: {
        title: null,
        body: null,
      },
      isLoading: false,
      quillValidation: false,
      fields: [
        {
          key: "title",
          label: "Title",
          tdClass: "w-75",
        },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      editModal: {},
      deleteNoticeId: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    message() {
      return this.$store.state.registrar.message;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onEditorBlur() {
      if (this.noticeForm.body == "") {
        this.quillValidation = true;
      } else {
        this.quillValidation = false;
      }
    },
    showEditModal(item) {
      this.editModal = item;
      this.$bvModal.show("edit-modal");
    },
    showConfirmDelete(item) {
      this.deleteNoticeId = item.id;
      this.$bvModal.show("confirm-delete");
    },
    async confirmDelete(id) {
      await this.$store.dispatch("registrar/DeleteNotice", id).finally(() => {
        if (this.message.status == 201 || this.message.status == 200) {
          this.$vToastify.success(this.message.message);

          this.$bvModal.hide("confirm-delete");
          this.$bvModal.hide("edit-modal");
        } else {
          this.$vToastify.error(this.message.message);
        }
      });
    },
    async updateHandler() {
      await this.$store
        .dispatch("registrar/UpdateNotice", {
          id: this.editModal.id,
          for: this.editModal.for,
          title: this.editModal.title,
          body: this.editModal.body,
        })
        .finally(() => {
          if (this.message.status == 201 || this.message.status == 200) {
            this.$vToastify.success(this.message.message);
            this.editForm = {
              title: "",
              body: "",
              for: "",
            };
            // this.editForm.title = "";
            // this.editForm.body = "";
          } else {
            this.$vToastify.error(this.message.message);
          }
        });
      this.$bvModal.hide("edit-modal");
    },
    submitHandler(data) {
      console.log(data);
    },
    resetEditModal() {
      this.editModal = {};
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
