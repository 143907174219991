<template>
  <div class="">
    <div
      v-for="(annoucement, index) in announcements"
      :key="index"
      class="shadow card"
    >
      <div class="py-1 card-body">
        <div class="flex-row d-flex bd-highlight">
          <div class="p-2 bd-highlight">
            <img
              :src="
                false
                  ? 'profile'
                  : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
              "
              class="rounded-circle"
              height="60"
            />
          </div>
          <div class="p-2 bd-highlight">
            <h3 class="pt-1 m-0">
              {{ annoucement.f_name }} {{ annoucement.m_name }}
              {{ annoucement.l_name }}
            </h3>
            <small>Nov 2021 <span class="badge badge-info">{{ (annoucement.start_time) ? "Teachers Notice":"Class Schedule" }}</span> </small>
          </div>
        </div>
      </div>
      <div class="card-body">
                <h5 v-if="annoucement.start_time">
          <b>The class starts at {{ annoucement.start_time }} and ends at {{ annoucement.end_time }} check your schedule</b>
        </h5>
        <div class="text-md">
          {{ annoucement.title }}
        </div>
        <div v-html="annoucement.body" class="text-sm">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.$store.dispatch("student/GetMyAnnouncements");
  },
  computed: {
    announcements() {
      return this.$store.state.student.announcements;
    },
  },
};
</script>

<style>
</style>