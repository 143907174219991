<template>
  <div>
    <div class="pt-4 pb-2">
      <h5 class="pb-0 text-center card-title fs-4">Create an Account</h5>
      <p class="text-center small">
        Enter your personal details to create account
      </p>
    </div>
    <FormulateForm
      @submit="submitHandler"
      class="row g-3 needs-validation"
      v-model="signupForm"
    >
      <div class="col-12">
        <FormulateInput
          name="email"
          type="email"
          label="Email address"
          placeholder="Email address"
          validation="required|email"
          :disabled="isLoading"
        />
      </div>

      <div class="col-12">
        <FormulateInput
          name="password"
          type="password"
          label="Password"
          placeholder="Your password"
          validation="required"
          :disabled="isLoading"
        />
      </div>
      <div class="col-12">
        <FormulateInput
          name="password_confirm"
          type="password"
          label="Confirm your password"
          placeholder="Confirm password"
          validation="required|confirm"
          validation-name="Confirmation"
          :disabled="isLoading"
        />
      </div>
      <div class="col-12">
        <div class="form-check">
          <input
            class="form-check-input"
            name="terms"
            type="checkbox"
            v-model="agree"
            id="acceptTerms"
            required=""
          />
          <label class="form-check-label" for="acceptTerms"
            >I agree and accept the
            <router-link :to="{ name: 'term' }"
              >terms and conditions</router-link
            ></label
          >
          <div class="invalid-feedback">You must agree before submitting.</div>
        </div>
      </div>
      <div class="col-12">
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-100"
          :disabled="!agree || isLoading"
          :label="isLoading ? 'Registering...' : 'Create Account'"
        />
      </div>

      <div class="col-12">
        <p class="mb-0 small d-flex justify-content-center">
          Already have an account?&nbsp;
          <router-link :to="{ name: 'login' }">Login</router-link>
        </p>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agree: false,
      signupForm: {},
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.auth.isLoading;
    },
    message() {
      return this.$store.state.auth.message;
    },
  },
  methods: {
    async submitHandler(data) {
      console.log(data);
      data.router = this.$router;
      this.$store.dispatch("auth/Register", data).then((res) => {
        res;
        this.$router.back();
      });
    },
  },
};
</script>

<style scoped>
main {
  color: #2c3e50;
  background: #e4edff;
}
</style>