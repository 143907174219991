<template>
  <div>
    <div
      v-for="(resource, index) in resources"
      :key="index"
      class="px-4 pt-2 card"
    >
      <div class="d-flex justify-content-between">
        <div class="flex-row d-flex">
          <i class="ri-book-mark-fill display-5"></i>
          <h4 class="p-3">{{ resource.file_name }}</h4>
        </div>
        <div class="p-2">
          <a
            :href="
              'https://sisaybegena.com/api/api/file/resources/' +
              resource.id + '.' +
              resource.file_type
            "
            download=""
          >
            <i class="bi bi-eye"></i> View
          </a>
          <!-- <router-link
            :to="{ name: 'resource', params: { id: resource.id } }"
            class="btn btn-outline-primary"
          >
            <i class="bi bi-eye"></i> View
          </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("student/GetMyResources");
  },
  computed: {
    resources() {
      return this.$store.state.student.resources;
    },
  },
};
</script>

<style>
</style>