<template>
  <home-layout :isLanding="false" :pageInfo="pageInfo">
    <services />
    <cta />

  </home-layout>
</template>

<script>
import Services from "../components/home/services.vue";
import Cta from "../components/home/cta.vue";
import HomeLayout from "../layouts/Home.vue";
export default {
  components: {
    "home-layout": HomeLayout,
    Services,
    Cta
  },
  data() {
    return {
      pageInfo: {
        title: "አገልግሎቶቻችን",
        description: "ተቋም አሁን እየሰራቸው ያሉ ስራዎች",
      },
    };
  },
};
</script>

<style></style>
