<template>
  <div class="body">
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">
      <div class="d-flex align-items-center justify-content-between">
        <router-link
          class="logo d-flex align-items-center"
          :to="{ name: 'classRooms' }"
        >
          <span class="d-none d-lg-block">Sisay Begena</span>
        </router-link>
        <i class="bi bi-list toggle-sidebar-btn" v-b-toggle.sidebar></i>
      </div>
      <!-- headerdropdown here -->
      <header-drop-down :dropDownLinks="dropDownLinks" :loggedInUser="user" />
      <!-- End Icons Navigation -->
    </header>
    <b-sidebar id="sidebar" shadow>
      <side-bar :links="links" :user = "user" />
    </b-sidebar>
    <aside id="sidebar" class="sidebar">
      <side-bar :links="links" :user = "user"/>
    </aside>
    <!-- End Header -->
    <main class="main" id="main">
      <slot></slot>
    </main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import HeaderDropDown from "../components/HeaderDropDown.vue";
export default {
  components: {
    SideBar,
    HeaderDropDown,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    dropDownLinks: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },


  
};
</script>

<style scoped>
@import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
@import "../assets/vendor/boxicons/css/boxicons.min.css";
@import "../assets/vendor/quill/quill.snow.css";
@import "../assets/vendor/quill/quill.bubble.css";
@import "../assets/vendor/remixicon/remixicon.css";
@import "../assets/vendor/simple-datatables/style.css";
@import "../assets/style.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
.body {
  font-family: "Poppins", sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #e4edff;
}
.sidebar {
  padding: 0px;
}
.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  text-align: center;
  font-family: "Nunito", sans-serif;
}
</style>