<template>
  <home-layout :isLanding="true">
    <services />
    <section id="about" class="about">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title">
          <h2>በአጭሩ</h2>
          <p>ስለ እኛ</p>
        </div>
        <div class="row">
          <div
            class="col-lg-5 order-1 order-lg-2 aos-init aos-animate"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <b-img-lazy
              src="https://i.ibb.co/G0cQjst/about.jpg"
              height="500"
              width="500"
            />
          </div>
          <div class="col-lg-7 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <p class="fst-italic">
              ሲሳይ በገና የዜማ መሳሪያዎች ማሰልጠኛ ተቋም ከተቋቋመበት ከመጋቢት ወር 2006 ዓ.ም ጀምሮ እስከ አሁን
              ድረስ የኢትዮጵያ የዜማ መሳሪያዎችን አጨዋወት በማሰልጠን እና በማስተዋወቅ፣ስራ ላይ የተሰማራ ተቋም
              ነው፡፡ተቋማችን የኢትዮጵያ የዜማ መሳሪያዎች በማሰልጠን ዘርፍ በበገና ድርደራ፣ መሰንቆና ክራር አጨዋወት
              ሥልጠና ለመስጠት ከአራዳ ክፍለ ከተማ ቴክኒክና ሙያ ትምህርትና ስልጠና ጽ/ቤት ሕጋዊ ፍቃድ ከወሰደበት
              ጊዜ ጀምሮ ሰልጣኞችን ተቀብሎ በማሰልጠን ላይ ይገኛል፡፡ በዚህም የማስተማር ሂደት ውስጥ ሰልጣኞችን
              ለብዙ ተከታታይ አመታት አሰልጥኖ አስመርቋል፡፡ ተቋማችን ጥንታዊ የሃገራችን የዜማ መሳሪያዎችን
              በማስተዋወቅ፣ በማስተማር፣ በማጥናት ለትውልድ በማስተላለፍ የበኩሉን ሚና በመጫወት ላይ ነው፡፡ በተጨማሪም
              በተለያዩ መድረኮች በኤግዚቢሽንና ባዛሮች እንዲሁም የምርምር ጥናት መድረኮች ላይ በመሳተፍ የዜማ
              መሳሪያዎቻችንን በማስተዋወቅ፣ ስለመሳሪያዎቹ ጥናቶችን በማድረግ ጥናታዊ ጽሑፎችን አቅርቧል፡፡
            </p>
            <h3>ማህበሩ የተቋቋመበት ዓላማዎች</h3>
            <ul>
              <li><i class="bi bi-check-circle"></i> የኢትዮጵያ ባህላዊ የዜማ መሳሪዎችና ማምረትና መሸጥ፤</li>
              <li><i class="bi bi-check-circle"></i> የኢትዮጵያ ባህላዊ የዜማ መሳሪዎችን አጠቃቀም ትምህርት መስጠት፤</li>
              <li>
                <i class="bi bi-check-circle"></i> በኢትዮጵያ ባህላዊ የዜማ መሳሪዎችን የሚሰሩ ባህላዊና መንፈሳዊ ዜማዎችን ማዘጋጀትና ማሳተም፤
              </li>
              <li>
                <i class="bi bi-check-circle"></i> ባህላዊ እና መንፈሳዊ ፕሮግራሞችን ማዘጋጀትና ማስተዋወቅ (የዕደ ጥበብና ኪነጥበብ ፕሮሞሽን)፡፡
              </li>
              <li>
               <i class="bi bi-check-circle"></i>  ደረጃቸውን የጠበቁ የኢትዮጵያ ባህላዊ ዜማ መሣሪያዎችን ለአገር አቀፍ እና ለዓለም አቀፍ ገበያ
                ማቅረብ፤
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <cta />
    <awards />
    <donate />
  </home-layout>
</template>

<script>
import HomeLayout from "../layouts/Home.vue";
import Services from "../components/home/services.vue";
import Awards from "../components/home/awards.vue";
import Cta from "../components/home/cta.vue";
import Donate from "../components/home/donate.vue"

export default {
  components: {
    "home-layout": HomeLayout,
    Services,
    Awards,
    Cta,
    Donate
  },
};
</script>

<style></style>
