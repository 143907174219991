<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <FormulateForm
          @submit="submitHandler"
          #default="{ isLoading }"
          class="row g-3 needs-validation"
        >
          <h4 class="heading-small text-muted">User information</h4>
          <div>
            <div class="row">
              <div class="col-lg-4">
                <FormulateInput
                  name="f_name"
                  validation="required"
                  alternative=""
                  :value="teacher.f_name"
                  label="First Name"
                  placeholder="First Name"
                  input-classes="mb-3"
                />
              </div>
              <div class="col-lg-4">
                <FormulateInput
                  :value="teacher.m_name"
                  name="m_name"
                  validation="required"
                  alternative=""
                  label="Father Name"
                  placeholder="Father Name"
                  input-classes="mb-3"
                />
              </div>
              <div class="col-lg-4">
                <FormulateInput
                  :value="teacher.l_name"
                  validation="required"
                  alternative=""
                  label="Grand Father Name"
                  placeholder="Grand Father Name"
                  input-classes="mb-3"
                  name="l_name"
                />
              </div>
            </div>
            <div class="row">
              <div class="my-2 col-lg-6">
                <FormulateInput
                  :value="teacher.sex"
                  type="select"
                  name="sex"
                  label="Sex"
                  validation="required"
                  :options="{
                    '': 'Select your sex',
                    f: 'Female',
                    m: 'Male',
                  }"
                />
              </div>
              <div class="my-2 col-lg-6">
                <FormulateInput
                  validation="required"
                  alternative=""
                  :value="teacher.age"
                  label="Age"
                  name="age"
                  type="number"
                  placeholder="Age"
                  input-classes="mb-3"
                />
              </div>
            </div>
            <div class="row">
              <div class="my-2 col-lg-12">
                <FormulateInput
                  validation="required"
                  alternative=""
                  name="profession"
                  :value="teacher.profession"
                  label="Profession"
                  placeholder="Profession"
                  input-classes="mb-3"
                />
              </div>
            </div>
          </div>
          <!-- Address -->
          <h4 class="heading-small text-muted">Contact information</h4>
          <div class="pl-lg-4">
            <div class="row">
              <div class="col-md-12">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Address"
                  placeholder="Home Address"
                  :value="teacher.address"
                  input-classes="mb-3"
                  name="address"
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-100"
              :label="isLoading ? 'Loading...' : 'Register'"
            />
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    teacher: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      files: [],
      edit: false,
      options: ["Begena", "Krar", "Masenqo"],
      model: {
        f_name: "",
        m_name: "",
        l_name: "",
        sex: "",
        age: "",
        education_level: "",
        profession: "",
        address: "",
        city: "",
        country: "",
        time_of_the_date: "",
        lesson_type: [],
        profile_picture: "",
      },
    };
  },
  methods: {
    alert(message) {
      alert(message);
    },

    // eslint-disable-next-line no-unused-vars
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert("Your choice is not a picture");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    confirmProfile() {
      alert("yes");
    },
    cancelUpload() {
      this.$refs.upload.clear();
      this.files = [];
    },
    submitHandler(data) {
      this.$store.dispatch("registrar/updateTeacher", {
        ...data,
        id: this.teacher.id,
      });

      this.$bvModal.hide("registrar-edit-modal")
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>