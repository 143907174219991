<template>
  <div>
    <div class="pagetitle d-flex justify-content-between">
      <h1>Manage Blog</h1>
      <router-link class="btn btn-primary" :to="{ name: 'admin-create-post' }"
        ><i class="bi bi-plus"></i> Create new post</router-link
      >
    </div>

    <b-card>
      <manage-posts />
    </b-card>
  </div>
</template>

<script>
import ManagePosts from "../../components/admin/managePosts.vue";
export default {
  components: {
    ManagePosts,
  },
  created() {
    console.log(this.$user);
  },
};
</script>

<style>
</style>