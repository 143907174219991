import axios from "axios";
import {
  USER_ROLE_REGISTRAR,
  USER_ROLE_ADMIN,
  USER_ROLE_STUDENT,
  // USER_ROLE_GUEST,
  USER_ROLE_TEACHER,
  USER_ROLE_GUEST,
} from "../../libs/roles";


const auth = {
  namespaced: true,

  state: () => ({
    isLoggedin: JSON.parse(localStorage.getItem("is_loggedin"))
      ? JSON.parse(localStorage.getItem("is_loggedin"))
      : false,
    token: localStorage.getItem("token"),
    error: false,
    message: "",
    message2: {
      message: "",
      status: "",
    },
    isLoading: false,
    me: JSON.parse(localStorage.getItem("me"))
      ? JSON.parse(localStorage.getItem("me"))
      : {},
    role: JSON.parse(localStorage.getItem("role"))
      ? JSON.parse(localStorage.getItem("role"))
      : "g",
  }),
  mutations: {
    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_ISLOGGEDIN(state, value) {
      localStorage.setItem("is_loggedin", value);
      state.isLoggedin = value;
    },
    SET_TOKEN(state, value) {
      state.token = value;
      localStorage.setItem("token", value);
    },
    SET_ERROR(state, value) {
      state.error = value;
    },
    SET_MESSAGE(state, value) {
      state.message = value;
    },
    SET_ME(state, value) {
      localStorage.setItem("me", JSON.stringify(value));
      state.me = value;
    },
    SET_ROLE(state, value) {
      localStorage.setItem("role", JSON.stringify(value));
      state.role = value;
    },

    SET_INFORMATIVE_MESSAGE(state, value) {
      state.message2 = value;
    },
  },
  actions: {
    async AttemptLogin({ dispatch, commit, state }, data) {
      commit("SET_IS_LOADING", true);
      await axios.get("/csrf-cookie");
      await axios
        .post(
          "/login",
          { email: data.email, password: data.password },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          // Loading state
          commit("SET_IS_LOADING", true);
          commit("SET_ISLOGGEDIN", true);
          commit("SET_TOKEN", res.data.token);
          dispatch("CheckMe", {
            roleManager: data.roleManager,
            router: data.router,
          });
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_ISLOGGEDIN", false);
          commit("SET_TOKEN", undefined);
        });

      commit("SET_ISLOGGEDIN", false);
    },
    // CheckMe({ commit }, { roleManager, router }) {
    //   axios.get("/user", {
    //     headers: {
    //       "Authorization": `Bearer ${localStorage.getItem("token")}`
    //     }
    //   }).then(res => {
    //     commit("SET_ME", res.data.user);
    //     commit("SET_ROLE", res.data.user.u_type);
    //     console.log(res.data.user.u_type == "s");
    //     if (res.data.user.u_type == "a") {
    //       roleManager.set(Object.assign({ ...res.data.user, role: USER_ROLE_ADMIN }, { role: USER_ROLE_ADMIN }))
    //       router.push("/admin")
    //     }
    //     else if (res.data.user.u_type == "t") {
    //       roleManager.set(Object.assign({ ...res.data.user, role: USER_ROLE_TEACHER }, { role: USER_ROLE_TEACHER }))
    //       router.push("/teacher")
    //     }
    //     else if (res.data.user.u_type == "r") {
    //       roleManager.set(Object.assign({ ...res.data.user, role: USER_ROLE_REGISTRAR }, { role: USER_ROLE_REGISTRAR }))
    //       router.push({ name: "registrarHome" })
    //     }
    //     else if (res.data.user.u_type == "s") {
    //       roleManager.set(Object.assign({ ...res.data.user, role: USER_ROLE_STUDENT }, { role: USER_ROLE_STUDENT }))
    //       // console.log("pushed from here bro dammn")
    //       router.push("/student")
    //     } else {
    //       roleManager.set(Object.assign({ ...res.data.user, role: USER_ROLE_GUEST }, { role: USER_ROLE_GUEST }))
    //       router.push("/login")
    //     }
    //   }).catch(err => {
    //     err;
    //     commit("SET_MESSAGE", "Something went wrong");
    //   })
    // },

    async Logout({ commit, state }, { roleManager, router }) {
      await localStorage.clear();
      await roleManager.set(
        Object.assign({ role: USER_ROLE_GUEST }, { role: USER_ROLE_GUEST })
      );
      commit("SET_ROLE", "g");
      router.push({ name: "login" }).catch((_) => {
        _;
      });
      if (state.error) commit("SET_ERROR", false);
    },
    async Register({ commit, state }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .post(
          "/register",
          { email: data.email, password: data.password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          commit("SET_IS_LOADING", false);
          commit("SET_MESSAGE", res.data.message);
          // commit("SET_MESSAGE", {
          //   message: err.response,
          //   status: err.response.status,
          // });
          data.router.push({ name: "login" }).catch((e) => {
            e;
          });
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          err;
          console.log(err);
          // if (err.response.status == 400) {
          // commit("SET_MESSAGE", err.response.data.message);
          commit("SET_ERROR", true);
          // }
          commit("SET_IS_LOADING", false);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
        });
    },
    GetMe({ commit, state }, router) {
      console.log(state.token);
      axios
        .get("/user", {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          commit("SET_ME", res.data.user);
          commit("SET_ROLE", res.data.user.u_type);
          if (res.data.user.u_type == "a") {
            router.push("/admin");
          }
        })
        .catch((err) => {
          err;
          commit("SET_MESSAGE", "Something went wrong");
        });
    },
    async ChangePassword({ state, commit }, credential) {
      commit('SET_IS_LOADING', true);
      await axios
        .post("/resetpassword", credential, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${state.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("SET_INFORMATIVE_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          commit("SET_IS_LOADING", false);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          commit("SET_INFORMATIVE_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_IS_LOADING", false);
        });
    },
    CheckMe({ commit }, { roleManager, router }) {
      axios
        .get("/user", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          commit("SET_ME", res.data.user);
          commit("SET_ROLE", res.data.user.u_type);
          console.log(res.data.user.u_type == "s");
          if (res.data.user.u_type == "a") {
            roleManager.set(
              Object.assign(
                { ...res.data.user, role: USER_ROLE_ADMIN },
                { role: USER_ROLE_ADMIN }
              )
            );
            router.push("/admin");
          } else if (res.data.user.u_type == "t") {
            roleManager.set(
              Object.assign(
                { ...res.data.user, role: USER_ROLE_TEACHER },
                { role: USER_ROLE_TEACHER }
              )
            );
            router.push("/teacher");
          } else if (res.data.user.u_type == "r") {
            roleManager.set(
              Object.assign(
                { ...res.data.user, role: USER_ROLE_REGISTRAR },
                { role: USER_ROLE_REGISTRAR }
              )
            );
            router.push({ name: "registrarHome" });
          } else if (res.data.user.u_type == "s") {
            roleManager.set(
              Object.assign(
                { ...res.data.user, role: USER_ROLE_STUDENT },
                { role: USER_ROLE_STUDENT }
              )
            );
            // console.log("pushed from here bro dammn")
            router.push("/student");
          } else {
            roleManager.set(
              Object.assign(
                { ...res.data.user, role: USER_ROLE_GUEST },
                { role: USER_ROLE_GUEST }
              )
            );
            router.push("/login");
          }
        })
        .catch((err) => {
          err;
          // commit("SET_MESSAGE", "Something went wrong");
          // commit("SET_MESSAGE", {
          //   message: err.response,
          //   status: err.response.status,
          // });
        });
    },
  },

  getters: {},
};

export default auth;
