<template>
  <div id="app">
       <div class="pagetitle">
      <h2>Resources</h2>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="index.html">Teachers</a></li>
          <li class="breadcrumb-item active">Resources</li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
};
</script>

<style>
</style>
