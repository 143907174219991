<template>
  <Dashboard :dropDownLinks="dropDownLinks" :links="links" :user="user">
    <b-overlay :show="isLoading" rounded="sm">
      <router-view></router-view>
    </b-overlay>
  </Dashboard>
</template>

<script>
import Dashboard from "../../../layouts/Dashboard.vue";
export default {
  components: {
    Dashboard,
  },

  mounted() {
    this.$store.dispatch("auth/GetMe");
  },
  computed: {
    user() {
      return this.$store.state.auth.me;
    },
    isLoading() {
      return this.$store.state.teacher.isLoading;
    },
  },
  data() {
    return {
      links: [
        {
          text: "Class Rooms",
          icon: "bi bi-door-open",
          pathName: "teacher-classroom",
        },
        {
          text: "Schedule",
          icon: "bi bi-calendar-week",
          pathName: "teacher-schedule",
        },
        {
          text: "Resources",
          icon: "ri-user-2-line",
          pathName: "teacher-post",
        },
        {
          text: "Assesment",
          icon: "bi bi-person",
          pathName: "student-assesment",
        },
        {
          text: "Profile",
          icon: "bi bi-person",
          pathName: "teacher-profile",
        },
      ],

      dropDownLinks: [
        {
          text: "My Profile",
          icon: "bi bi-person",
          pathName: "teacher-profile",
        },
      ],
    };
  },
};
</script>
