import {
  USER_ROLE_GUEST,
  USER_ROLE_TEACHER,
  USER_ROLE_ADMIN,
  USER_ROLE_REGISTRAR,
  ROUTE_NAME_HOME_GUEST,
  ROUTE_NAME_HOME_ADMIN,
  ROUTE_NAME_HOME_REGISTRAR,
  ROUTE_NAME_HOME_TEACHER,
  ROUTE_NAME_HOME_STUDENT,
  USER_ROLE_STUDENT
} from "./roles";

export const studentPermissions = [
  {
    role: USER_ROLE_GUEST,
    access: false,
    redirect: ROUTE_NAME_HOME_GUEST,
  },
  {
    role: USER_ROLE_REGISTRAR,
    access: false,
    redirect: ROUTE_NAME_HOME_REGISTRAR,
  },
  {
    role: USER_ROLE_TEACHER,
    access: false,
    redirect: ROUTE_NAME_HOME_TEACHER,
  },
  {
    role: USER_ROLE_ADMIN,
    access: false,
    redirect: ROUTE_NAME_HOME_ADMIN,
  },
];



export const adminPermissions = [
  {
    role: USER_ROLE_GUEST,
    access: false,
    redirect: ROUTE_NAME_HOME_GUEST,
  },
  {
    role: USER_ROLE_REGISTRAR,
    access: false,
    redirect: ROUTE_NAME_HOME_REGISTRAR,
  },
  {
    role: USER_ROLE_STUDENT,
    access: false,
    redirect: ROUTE_NAME_HOME_STUDENT,
  },
  {
    role: USER_ROLE_TEACHER,
    access: false,
    redirect: ROUTE_NAME_HOME_TEACHER,
  },
];
export const registrarPermissions = [
  {
    role: USER_ROLE_GUEST,
    access: false,
    redirect: ROUTE_NAME_HOME_GUEST,
  },
  {
    role: USER_ROLE_TEACHER,
    access: false,
    redirect: ROUTE_NAME_HOME_TEACHER,
  },
  {
    role: USER_ROLE_ADMIN,
    access: false,
    redirect: ROUTE_NAME_HOME_ADMIN,
  },
  {
    role: USER_ROLE_STUDENT,
    access: false,
    redirect: ROUTE_NAME_HOME_STUDENT,
  },
];
export const teacherPermissions = [
  [
    {
      role: USER_ROLE_GUEST,
      access: false,
      redirect: ROUTE_NAME_HOME_GUEST,
    },
    {
      role: USER_ROLE_REGISTRAR,
      access: false,
      redirect: ROUTE_NAME_HOME_REGISTRAR,
    },
    {
      role: USER_ROLE_ADMIN,
      access: false,
      redirect: ROUTE_NAME_HOME_ADMIN,
    },
    {
      role: USER_ROLE_STUDENT,
      access: false,
      redirect: ROUTE_NAME_HOME_STUDENT,
    },
  ]
];
export const authPermissions = [
  [
    {
      role: USER_ROLE_REGISTRAR,
      access: false,
      redirect: ROUTE_NAME_HOME_REGISTRAR,
    },
    {
      role: USER_ROLE_TEACHER,
      access: false,
      redirect: ROUTE_NAME_HOME_TEACHER,
    },
    {
      role: USER_ROLE_ADMIN,
      access: false,
      redirect: ROUTE_NAME_HOME_ADMIN,
    },
    {
      role: USER_ROLE_STUDENT,
      access: false,
      redirect: ROUTE_NAME_HOME_STUDENT,
    },
  ]
];

// [
//   {
//     role: USER_ROLE_GUEST,
//     access: false,
//     redirect: ROUTE_NAME_HOME_GUEST,
//   },
//   {
//     role: USER_ROLE_REGISTRAR,
//     access: false,
//     redirect: ROUTE_NAME_HOME_REGISTRAR,
//   },
//   {
//     role: USER_ROLE_TEACHER,
//     access: false,
//     redirect: ROUTE_NAME_HOME_TEACHER,
//   },
//   {
//     role: USER_ROLE_ADMIN,
//     access: false,
//     redirect: ROUTE_NAME_HOME_ADMIN,
//   },
//   {
//     role: USER_ROLE_STUDENT,
//     access: false,
//     redirect: ROUTE_NAME_HOME_STUDENT,
//   },
// ]