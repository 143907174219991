<template>
  <div>
    <div class="py-3" v-if="user">
      <!-- src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" -->
        <!-- src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light" -->
      <b-img-lazy
        v-if="!profile"
        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
        alt="Profile Picture"
        rounded="circle"
        width="100"
        height="100"
        class="shadow"
        center
      />
      <b-img-lazy
        v-else
        :src="getImgUrl(profile)"
        alt="Profile Picture"
        rounded="circle"
        width="100"
        height="100"
        class="shadow"
        center
      />

      <div class="pt-3">
        <h4 class="mb-1 font-weight-bold d-flex justify-content-center">
          <b>{{ user.f_name }} {{ user.m_name }}</b>
        </h4>
        <p class="mb-0 small d-flex justify-content-center">
          {{
            user.u_type == "t"
              ? "Teacher"
              : user.u_type == "s"
              ? "Student"
              : user.u_type == "r"
              ? "Registrar"
              : ""
          }}
        </p>
      </div>
    </div>
    <div class="shadow bg-shallow"></div>
    <ul class="sidebar-nav" id="sidebar-nav">
      <li class="mb-3 nav-item" v-for="(link, i) in links" :key="i">
        <router-link class="nav-link collapsed" :to="{ name: link.pathName }">
          <b><i :class="link.icon"></i></b>
          <span>{{ link.text }}</span>
        </router-link>
      </li>
      <li
        class="nav-item nav-link collapsed logout d-flex justify-content-center"
        @click="LogMeOut"
      >
        <b><i class="bi bi-box-arrow-right"></i></b>
        <span>Logout</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      profile_url: "",
    };
  },
  // created() {
  //   this.profile_url = JSON.parse(localStorage.getItem("me"))
  // },
  computed: {
    profile() {
      if (this.$store.state.auth.me)
        return this.$store.state.auth.me.profile_url;
      return {};
    },
  },
  methods: {
    LogMeOut() {
      let data = {};
      data.router = this.$router;
      data.roleManager = this.$user;
      console.log(this.$store.dispatch("auth/Logout", data));
    },

    getImgUrl(profile) {
      let dir = "";
      if (this.$store.state.auth.me.u_type == "r") dir = "registrarProfile";
      else if (this.$store.state.auth.me.u_type == "s") dir = "studentProfile";
      else if (this.$store.state.auth.me.u_type == "t") dir = "teacherProfile";
      return "https://sisaybegena.com/api/api/file/" + dir + "/" + profile;
    },
  },
};
</script>

<style scoped>
.sidebar-nav .nav-link.collapsed {
  color: #02225a;
  background: #fff;
  padding: 17px 25px;
  border-radius: 27px;
  font-size: 16px;
  font-weight: 400;
}
.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  transition: auto;
}
.sidebar-nav .nav-link:hover {
  color: #303030;
  background: #7ea9ff;
}
.bg-shallow {
  background: #cdddff;
  width: 100%;
  height: 100%;
  border-radius: 50px 50px 0px 0px;
  position: absolute;
  top: 190px;
  z-index: -1;
}
a.nav-link.collapsed.logout {
  color: #ffffff;
  background: rgb(1, 41, 112);
  padding: 17px 25px;
  border-radius: 27px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

ul#sidebar-nav {
  padding: 20px;
}

a.nav-link.collapsed.logout:hover {
  background: rgb(1 36 98);
}
</style>