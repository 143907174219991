<template>
  <home-layout :isLanding="false" :pageInfo="pageInfo">
    <section id="about" class="about">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="row pb-2">
          <div
            class="col-lg-5 order-1 order-lg-2 aos-init aos-animate"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <b-img-lazy
              src="https://i.ibb.co/25kBkCh/11.png"
              height="300"
              width="430"
              class="mt-4"
            />
          </div>
          <div class="col-lg-7 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h3>ተቋማችን ታሪክ በአጭሩ</h3>
            <p class="fst-italic">
              ሲሳይ ከ1989 ዓ.ም የጀመረውን በገናን ፤ የመስራትና የመደርደር አቅመን በማሳደግ ከ2002 ዓ.ም እስከ
              2004 ዓ.ም በአዲስ አበባ ንፋስ ስልክ ላፍቶ ክ/ከተማ በጥቃቅን እና አነስተኛ ኢንተርፕራዝ ተደራጅቶ
              ሲሳይ በገና ኢንተርፕራዝ በሚል በገና፤ መስንቆ እና ክራርን በግል ሲሰራ የነበረው ሥራ በሰውና በቁሳቁስ
              ለማጠናከር በዓላማና በራዕይ ከፍጹም ግሩም ጋር አንድ በመሆን “ሲሳይ እና ፍጹም ባህላዊ የዕደ ጥበብና
              ገጸ በረከት ሥራና ሽያጭ ሕብረት ሽርክና ማህበር” በጥር 2005 ዓ.ም ተመሰረተ፡፡
            </p>
            <h3>የዜማ መሳሪያዎች ማሰልጠኛ ተቋም</h3>

            <p class="fst-italic">
              ሲሳይ በገና የዜማ መሳሪያዎች ማሰልጠኛ ተቋም ከተቋቋመበት ከ2006 ዓ.ም ጀምሮ እስከ አሁን ድረስ
              የኢትዮጵያ የዜማ መሳሪያዎችን አጨዋወት በማሰልጠን፣ በማስተዋወቅ፣ በማምረት እና በመሸጥ ስራ ላይ
              የተሰማራ ነው::ተቋማችን የኢትዮጵያ የዜማ መሳሪያዎች በማሰልጠን ዘርፍ በበገና ድርደራ፣ መሰንቆና ክራር
              አጨዋወት ሥልጠና ለመስጠት ከአራዳ ክፍለ ከተማ ቴክኒክና ሙያ ትምህርትና ስልጠና ጽ/ቤት ሕጋዊ ፍቃድ
              ከወሰደበት ጊዜ ጀምሮ ሰልጣኞችን ተቀብሎ በማሰልጠን ላይ ይገኛል፡፡በዚህም የማስተማር ሂደት ውስጥ
              ሰልጣኞችን ለአራት ተከታታይ አመታት አሰልጥኖ አስመርቋል፡፡
            </p>
          </div>
        </div>
        <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <p class="fst-italic">
            ተቋማችን ጥንታዊ የሃገራችን የዜማ መሳሪያዎችን በማስተማር፣ ለትውልድ በማስተላለፍ የበኩሉን ሚና በመጫወት
            ላይ ነው፡፡ በዚህም ላይ ተቋማችን በተለያዩ መድረኮች በኤግዚቢሽንና ባዛሮች እንዲሁም የምርምር ጥናት
            መድረኮች ላይ በመሳተፍ የዜማ መሳሪያዎቻችንን አስተዋውቋል፡፡በተጨማሪም የተመረቁ ተማሪዎችንም በማሰባሰብ
            አገልግሎት እንዲሰጡ አድርጓል፡፡
          </p>
          <p class="fst-italic">
            ተቋማችን የኢትዮጵያ የዜማ መሳሪያዎች በማሰልጠን ዘርፍ የነገ ሀገር ተረካቢ ለሚሆኑ ህፃናት እና ወጣቶች
            ከፍተኛ ትኩረት በመስጠት ”ባህል የማንነት መገለጫ ነው፡፡” በሚል ትልቅ መርህ ለሀገር በቀል የዜማ
            መሳሪያዎች ትልቅ ክብር እና እንክብካቤ ኖሯቸው እንዲያድጉ ጥረት ያደርጋል፡፡ይህም ሲሳይ በገና የዜማ
            መሳሪያዎች ማሰልጠኛ ተቋም ሀገራችን ኢትዮጵያ ካሏት በርካታ ባህላዊ የአኗኗር ዘይቤዎች አንዱን ባህላዊ የዜማ
            መሳሪያዎችን ለመጠበቅ እና ለተተኪው ትውልድ ለማስተላለፍ ከሚያደርጋቸው ጥረቶች ተጠቃሽ ነው፡፡
          </p>
        </div>
      </div>
    </section>
    <div id="why-us" class="why-us">
      <div data-aos="fade-up" class="container aos-init aos-animate">
        <div class="row">
          <div class="col-lg-4 d-flex align-items-stretch">
            <div class="content">
              <h3>ራዕይ</h3>
              <p>የኢትዮጵያ ባህላዊ የዜማ መሣሪያወችን የሚጠቀም ፤ የሚንከባከብና የሚጠብቅ ትውልድ ማየት፡፡</p>
            </div>
          </div>
          <div class="col-lg-4 d-flex align-items-stretch">
            <div class="content">
              <h3>ዓላማ</h3>
              <p>
                የኢትዮጵያ የዜማ መሳሪያዎች በማሰልጠን ዘርፍ የነገ ሀገር ተረካቢ ለሚሆኑ ህፃናት እና ወጣቶች ከፍተኛ
                ትኩረት በመስጠት ለሀገር በቀል የዜማ መሳሪያዎች ትልቅ ክብር እና እንክብካቤ እንዲሰጡ ማድረግ፡፡
              </p>
            </div>
          </div>
          <div class="col-lg-4 d-flex align-items-stretch">
            <div class="content">
              <h3>ተልዕኮ</h3>
              <p>
                የኢትዮጵያ የዜማ መሣሪያወችን በማስተማር፤ በማምረት፤ በማጥናትና በማስተዋወቅ ትውልድ ውስጥ ማስረጽ
                ፡፡
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section id="about" class="about">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6" data-aos="fade-left" data-aos-delay="100">
            <b-img-lazy
              src="https://i.ibb.co/ZM581dY/manfcturing.png"
              class="img-fluid"
            />
          </div>
          <div class="col-md-6">
            <h3>ማምረቻ</h3>
            <p class="fst-italic">
              የዜማ መሳሪያዎች ማምረቻው በተቋሙ መስራች እና ምክትል ሥራ አስኪያጅ በዋናነት እየተመራ በስሩ
              ባለሞያዎችን በመደበኛነት ቀጥሮ በገና፣ ክራር፣ መሰንቆ ፣ መለከት በጥራትና በብዛት ያመርታል፡፡እንዲሁም
              ከማምረቻው ሠራተኞች በከፊል የአዲስ አበባ ባህል እና ቱሪዝም ቢሮ ባመቻቸው የእጨት ሥራ ስልጠና በቴክኒክ
              እና ሙያ ሰልጥነው የብቃት ማረጋገጫ ያገኙ ይገኙበታል፡፡:: •በማምረቻው ለ16 ቋሚ እና ለ6 ኮንትራት
              ሠራተኞች በቀጥታ እና ለማምረቻው ጥሬ ዕቃ እና ለዜማ መሳሪያዎች ግብአት የሚሆኑ ቁሶችን ለሚያቀርቡ
              ደንበኞች ፤ የዜማ መሳሪያዎችን ከእኛ ገዝተው ለሚሸጡና ለሚያሰለጥኑ ተቋማትና ግለሰቦች በተዘዋዋሪ የሥራ
              ዕድል ፈጥሯል፡፡
            </p>
          </div>
        </div>
      </div>
    </section>
    <donate />
    <section id="trainers" class="trainers">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title">
          <h2>መስራቾችን ይተዋወቁ</h2>
          <p>ተቋማችን መስራቾች</p>
        </div>
        <div
          class="row aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
            <div class="member">
              <center>
              <b-img-lazy src="https://i.ibb.co/FXCTpcc/MS.jpg" height="700" alt="" />
              </center>
              <div class="member-content">
                <h4>መምህር ሲሳይ ደምሴ</h4>
                <span>ዋና ሥራ አስኪያጅ</span>
                <p class="py-0">
                  የተቋሙ መስራችና ሥራ አስኪያጅ አቶ ሲሳይ ደምሴ በአዋሳ መምህራን ተቋም የ1ኛ ደረጃ
                  መምህርነት(1981ዓ.ም) ፣ በአልፋ የርቀት ትምህርት የእንጨት ቴክኖሎጂ ስልጠና(1989ዓ.ም) ፤
                  በአቢሲኒያ የሥነ ጥበብ ተቋም(2000ዓ.ም) እና በእንጦጦ ቴክኒክና ሙያ ማሰልጠኛ
                  ኮሌጅ(2001-2002ዓ.ም) መሰረታዊ የሙዚቃ ትምህርት ፧ ስልጠና ወስደዋል፡፡(1989ዓ.ም) መጋቤ
                  ስብሃት አለሙ አጋ በግል የበገና ድርደራን ተምረዋል፡፡ በ1ኛ ደረጃ መምህርነት 14ዓመት ያገለገሉ
                  ሲሆን የዜማ መሳሪያዎችን በገና ክራርና መሰንቆ በመስራትና በማስተማር የበርካታ ዓመታት የዳበረ
                  ልምድ አላቸው፡፡
                </p>
                <p class="py-0">
                  አቶ ሲሳይ በ1996 ዓ.ም በኢትዮጵያ ኦርቶዶክስ ተዋሕዶ ቤተክርስቲያን ሰንበት ትምህርት ቤቶች
                  ማደራጃ መምሪያ ማኅበረ ቅዱሳን የበገና እና የመሰንቆ ትምህርት እንዲሰጥ ሃሳብ አቅርበው
                  በገናዎችንና መሰንቆዎችን በመሥራት በአቡነ ጎርጎርዮስ የአብነትና የዜማ መሳሪያዎች የስልጠና ማዕከል
                  ሥልጠናው እንዲጀመርና እንዲሥፋፋ ከፍተኛ አስተዋጽዖ አበርክተዋል፡፡
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
            <div class="member">
              <center>
              <b-img-lazy src="https://i.ibb.co/12z47Vd/FG.jpg" height="700" alt="fitsum picture" />
              </center>
              <div class="member-content">
                <h4>ፍፁም ግሩም እንቁባሕርይ</h4>
                <span>ምክትል ሥራ አስኪያጅ</span>
                <p>
                  የተቋሙ መስራች እና ምክትል ሥራ አስኪያጅ በእንጨት ሥራ ሙያ ላይ ልዩ ተሰጥኦ ያላቸው ፤ በኦሜዳድ
                  የእንጨት ሥራ ድርጅትና ከሌሎች ባለሙያዎች ጋር በመስራት የካበተ ልምድ ያላቸውና በእንጨት ቅርፃ
                  ቅርፅ ሥራና ለእንጨት ሥራ የሚሆኑ ማሽኖችን ለሥራው አስፈላጊ በሆነ መንገድ በማዘጋጀት ሥራ ላይ
                  ያዋሉ ሲሆኑ ለእማኝነት የመለከት መስሪያ ማሽንና የክራር ሳህን መስሪያ ቶርኖ ማሽን የእርሳቸው
                  የፈጠራ ውጤት ናቸው፡፡አቶ ፍፁም ግሩም የ10ኛ ክፍል ትምህርታቸውን ከጨረሱ በኋላ በእንጨትና
                  በብረት ሥራ ፤ መሰረታዊ አጫጭር ስልጠናዎችን ሰልጥነዋል፡፡የኢትዮጵያ ብሔራዊ ባህል ማዕከል
                  ባዘጋጀው የዕደ የጥበብ ሥልጠና ሠልጥነው በብቃት አጠናቀው በማዕከሉ የተዘጋጀውን የብቃት ማረጋገጫ
                  አልፈዋል፡፡
                </p>
                <p>
                  ወጣት ፍፁም ግሩም በአቡነ ጎርጎርዮስ የዜማ ማ/ተቋም የበገና ድርደራ የተመረቁ ሲሆን በአክሱም ብቻ
                  የሚገኘውን መለከት በተቋማችን ከአንጋፋው መለከት ነፊ አቦይ ፀሐዬ ገ/አምላክ የመለከት አነፋፍን
                  በመማር ተረክበዋል፡፡ይህን የተረከቡትን መለከት በመስራት ተተኪ መለከት ነፊዎችን በማሰልጠን
                  በታላላቅ ክብረ በዓላት እና የእንግዳ አቀባበል ሥነ ሥርዓት ላይ የመለከት ነፊዎችን በመምራት
                  ተሳትፈዋል፡፡
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <awards />

    <cta />
  </home-layout>
</template>

<script>
import HomeLayout from "../layouts/Home.vue";
import Awards from "../components/home/awards.vue";
import Cta from "../components/home/cta.vue"
import Donate from "../components/home/donate.vue"
export default {
  components: {
    "home-layout": HomeLayout,
    Awards,
    Cta,
    Donate
  },
  data() {
    return {
      pageInfo: {
        title: "ስለ እኛ",
        description: "ተቆማችንን በዝርዝር ይወቁ",
      },
    };
  },
};
</script>

<style></style>
