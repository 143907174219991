<template>
  <div class="container">
    <h2>My Students</h2>
    <b-table
      :fields="fields"
      show-empty
      stacked="md"
      :items="students"
      empty-text="No students currently"
    >
      <template #cell(name)="row">
        {{ row.item.f_name }}
        {{ row.item.m_name }}
        {{ row.item.l_name }}
      </template>

      <template #cell(finished_registration)="row">
        <p class="badge bg-success">
          {{ row.item.finished_registration ? "enrolled" : "pending" }}
        </p>
      </template>
      <template #cell(sex)="row">
        <p class="badge bg-success">{{ row.item.sex }}</p>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mx-2"
          @click="showDetail(row.item, row.toggleDetails)"
        >
          {{ row.detailsShowing ? "Hide" : "Assesment" }}
        </b-button>
      </template>
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template #row-details="row">
        <b-card class="section profile">
          <b-button
            size="md"
            class="mx-2"
            block
            variant="primary"
            @click="ShowAssessmentModal(row.item)"
          >
            Add Assessment
          </b-button>
          <b-table
            striped
            hover
            :items="assessments"
            :show-empty="true"
            empty-text="No Assesments currently"
            :fields="fields2"
          >
            <template #cell(passed)="row">
              {{ row.item.passed == 1 ? "Passed" : "Failed" }}
            </template>

            <template #cell(manage)="row">
              <b-button @click="deleteAssessment(row)" variant="danger"
                >Delete Assesment</b-button
              >
            </template>
          </b-table>
        </b-card>
      </template>
      <template #cell(class_type)="row">
        {{
          row.item.class_type == "k"
            ? "Kirar"
            : row.item.class_type == "m"
            ? "Messenko"
            : "Begena"
        }}
      </template>
    </b-table>

    <!-- Assessment adding modal -->
    <b-modal
      id="add-assessment-modal"
      ref="modal"
      title="Add Assessment for Student"
      centered
      hide-footer
      size="xl"
    >
      <FormulateForm class="row g-3 needs-validation" v-model="addModal">
        <FormulateInput
          type="text"
          name="todays"
          validation="required"
          label="Today's Lesson"
          v-model="addModal.todays_lesson"
        />
        <FormulateInput
          type="text"
          name="yesterdays"
          validation="required"
          label="Yesterday's Lesson"
          v-model="addModal.yesterdays_lesson"
        />
        <FormulateInput
          type="textarea"
          validation="required"
          label="Note"
          v-model="addModal.note"
        />
        <div class="form-group row align-middle">
          <label for="passed" class="col-sm-6 col-form-label"
            >Passed today's lesson</label
          >
          <div class="col-sm-6">
            <FormulateInput
              v-model="addModal.passed"
              type="checkbox"
              input-class="inline"
              name="passed"
            />
          </div>
        </div>

        <div class="form-group justify-content-between">
          <FormulateInput
            type="submit"
            input-class="btn btn-block btn-primary w-100"
            :disabled="
              addModal.todays_lesson == '' || addModal.yesterdays_lesson == ''
            "
            @click="CreateAssessment"
            :label="isLoading ? 'Loading...' : 'Create Assessment'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Full name",
        },
        {
          key: "finished_registration",
          label: "Status",
        },
        // {
        //   key: "sex",
        //   label: "Sex",
        // },
        {
          key: "class_type",
          label: "Class Type",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      addModal: {
        passed: 0,
        todays_lesson: "",
        yesterdays_lesson: "",
      },
      fields2: [
        "yesterdays_lesson",
        "todays_lesson",
        "passed",
        "note",
        "created_at",
        "manage",
      ],
      student: {},
      student_id: "",
      class_type: "",
      toggle: "",
    };
  },
  created() {
    this.$store.dispatch("student/MyStudents");
  },
  computed: {
    students() {
      return this.$store.state.student.myStudents;
    },
    assessments() {
      return this.$store.state.student.assessments;
    },
    isLoading() {
      return this.$store.state.student.isLoading;
    },
  },
  methods: {
    showDetail(row, toggle) {
      /** eslint-disable */
      toggle();
      this.toggle = toggle;
      this.student_id = row.id;
      this.class_type = row.class_type;
      this.$store.dispatch("student/GetStudentAssment", {
        student_id: row.id,
        class_type: row.class_type,
      });
    },

    ShowAssessmentModal(item) {
      this.student = item;
      this.$root.$emit("bv::show::modal", "add-assessment-modal", "#btnShow");
    },

    CreateAssessment() {
      console.log(this.addModal);
      this.$store
        .dispatch("student/CreateAssessment", {
          ...this.addModal,
          student_id: this.student_id,
          class_type: this.class_type,
        })
        .then((e) => {
          this.$root.$emit(
            "bv::hide::modal",
            "add-assessment-modal",
            "#btnShow"
          );
          e;
        });
    },
    deleteAssessment(row) {
      this.$store.dispatch("student/deleteAssessment", row.item);
    },
  },
  props: {},
};
</script>