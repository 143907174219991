export const USER_ROLE_GUEST = "guest";
export const USER_ROLE_ADMIN = "admin";
export const USER_ROLE_REGISTRAR = "registrar";
export const USER_ROLE_TEACHER = "teacher";
export const USER_ROLE_STUDENT = "student";

// routes

export const ROUTE_NAME_HOME_GUEST = "home";
export const ROUTE_NAME_HOME_ADMIN = "admin-blog";
export const ROUTE_NAME_HOME_REGISTRAR = "registrarHome";
export const ROUTE_NAME_HOME_TEACHER = "teacher-classroom";
export const ROUTE_NAME_HOME_STUDENT = "classRooms";