<template>
  <div>
    <header id="header" class="fixed-top">
      <div class="container d-flex align-items-center">
        <router-link to="/" class="logo me-auto"
          ><img class="fluid" src="../assets/img/logo.png" height="100" />
        </router-link>

        <nav id="navbar" class="order-last navbar order-lg-0">
          <ul>
            <li>
              <router-link to="/">ቀዳሚ ገጽ</router-link>
            </li>
            <li><router-link :to="{ name: 'about' }">ስለ እኛ</router-link></li>
            <li>
              <router-link :to="{ name: 'services' }">አገልግሎቶቻችን</router-link>
            </li>

            <li>
              <router-link :to="{ name: 'contact' }">ያግኙን</router-link>
            </li>

            <li><router-link :to="{ name: 'blog' }">ጦማሪ </router-link></li>
            <li><router-link :to="{ name: 'login' }">ግባ</router-link></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <!-- .navbar -->

        <router-link :to="{ name: 'signup' }" class="get-started-btn"
          >ኦንላይን ይማሩ</router-link
        >
      </div>
    </header>

    <!-- landing page start here -->
    <section v-if="isLanding" id="hero" class="d-flex align-items-center">
      <div class="container position-relative">
        <h1 class="display-1">እንኳን ወደ ተቋማችን በደህና መጣችሁ!!</h1>
        <h2>በእውነቱ የተወደደና ጥበብ ያለው የዜማ ትምህርት አናስተምራለን፡፡</h2>
        <router-link :to="{ name: 'services' }" class="btn-get-started"
          >አገልግሎቶቻችን</router-link
        >
        <router-link :to="{ name: 'signup' }" class="mx-4 btn-get-started"
          >ኦንላይን ይማሩ</router-link
        >
      </div>
    </section>
    <!-- landing page end here -->

    <div class="breadcrumbs" v-else>
      <div class="container py-4">
        <h2>{{ pageInfo.title }}</h2>
        <p>
          {{ pageInfo.description }}
        </p>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>

    <footer id="footer">
      <div class="footer-top">
        <div class="px-4 mx-4">
          <div class="row">
            <div
              class="col-lg-3 col-md-6 footer-contact d-flex justify-content-center item-content-center"
            >
              <img
                src="../assets/img/logo_no_text.png"
                width="220"
                height="200"
              />
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
              <h4>ጠቃሚ ገጾች</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <router-link to="/" class="active">ቀዳሚ ገጽ</router-link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <router-link :to="{ name: 'about' }">ስለ እኛ</router-link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <router-link :to="{ name: 'services' }"
                    >አገልግሎቶቻችን</router-link
                  >
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i
                  ><router-link :to="{ name: 'blog' }">ጦማሪ</router-link>
                </li>
              </ul>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
              <h4>አገልግሎቶቻችን</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <router-link to="/services">ማሰልጠን</router-link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <router-link to="/services">መሸጥ </router-link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <router-link to="/services">ጥናት እና ምርምር</router-link>
                </li>
              </ul>
            </div>

            <div class="col-lg-5 col-md-6 footer-newsletter">
              <h3>ከሲሳይ በገና የዜማ መሳሪያዎች ማሰልጠኛ ተቋም</h3>
              <p>
                አዲስ አበባ አራዳ ክፍለ ከተማ ወረዳ 9 <br /> 4ኪሎ ቅድስት ሥላሴ ዩኒቨርሲቲ ግቢ ውስጥ<br /><br />
                <strong>ስልክ:</strong> +251 911358560 / +251 932176622<br />
                <strong>ኢሜይል:</strong>  sisaybegena@gmail.com / sisaybegenamii@gmail.com<br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container py-4 d-md-flex">
        <div class="text-center me-md-auto text-md-start">
          <div class="copyright">
            © Copyright
            <strong
              ><span
                >Sisay Begena Musical Instruments Training Institute</span
              ></strong
            >. All Rights Reserved
          </div>
        </div>
        <div class="pt-3 text-center social-links text-md-right pt-md-0">
          <a href="https://www.youtube.com/channel/UCgm31cQ7TtGewMkLIo0od3Q" target=”_blank”  class="youtube"><i class="bx bxl-youtube"></i></a>
          <a href="https://www.facebook.com/sisaybegena.mii.5" target=”_blank”  class="facebook"><i class="bx bxl-facebook"></i></a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    isLanding: {
      type: Boolean,
      required: true,
      default: false,
    },
    pageInfo: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped>
@import "../assets/homeStyle.css";
body {
  background: #fff;
}
</style>
