var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"mb-4 col-md-4"},[_c('div',{staticClass:"shadow card"},[_c('div',{staticClass:"card-header"},[_vm._v("Profile Picture")]),(_vm.checkUploadImage)?_c('div',{staticClass:"card-body"},[_c('div',{},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$refs.upload && _vm.$refs.upload.dropActive),expression:"$refs.upload && $refs.upload.dropActive"}],staticClass:"drop-active"},[_c('h3',[_vm._v("Drop files to upload")])]),_c('div',{staticClass:"p-2 text-center"},[_c('label',{attrs:{"for":"avatar"}},[_c('img',{staticClass:"rounded-circle img-fluid",attrs:{"src":_vm.files.length
                      ? _vm.files[0].url
                      : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'}}),_vm._m(1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.files.length !== 0),expression:"files.length !== 0"}],staticClass:"p-2 text-center"},[_c('button',{staticClass:"mx-2 btn btn-success btn-sm",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.confirmProfile.apply(null, arguments)}}},[_vm._v(" Confirm Profile ")]),_c('button',{staticClass:"mx-2 btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.cancelUpload.apply(null, arguments)}}},[_vm._v(" Cancel ")])])]),_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":_vm.upload}},[_c('div',{staticClass:"dropbox"},[_c('input',{staticClass:"input-file",attrs:{"type":"file","name":"Picture","disabled":_vm.passToForm,"accept":"image/*"},on:{"change":function($event){return _vm.filesChange($event.target.name, $event.target.files)}}})])])]):_c('div',[_c('h1',[_vm._v("Image Uploaded")])])])]),(_vm.checkUploadImage)?_c('div',{class:_vm.pass ? 'd-block col-md-8' : 'd-none col-md-8'},[_c('div',{staticClass:"shadow card"},[_c('div',{staticClass:"card-header"},[_vm._v("Fill Out Your Information")]),_c('div',{staticClass:"card-body d-flex align-items-start"},[_c('FormulateForm',{staticClass:"row g-3 needs-validation",on:{"submit":_vm.submitHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var isLoading = ref.isLoading;
return [_c('h4',{staticClass:"heading-small text-muted"},[_vm._v("User information")]),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('FormulateInput',{attrs:{"name":"f_name","validation":"required","alternative":"","label":"First Name","placeholder":"First Name","input-classes":"mb-3"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('FormulateInput',{attrs:{"name":"m_name","validation":"required","alternative":"","label":"Father Name","placeholder":"Father Name","input-classes":"mb-3"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('FormulateInput',{attrs:{"validation":"required","alternative":"","label":"Grand Father Name","placeholder":"Grand Father Name","input-classes":"mb-3","name":"l_name"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"type":"select","name":"sex","label":"Sex","validation":"required","options":{
                      '': 'Select your sex',
                      f: 'Female',
                      m: 'Male',
                    }}})],1),_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"validation":"required","alternative":"","label":"Age","name":"age","type":"number","placeholder":"Age","input-classes":"mb-3"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"type":"select","name":"education_level","label":"Education Level","validation":"required","options":{
                      '': 'Select your education level',
                      'No formal education': 'No formal education',
                      'Primary education': 'Primary education',
                      'Secondary education or high school':
                        'Secondary education or high school',
                      'Vocational qualification': 'Vocational qualification',
                      'Bachelor degree': 'Bachelor degree',
                      'Masters degree': 'Masters degree',
                      'Doctorate or higher': ' Doctorate or higher',
                    }}})],1),_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"validation":"required","alternative":"","name":"profession","label":"Profession","placeholder":"Profession","input-classes":"mb-3"}})],1)])]),_c('h4',{staticClass:"heading-small text-muted"},[_vm._v("Contact information")]),_c('div',{staticClass:"pl-lg-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('FormulateInput',{attrs:{"validation":"required","alternative":"","label":"Address","placeholder":"Home Address","input-classes":"mb-3","name":"address"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"validation":"email","alternative":"","label":"Email","name":"email","placeholder":"City","input-classes":"mb-3"}})],1),_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"name":"password","alternative":"","type":"password","validation":"^required|min:6,length","validation-messages":{
                        matches: 'Passwords must be at least 6 characters.',
                      },"label":"Password","placeholder":"password","input-classes":"mb-3"}})],1)])])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('FormulateInput',{attrs:{"type":"submit","input-class":"btn btn-primary w-100","label":isLoading ? 'Loading...' : 'Register'}})],1)]}}],null,false,2300831097)})],1)])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagetitle"},[_c('h1',[_vm._v("Register Teacher")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"pt-2"},[_vm._v("or"),_c('br'),_vm._v("Drop files anywhere to upload")])}]

export { render, staticRenderFns }