<template>
  <home-layout :isLanding="false" :pageInfo="pageInfo">
    <center class="py-3">
        <i class="bx bx-sad" style="font-size:12em; "></i>
        <h1 class="display-1">የሚፈልጉት ገጽ የለም።</h1>
        <router-link to="/"><h1 class="display-5"> <i class="bx bx-left-arrow-alt"/>ይመለሱ</h1></router-link>
    </center>
    <cta />
  </home-layout>
</template>

<script>
import HomeLayout from "../layouts/Home.vue";
import Cta from "../components/home/cta.vue"
export default {
  components: {
    "home-layout": HomeLayout,
    Cta,
  },
  data() {
    return {
      pageInfo: {
        title: "ገጹ አልተገኘም",
        description: "እናዝናለን የምትፈልጉት ገጽ የለም",
      },
    };
  },
};
</script>

<style></style>
