<template>
  <div>
    <div class="pt-4 pb-2">
      <h5 class="pb-0 text-center card-title fs-4">Login to Your Account</h5>
      <p class="text-center small">Enter your email &amp; password to login</p>
    </div>
    <FormulateForm
      @submit="submitHandler"
      class="row g-3 needs-validation"
      v-model="loginForm"
    >
      <div class="col-12">
        <FormulateInput
          name="email"
          type="email"
          label="Email address"
          placeholder="Email address"
          validation="required|email"
          :disabled="isLoading"
        />
      </div>

      <div class="col-12">
        <FormulateInput
          name="password"
          type="password"
          label="Password"
          placeholder="Your password"
          validation="required"
          :disabled="isLoading"
        />
      </div>

      <div class="col-12">
        <div class="flex-row d-flex justify-content-between">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name="remember"
              value="true"
              id="rememberMe"
            />
            <label class="form-check-label" for="rememberMe">Remember me</label>
          </div>
          <p class="mb-0 small">
            <router-link :to="{ name: 'forget' }"
              >Forgot your password?</router-link
            >
          </p>
        </div>
      </div>
      <div class="col-12">
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-100"
          :disabled="isLoading"
          :label="isLoading ? 'Logging in...' : 'Login'"
        />
      </div>
      <div class="col-12">
        <p class="mb-0 small d-flex justify-content-center">
          Don't have account? &nbsp;
          <router-link :to="{ name: 'signup' }">Create an account</router-link>
        </p>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {},
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.auth.isLoading
    },
    message() {
      return this.$store.state.auth.message
    }
  },
  methods: {
    async submitHandler(data) {
      data.router = this.$router;
      data.roleManager = this.$user;
      await this.$store.dispatch("auth/AttemptLogin", data).finally(() => {
        if (this.message.status == 201 || this.message.status == 200) {
          this.$vToastify.success(this.message.message);
        } else {
          this.$vToastify.error(this.message.message);
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped>
main {
  color: #2c3e50;
  background: #e4edff;
}
</style>
