<template>
  <div>
    <main>
      <div class="container">
        <section
          class="py-4  section register min-vh-100 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="container">
            <div class="row justify-content-center">
              <div
                class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center"
              >
                <div class="py-4 d-flex justify-content-center">
                  <router-link
                    to="/"
                    class="w-auto logo d-flex align-items-center"
                  >
                    <img src="assets/img/logo.png" alt="" />
                    <span class="d-none d-lg-block"
                      >Sisay Begena Elearning</span
                    >
                  </router-link>
                </div>
                <!-- End Logo -->

                <div class="mb-3 card">
                  <div class="card-body">
                    <router-view></router-view>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>