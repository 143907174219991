<template>
  <div>
    <div class="shadow card">
      <div class="py-1 card-body">
        <div class="flex-row d-flex bd-highlight">
          <div class="p-2 bd-highlight">
            <img
              :src="
                false
                  ? 'profile'
                  : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
              "
              class="rounded-circle"
              height="60"
            />
          </div>
          <div class="p-2 bd-highlight">
            <h3 class="pt-1 m-0">Michael Belete</h3>
            <small>Nov 2021</small>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="text-sm">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum
          recusandae repellendus facilis quis, ut reiciendis optio. Aperiam
          distinctio delectus tempore id corporis aliquam eum ea nostrum,
          nesciunt natus voluptates similique.
        </div>
      </div>
      <div class="card-footer">
        <FormulateForm @submit="submitHandler" #default="{ isLoading }">
          <b-row class="px-2">
            <b-col md="11">
              <FormulateInput
                name="question"
                type="text"
                validation="required"
                placeholder="Ask a question?"
            /></b-col>
            <b-col md="1">
              <FormulateInput
                type="submit"
                input-class="btn btn-primary"
                :disabled="isLoading"
                :label="isLoading ? 'Loading...' : 'Ask'"
            /></b-col>
          </b-row>
        </FormulateForm>
      </div>
    </div>

    <!-- questions0 -->
    <div class="pagetitle">
      <h1>Questions</h1>
    </div>
    <div class="shadow card">
      <div class="py-1 card-body">
        <div class="flex-row d-flex bd-highlight">
          <div class="p-2 bd-highlight">
            <img
              :src="
                false
                  ? 'profile'
                  : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
              "
              class="rounded-circle"
              height="60"
            />
          </div>
          <div class="p-2 bd-highlight">
            <h3 class="pt-1 m-0">Michael Belete</h3>
            <small>Nov 2021</small>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="text-sm">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum
          recusandae repellendus facilis quis, ut reiciendis optio?
        </div>
      </div>
      <div class="card-footer">
        <h4><b>Answer</b></h4>
        <p class="text-dark">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum
          recusandae repellendus facilis quis, ut reiciendis optio?
        </p>
      </div>
    </div>
    <div class="shadow card">
      <div class="py-1 card-body">
        <div class="flex-row d-flex bd-highlight">
          <div class="p-2 bd-highlight">
            <img
              :src="
                false
                  ? 'profile'
                  : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
              "
              class="rounded-circle"
              height="60"
            />
          </div>
          <div class="p-2 bd-highlight">
            <h3 class="pt-1 m-0">Michael Belete</h3>
            <small>Nov 2021</small>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="text-sm">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum
          recusandae repellendus facilis quis, ut reiciendis optio?
        </div>
      </div>
      <div class="card-footer">
        <h4><b>Answer</b></h4>
        <p class="text-dark">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum
          recusandae repellendus facilis quis, ut reiciendis optio?
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async submitHandler(data) {
      console.log(data);
    },
  },

};
</script>

<style>
</style>