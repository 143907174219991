<template>
  <b-card fluid>
    <div class="pagetitle d-flex justify-content-between">
      <h1>Manage Resources</h1>
      <b-button variant="primary" class="ml-2" size="sm" v-b-modal.add-resource>
        <i class="bi bi-plus"></i> Create Resources</b-button
      >
    </div>
    <b-row>
      <b-col md="2" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-md="12"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            class="form-control"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="8"></b-col>
      <b-col md="2" class="my-1">
        <b-form-group
          label="Initial sort"
          label-for="initial-sort-select"
          label-cols-sm="12"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="initial-sort-select"
            v-model="sortDirection"
            :options="['asc', 'desc', 'last']"
            size="sm"
            class="form-control"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12" class="my-1">
        <b-form-group
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Main table element -->
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
      <template #cell(actions)="row">
        <a
          blank
          :href="`https://sisaybegena.com/api/api/file/resources/${row.item.id}.${row.item.file_type}`"
          class="mx-2 btn btn-primary btn-sm"
          ><i class="bi bi-eye"></i> View</a
        >
        <!-- <router-link
          :to="{
            name: 'resource',
            params: { id: `${row.item.id}.${row.item.file_type}` },
          }"
          class="mx-2 btn btn-primary btn-sm"
          ><i class="bi bi-eye"></i> View</router-link
        > -->
        <a class="btn btn-danger btn-sm" @click="showConfirmDelete(row.item)">
          <i class="bi bi-trash"></i> Delete Post</a
        >
        <b-button
          size="sm"
          class="mx-2"
          variant="info"
          @click="showDetails(row.item, row.toggleDetails)"
        >
          <i class="bi bi-pencil"></i>
          {{ row.detailsShowing ? "hide details" : "Show Details" }}
        </b-button>
      </template>

      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template #row-details="row">
        <b-card>
          <h4 class="py-2">The resource is shared to</h4>
          <div class="mb-3 input-group">
            <multiselect
              v-model="value"
              :options="new_students_computed"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} options selected</span
                ></template
              >
            </multiselect>
            <b-button
              variant="primary"
              @click="attachStudentToResource(row.item.id)"
              class="input-group-text"
              >Add Student</b-button
            >
          </div>
          <b-list-group>
            <b-list-group-item
              v-for="(student, i) in row.item.students"
              class="flex-row d-flex justify-content-between"
              :key="i"
              ><h5 class="pt-1">{{ student.f_name }} {{ student.m_name }}</h5>
              <b-button
                variant="danger"
                size="sm"
                @click="removeStudent(student.id)"
                ><i class="ri-close-fill"></i>Remove</b-button
              >
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </template>
    </b-table>
    <b-col class="d-flex justify-content-end">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
    </b-col>

    <b-modal id="confirm-delete" centered hide-footer hide-header>
      <h4>Are you sure you want to delete this resource?</h4>
      <div class="flex-row d-flex">
        <div class="p-1">
          <button
            class="btn btn-danger"
            @click="confirmDelete(deleteResourceId)"
          >
            Confirm delete
          </button>
        </div>
        <div class="p-1">
          <button class="btn btn-secondary" @click="resetConfirmDelete()">
            Cancel
          </button>
        </div>
      </div>
    </b-modal>

    <create-resource :students="students" />
  </b-card>
</template>

<script>
import Multiselect from "vue-multiselect";

import CreateResource from "./createResource.vue";
export default {
  components: {
    CreateResource,
    Multiselect,
  },
  data() {
    return {
      noticeForm: {
        title: null,
        body: null,
      },
      newStudents: [],
      isLoading: false,
      quillValidation: true,
      options: [
        "student 1",
        "student 2",
        "student 3",
        "student 4",
        "student 5",
      ],
      fields: [
        {
          key: "file_name",
          label: "Title",
          tdClass: "w-50",
        },
        { key: "actions", label: "Actions" },
      ],
      // totalRows: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      value: [],
      editModal: {},
      deleteResourceId: "",
      new_students: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      if (this.$store.state.teacher.resources == undefined) {
        return 0;
      }
      return this.$store.state.teacher.resources.length;
    },
    items() {
      return this.$store.state.teacher.resources;
    },
    students() {
      return this.$store.state.student.myStudents;
    },
    new_students_computed() {
      // return this.$store.state.student.new_students;
      return this.new_students;
    },
  },
  mounted() {
    // Set the initial number of items
    this.$store.dispatch("teacher/GetResources");
    this.$store.dispatch("student/MyStudentsGrouped");
    this.totalRows = this.items.length;
  },
  methods: {
    removeStudent(id) {
      this.$store.dispatch("teacher/DeleteResourceForStudent", id);
    },
    showEditModal(item) {
      this.editModal = item;
      this.$bvModal.show("edit-modal");
    },
    showConfirmDelete(item) {
      console.log(item);
      this.deleteResourceId = item.id;
      this.$bvModal.show("confirm-delete");
    },
    confirmDelete(id) {
      this.$store.dispatch("teacher/DeleteResource", id);
      console.log(`${id} is deleted`);
      this.$bvModal.hide("confirm-delete");
      this.$bvModal.hide("edit-modal");
    },
    resetConfirmDelete() {
      this.deleteResourceId = null;
      this.$bvModal.hide("confirm-delete");
    },
    updateHandler(data) {
      console.log(data);
    },
    submitHandler(data) {
      console.log(data);
    },
    resetEditModal() {
      this.editModal = {};
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showDetails(row, toggle) {
      toggle();
      this.new_students = this.students
        .filter((i) => !row.students.map((j) => j.id).includes(i.id))
        .map((i) => {
          return { name: `${i.f_name} ${i.m_name} ${i.l_name}`, id: i.id };
        });
      console.log(this.new_students);
    },
    attachStudentToResource(resource_id) {
      if (this.value.length == 0) alert("invalid input");
      let data = new FormData();
      data.append("students", JSON.stringify(this.value.map((i) => i.id)));
      data.append("resource_id", resource_id);
      this.$store
        .dispatch("student/AttachStudentToResource", data)
        .then((e) => {
          e;
          this.$store.dispatch("teacher/GetResources");
        });
    },
  },
};
</script>