<template>
  <div>
    <div class="pagetitle">
      <h1>Fill Out this form before you begin class</h1>
    </div>

    <div class="row">
      <div class="mb-4 col-md-4">
        <div class="shadow card">
          <div class="card-header">Profile Picture</div>
          <div class="card-body" v-if="checkUploadImage">
            <div class="">
              <div
                v-show="$refs.upload && $refs.upload.dropActive"
                class="drop-active"
              >
                <h3>Drop files to upload</h3>
              </div>
              <div class="p-2 text-center">
                <label for="avatar">
                  <img
                    :src="
                      f.length
                        ? url
                        : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
                    "
                    alt="uploaded image"
                    class="rounded-circle img-fluid"
                    width="300"
                    height="300"
                  />
                  <h4 class="pt-2">or<br />Drop files anywhere to upload</h4>
                </label>
              </div>
              <div class="p-2 text-center" v-show="files.length !== 0">
                <button
                  type="submit"
                  class="mx-2 btn btn-success btn-sm"
                  @click.prevent="confirmProfile"
                >
                  Confirm Profile
                </button>
                <button
                  type="button"
                  class="mx-2 btn btn-danger btn-sm"
                  @click.prevent="cancelUpload"
                >
                  Cancel
                </button>
              </div>
            </div>
            <form
              id="student-upload-form"
              enctype="multipart/form-data"
              @submit="upload"
            >
              <div class="dropbox">
                <input
                  type="file"
                  name="Picture"
                  :disabled="passToForm || isLoading"
                  @change="filesChange($event.target.name, $event.target.files)"
                  accept="image/*"
                  class="input-file"
                />
                <!-- <p> -->
                <!-- Drag your file(s) here to begin<br /> -->
                <!-- or click to browse -->
                <!-- </p> -->
                <!-- <p v-if="isSaving">Uploading {{ fileCount }} files...</p> -->

                <!-- <button :disabled="passToForm" type="submit">upload</button> -->
              </div>
            </form>
          </div>
          <div v-else>
            <h1>Image Uploaded</h1>
          </div>
        </div>
      </div>
      <div :class="pass ? 'd-block col-md-8' : 'd-none col-md-8'">
        <div class="shadow card">
          <div class="card-header">Fill Out Your Information</div>
          <div class="card-body d-flex align-items-start">
            <FormulateForm
              name="registerStudent"
              @submit="submitHandler"
              class="row g-3 needs-validation"
            >
              <h4 class="heading-small text-muted">User information</h4>
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <FormulateInput
                      :disabled="isLoading"
                      name="f_name"
                      validation="required"
                      alternative=""
                      label="First Name"
                      placeholder="First Name"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="col-lg-4">
                    <FormulateInput
                      :disabled="isLoading"
                      name="m_name"
                      validation="required"
                      alternative=""
                      label="Father Name"
                      placeholder="Father Name"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="col-lg-4">
                    <FormulateInput
                      :disabled="isLoading"
                      validation="required"
                      alternative=""
                      label="Grand Father Name"
                      placeholder="Grand Father Name"
                      input-classes="mb-3"
                      name="l_name"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      type="select"
                      name="sex"
                      label="Sex"
                      validation="required"
                      :options="{
                        '': 'Select your sex',
                        f: 'Female',
                        m: 'Male',
                      }"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      validation="required|number|between:10,100"
                      alternative=""
                      label="Age"
                      name="age"
                      type="number"
                      placeholder="Age"
                      input-classes="mb-3"
                      min="0"
                      max="100"
                      error-behavior="live"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      type="select"
                      name="education_level"
                      label="Education Level"
                      validation="required"
                      :options="{
                        '': 'Select your education level',
                        'No formal education': 'No formal education',
                        'Primary education': 'Primary education',
                        'Secondary education or high school':
                          'Secondary education or high school',
                        'Vocational qualification': 'Vocational qualification',
                        'Bachelor degree': 'Bachelor degree',
                        'Masters degree': 'Masters degree',
                        'Doctorate or higher': ' Doctorate or higher',
                      }"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      validation="required"
                      alternative=""
                      name="profession"
                      label="Profession"
                      placeholder="Profession"
                      input-classes="mb-3"
                    />
                  </div>
                </div>
              </div>
              <!-- Address -->
              <h4 class="heading-small text-muted">Contact information</h4>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12">
                    <FormulateInput
                      :disabled="isLoading"
                      validation="required"
                      alternative=""
                      label="Address"
                      placeholder="Home Address"
                      input-classes="mb-3"
                      name="address"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      validation="required"
                      alternative=""
                      label="City"
                      name="city"
                      placeholder="City"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      validation="required"
                      name="country"
                      alternative=""
                      label="Country"
                      placeholder="Country"
                      input-classes="mb-3"
                    />
                  </div>
                </div>
              </div>
              <h4 class="text-muted">Lesson Information</h4>
              <div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      validation="required"
                      alternative=""
                      label="What time do you prefer to learn?"
                      placeholder="Time"
                      type="time"
                      name="time_of_the_day"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <label for="lesson_type">What you will learn?</label>
                    <Multi-Select
                      :disabled="isLoading"
                      v-model="lesson_type"
                      label="name"
                      track-by="code"
                      :taggable="true"
                      :options="options"
                      :multiple="true"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} lessons selected</span
                        ></template
                      >
                    </Multi-Select>
                  </div>
                </div>

                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      validation="email"
                      alternative=""
                      label="Email"
                      name="email"
                      placeholder="City"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      :disabled="isLoading"
                      name="password"
                      alternative=""
                      type="password"
                      validation="^required|min:6,length"
                      :validation-messages="{
                        matches: 'Passwords must be at least 6 characters.',
                      }"
                      label="Password"
                      placeholder="password"
                      input-classes="mb-3"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <FormulateInput
                  type="submit"
                  input-class="btn btn-primary w-100"
                  :disabled="
                    isLoading || lesson_type == null || lesson_type == ''
                  "
                  :label="isLoading ? 'Loading...' : 'Register'"
                />
              </div>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MultiSelect from "vue-multiselect";
export default {
  components: {
    MultiSelect,
  },
  data() {
    return {
      files: [],
      f: [],
      url: "",
      edit: false,
      options: [
        { name: "Begena", code: "b" },
        { name: "Krar", code: "k" },
        { name: "Masenqo", code: "m" },
      ],
      lesson_type: [],
      isSaving: false,
      passToForm: false,
    };
  },
  computed: {
    checkUploadImage() {
      return true;
    },
    pass() {
      return this.passToForm;
    },
    isLoading() {
      return this.$store.state.registrar.isLoading;
    },
    message() {
      return this.$store.state.registrar.message;
    },
  },
  methods: {
    alert(message) {
      alert(message);
    },
    filesChange(e, f) {
      this.f = f;
      this.url = URL.createObjectURL(this.f[0]);
      this.passToForm = true;
    },

    async submitHandler(data) {
      const lessons = this.lesson_type.map((lessonType) => lessonType.code);
      const sent = {
        ...data,
        lesson: lessons,
        type: "p",
      };
      this.$store
        .dispatch("registrar/CreateStudent", sent)
        .then((res) => {
          let a = new FormData();
          a.append("profileImg", this.f[0]);
          a.append("user_id", res.data.student);
          this.$store.dispatch("student/UploadProfile", a);
        })
        .catch((e) => {
          e;
        })
        .finally(() => {
          if (this.message.status == 201 || this.message.status == 200) {
            this.$formulate.reset("registerStudent");
            document.getElementById("student-upload-form").reset();
            this.$vToastify.success(this.message.message);
            this.$router.back();
          } else {
            this.$vToastify.error(this.message.message);
          }
        });

      this.$formulate.reset("registerStudent");
      this.lesson_type = null;
    },
    // eslint-disable-next-line no-unused-vars
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert("Your choice is not a picture");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    cancelUpload() {
      this.$refs.upload.clear();
      this.files = [];
      this.passToForm = false;
    },

    upload(e) {
      e.preventDefault();
      let a = new FormData();
      a.append("profileImg", this.f[0]);
      this.$store.dispatch("student/UploadProfile", a).then((res) => {
        res;
        this.passToForm = true;
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
