<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <FormulateForm
          @submit="submitHandler"
          #default="{ isLoading }"
          class="row g-3 needs-validation"
        >
          <h4 class="heading-small text-muted">User information</h4>
          <div>
            <div class="row">
              <div class="col-lg-4">
                <FormulateInput
                  name="f_name"
                  validation="required"
                  alternative=""
                  label="First Name"
                  placeholder="First Name"
                  input-classes="mb-3"
                  :value="registrar.f_name"
                />
              </div>
              <div class="col-lg-4">
                <FormulateInput
                  name="m_name"
                  validation="required"
                  alternative=""
                  label="Father Name"
                  placeholder="Father Name"
                  input-classes="mb-3"
                  :value="registrar.m_name"
                />
              </div>
              <div class="col-lg-4">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Grand Father Name"
                  placeholder="Grand Father Name"
                  input-classes="mb-3"
                  name="l_name"
                  :value="registrar.l_name"
                />
              </div>
            </div>
            <div class="row">
              <!-- <div class="my-2 col-lg-6">
                <FormulateInput
                  type="select"
                  name="sex"
                  label="Sex"
                  validation="required"
                  :value="registrar.sex"
                  :options="{
                    '': 'Select your sex',
                    f: 'Female',
                    m: 'Male',
                  }"
                />
              </div> -->
              <div class="my-2 col-lg-12">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Age"
                  type="number"
                  name="age"
                  placeholder="Age"
                  input-classes="mb-3"
                  :value="registrar.age"
                />
              </div>
            </div>
          </div>
          <!-- Address -->
          <h4 class="heading-small text-muted">Contact information</h4>
          <div class="pl-lg-4">
            <div class="row">
              <div class="col-md-12">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Address"
                  placeholder="Home Address"
                  input-classes="mb-3"
                  :value="registrar.address"
                  name="address"
                />
              </div>
            </div>

            <div class="row">
              <div class="my-2 col-lg-12">
                <FormulateInput
                  validation="required"
                  alternative=""
                  label="Email"
                  name="email"
                  :value="registrar.email"
                  type="email"
                  placeholder="email"
                  input-classes="mb-3"
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-100"
              :disabled="isLoading"
              :label="isLoading ? 'Loading...' : 'Update Registrar'"
            />
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    registrar: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      edit: false,
      model: {
        f_name: "",
        m_name: "",
        l_name: "",
        sex: "",
        age: "",
        education_level: "",
        profession: "",
        address: "",
        // city: "",
        country: "",
        time_of_the_date: "",
        lesson_type: [],
        profile_picture: "",
      },
    };
  },
  methods: {
    alert(message) {
      alert(message);
    },
    confirmProfile() {
      alert("yes");
    },
    cancelUpload() {
      this.$refs.upload.clear();
      this.files = [];
    },
    async submitHandler(data) {
      this.$store
        .dispatch("admin/UpdateRegistrar", { ...data, id: this.registrar.id })
        .finally((res) => {
          res;
          this.$bvModal.hide("registrar-edit-modal");
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>