<template>
  <!-- create new announcemtns -->
  <b-modal
    id="add-announcement"
    centered
    hide-footer
    title="Create announcement"
  >
    <b-tabs content-class="mt-3">
      <b-tab title="Create Announcement" active
        ><FormulateForm
          @submit="newAnnouncementA"
          #default="{ isLoading }"
          class="row g-3 needs-validation"
          v-model="newAnnouncment"
        >
          <FormulateInput
            type="text"
            name="title"
            validation="required"
            label="Announcement title"
            v-model="newAnnouncment.title"
          />

          <div class="form-group">
            <label>Body</label>
            <quill-editor v-model="newAnnouncment.body" ref="myQuillEditor">
            </quill-editor>
          </div>
          <div class="form-group">
            <label for="students">Students the announcements is for?</label>
            <multiselect
              v-model="value"
              :options="students"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} options selected</span
                ></template
              >
            </multiselect>
          </div>
          <div class="pt-3 form-group d-flex justify-content-end">
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-100"
              :disabled="isLoading"
              :label="isLoading ? 'Loading...' : 'create'"
            />
          </div> </FormulateForm
      ></b-tab>
      <b-tab title="Create Class"
        ><FormulateForm
          @submit="submitHandler"
          #default="{ isLoading }"
          class="row g-3 needs-validation"
          v-model="newAnnouncment"
        >
          <FormulateInput
            type="text"
            name="title"
            validation="required"
            label="Announcement title"
            v-model="newAnnouncment.title"
          />

          <FormulateInput
            type="datetime-local"
            name="startTime"
            label="Class Start Time"
            validation="required"
          />

          <FormulateInput
            type="datetime-local"
            name="endTime"
            label="Class End time"
            validation="required"
          />

          <div class="form-group">
            <label>Body</label>
            <quill-editor v-model="newAnnouncment.body" ref="myQuillEditor">
            </quill-editor>
          </div>
          <div class="form-group">
            <label for="students">Students the announcement is for? </label>
           
            <multiselect
              v-model="value"
              :options="students"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} options selected</span
                ></template
              >
            </multiselect>

            <!-- <multiselect
              id="students"
              track-by="name"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              v-model="newAnnouncment.students"
              :options="options"
              placeholder="select students"
            ></multiselect> -->
          </div>
          <div class="pt-3 form-group d-flex justify-content-end">
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-100"
              :disabled="isLoading"
              :label="isLoading ? 'Loading...' : 'create'"
            />
          </div> </FormulateForm
      ></b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  props: ["myStudents"],
  components: {
    Multiselect,
  },

  created() {
    this.options = this.myStudents.map((i) => {
      return { name: `${i.f_name} ${i.m_name} ${i.l_name}`, id: i.id };
    });

    this.$store.dispatch("student/MyStudentsGrouped");
  },

  computed: {
    student_selection() {
      return this.options;
    },
    students() {
      if (this.$store.state.student.myStudents)
        return this.$store.state.student.myStudents.map((i) => {
          return { name: `${i.f_name} ${i.m_name} ${i.l_name}`, id: i.id };
        });
      return [];
    },
  },

  data() {
    return {
      newAnnouncment: {
        title: null,
        body: "S",
        students: [],
      },
      value: [],
      newClass: {
        title: null,
        body: null,
        startTime: null,
        endTime: null,
      },
      isLoading: false,
      options: [
        { name: "Vue.js", language: "JavaScript" },
        { name: "Adonis", language: "JavaScript" },
        { name: "Rails", language: "Ruby" },
        { name: "Sinatra", language: "Ruby" },
        { name: "Laravel", language: "PHP" },
        { name: "Phoenix", language: "Elixir" },
      ],
    };
  },

  methods: {
    submitHandler(data) {
      let students = [];
      console.log("submit handler");
      for (let i = 0; i < this.value.length; i++) {
        students.push(this.value[i].id);
      }
      data.students = students;
      let q = { ...data, type: "a" };
      // console.log(q);
      console.log("this is it", data);
      this.$bvModal.hide("add-announcement");
      this.$store.dispatch("teacher/CreateAnnouncement", q);
    },
    newAnnouncementA(data) {
      let students = [];
      for (let i = 0; i < this.value.length; i++) {
        students.push(this.value[i].id);
      }
      data.students = students;
      let q = { ...data, type: "c" };
      console.log(q);
      this.$bvModal.hide("add-announcement");
      this.$store.dispatch("teacher/CreateAnnouncement", q);
    },
  },
};
</script>

<style>
</style>