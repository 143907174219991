<template>
  <b-modal id="add-resource" centered hide-footer title="Share Resource">
    <FormulateForm
      v-model="model"
      @submit="submitHandler"
      #default="{ isLoading }"
      class="row g-3 needs-validation"
    >
      <div class="mb-3 row">
        <div class="col-sm-12">
          <FormulateInput
            name="title"
            type="text"
            v-model="model.title"
            label="Document Title"
            placeholder="enter the document title"
            validation="required"
          />
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-sm-12">
          <label for="students">Students to share with</label>
          <multiselect
            v-model="value"
            :options="student_selection"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            track-by="name"
            :preselect-first="false"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
      </div>
      <div class="mb-3 row">
        <input
          type="file"
          @change="filesChange($event.target.name, $event.target.files)"
          name="file"
          label="Select your documents to upload"
          help="Select one PDF document upload"
          required
          validation="mime:application/pdf"
        />
      </div>

      <FormulateInput
        type="submit"
        input-class="btn btn-primary w-100"
        :disabled="isLoading"
        :label="isLoading ? 'Loading...' : 'post'"
      />
    </FormulateForm>
  </b-modal>
</template>

<script>
import multiselect from "vue-multiselect";
export default {
  components: {
    multiselect,
  },
  props: ["students"],
  data() {
    return {
      value: [],
      model: {
        title: null,
        file: null,
        student: null,
      },
      files: [],
      edit: false,
      f: [],
      options: [],
    };
  },
  methods: {
    alert(message) {
      alert(message);
    },
    async submitHandler(data) {
      let students = [];
      for (let i = 0; i < this.value.length; i++) {
        students.push(this.value[i].id);
      }
      const sent = { ...data, lesson_type: this.lesson_type, students };
      console.log(sent);
      let a = new FormData();
      a.append("resource", this.f[0]);
      a.append("file_name", this.model.title);
      a.append("students", JSON.stringify(students));
      this.$store.dispatch("teacher/postResource", a);
    },
    // eslint-disable-next-line no-unused-vars
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert("Your choice is not a picture");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    confirmProfile() {
      alert("yes");
    },
    cancelUpload() {
      this.$refs.upload.clear();
      this.files = [];
    },

    filesChange(e, f) {
      console.log(f);
      this.f = f;
    },

    upload(e) {
      e.preventDefault();

      let a = new FormData();
      a.append("profileImg", this.f[0]);
      this.$store.dispatch("student/UploadProfile", a);
    },
  },
  created() {
    this.options = this.students.map((i) => {
      return { name: `${i.f_name} ${i.m_name} ${i.l_name}`, id: i.id };
    });
  },

  computed: {
    student_selection() {
      return this.options;
    },
  },
};
</script>

<style>
</style>