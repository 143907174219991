<template>
  <home-layout :isLanding="false" :pageInfo="pageInfo">
    <section id="popular-courses" class="courses">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title">
          <p>የቅርብ ጊዜ ፖስትዎች</p>
        </div>

        <div
          class="row aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <b-alert variant="warning" show v-if="!blogs"
            ><center><h3>እስካሁን ምንም ፖስት የለም</h3></center></b-alert
          >
          <div
            v-for="blog in blogs"
            :key="blog.id"
            class="col-lg-4 col-md-6 d-flex align-items-stretch"
          >
            <div class="course-item">
              <img
                :src="
                  'https://sisaybegena.com/api/api/file/blogImg/' + blog.image_name
                "
                class="img-fluid"
                alt="..."
              />
              <div class="course-content">
                <h3>
                  <router-link
                    :to="{ name: 'post', params: { id: blog.id } }"
                    >{{ blog.title }}</router-link
                  >
                </h3>
                <p v-html="blog.body"></p>
              </div>
            </div>
          </div>
          <!-- End Course Item-->
        </div>
      </div>
    </section>
  </home-layout>
</template>

<script>
import HomeLayout from "../layouts/Home.vue";
export default {
  components: {
    "home-layout": HomeLayout,
  },
  created() {
    this.$store.dispatch("admin/GetBlog");
  },
  computed: {
    blogs() {
      return this.$store.state.admin.blogs;
    },
  },
  data() {
    return {
      pageInfo: {
        title: "ጦማሪ",
        description: "ስለ ተቋማችን አዳዲስ ነገሮች",
      },
    };
  },
};
</script>

<style></style>
