import store from "../index";
import axios from "axios";
const student = {
  namespaced: true,

  state: () => ({
    isLoading: false,
    notices: [],
    profile: {},
    announcement: [],
    announcements: [],
    schedule: [],
    resources: [],
    classes: [],
    // message: "",
    message: {
      message: "",
      status: "",
    },
    error: false,
    myStudents: [],
    assessments: [],
    new_students: [],
  }),
  mutations: {
    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_NOTICES(state, value) {
      state.notices = value;
    },
    SET_SCHEDULE(state, value) {
      state.schedule = value;
    },
    SET_CLASS(state, value) {
      state.classes = value;
    },
    SET_PROFILE(state, value) {
      state.profile = value;
    },
    SET_ANNOUNCEMENT(state, value) {
      state.announcement = value;
    },
    SET_RESOURCES(state, value) {
      state.resources = value;
    },
    SET_MESSAGE(state, value) {
      state.message = value;
    },
    SET_ERROR(state, value) {
      state.error = value;
    },
    SET_MY_STUDENTS(state, value) {
      state.myStudents = value;
    },
    SET_STUDENT_ASSESSMENT(state, value) {
      state.assessments = value;
    },
    SET_STUDENTS_WITHOUT_RESOURCES(state, value) {
      state.new_students = value;
    },
    SET_ANNOUCEMENT(state, value) {
      state.announcements = value;
    },
  },
  actions: {
    GetResources({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/resources", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.auth.state.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_RESOURCES", res.data.resources);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_ERROR", true);
          // commit("SET_MESSAGE", err);
        });

      commit("SET_IS_LOADING", false);
    },
    GetAnnouncement({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/announcements", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.auth.state.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_ANNOUNCEMENT", res.data.announcements);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          commit("SET_ERROR", true);
          // commit("SET_MESSAGE", err);
        });

      commit("SET_IS_LOADING", false);
    },
    GetNotices({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/notices/for/s",
        // .get("/notices",
         {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_NOTICES", res.data.notices);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          // commit("SET_MESSAGE", err);
        });

      commit("SET_IS_LOADING", false);
    },
    GetMyClasses({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/students/class", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("success");
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_CLASS", res.data.classes);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          // commit("SET_MESSAGE", err);
        });

      commit("SET_IS_LOADING", false);
    },
    GetMyAnnouncements({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/students/announcements", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("success");
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_ANNOUCEMENT", res.data.announcements);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
          // commit("SET_MESSAGE", err);
        });

      commit("SET_IS_LOADING", false);
    },
    GetMyResources({ commit, state }) {
      commit("SET_IS_LOADING", true);
      axios
        .get("/students/resources", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("success");
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_RESOURCES", res.data.resources);
          if (state.error) commit("SET_ERROR", false);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", err);

          commit("SET_IS_LOADING", false);
        });
    },
    GetME({ commit, state }) {
      axios
        .get("/me", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.auth.state.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_PROFILE", res.data.profile);

          commit("SET_IS_LOADING", false);
          if (state.error) commit("SET_ERROR", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);

          commit("SET_IS_LOADING", false);
          err;
          // commit("SET_MESSAGE", err);
        });
    },
    UploadProfile({ commit }, data) {
      commit("SET_IS_LOADING", true);
      return axios
        .post("/students/profile", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("success");
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });

          commit("SET_IS_LOADING", false);
          // commit("SET_MESSAGE", res.data.message);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    getStarted({ commit }, data) {
      commit("SET_IS_LOADING", true);
      return axios
        .post("/getStarted", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("success");
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });

          commit("SET_IS_LOADING", false);
          // commit("SET_MESSAGE", res.data.message);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    getStartStudent({ commit }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .post("/new/students", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("success");
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          // commit("SET_MESSAGE", res.data.message);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    UpdateStudentInfo({ commit }, data) {
      commit("SET_IS_LOADING", true);
      axios
        .put("/students", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          console.log("success");
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          // commit("SET_MESSAGE", res.data.message);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    MyStudents({ commit }) {
      axios
        .get("/teachers/students", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_MY_STUDENTS", res.data.students);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    MyStudentsGrouped({ commit }) {
      axios
        .get("/teachers/students/grouped", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_MY_STUDENTS", res.data.students);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    StudentsWithoutResource({ commit }, data) {
      console.log("this is my token ->" + store.state.auth.token);
      axios
        .get("/students/resources/unassigned", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          // commit("SET_STUDENTS_WITHOUT_RESOURCES", res.data.students);
          commit(
            "SET_STUDENTS_WITHOUT_RESOURCES",
            res.data.all.fillter((i) => !res.data.selected.includes(i.id))
          );
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    GetStudentAssment({ commit }, data) {
      axios
        .get(
          "/students/" + data.student_id + "/assessments/" + data.class_type,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${store.state.auth.token}`,
            },
          }
        )
        .then((res) => {
          // commit("SET_MESSAGE", {
          //     message: res.data.message,
          //     status: res.status,
          // });
          // commit("SET_MESSAGE", res.data.message);
          commit("SET_STUDENT_ASSESSMENT", res.data.assessments);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    AssignTeacherToStudent({ commit }, data) {
      commit("SET_IS_LOADING", true);
      return axios
        .post("/assign", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });

          commit("SET_IS_LOADING", false);
          console.log("success");
          // commit("SET_MESSAGE", res.data.message);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    unassignTeacher({ commit }, data) {
      commit("SET_IS_LOADING", true);
      return axios
        .post("/unassignTeacher", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          console.log("success");
          // commit("SET_MESSAGE", res.data.message);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    CreateAssessment({ commit, dispatch }, data) {
      commit("SET_IS_LOADING", true);
      return axios
        .post("/students/assessments", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          console.log("success");
          // commit("SET_MESSAGE", res.data.message);
          dispatch("GetStudentAssment", data);

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    deleteAssessment({ commit, dispatch }, data) {
      commit("SET_IS_LOADING", true);
      return axios
        .post("/delete/assessments", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          console.log("success");
          // commit("SET_MESSAGE", res.data.message);
          dispatch("GetStudentAssment", {
            student_id: data.students_id,
            class_type: data.class_type,
          });

          commit("SET_IS_LOADING", false);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },
    AttachStudentToResource({ commit }, data) {
      commit("SET_IS_LOADING", true);
      return axios
        .post("/attach/resource", data, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });

          commit("SET_IS_LOADING", false);
          // commit("SET_MESSAGE", res.data.message);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });

          commit("SET_IS_LOADING", false);
        });
    },

    async GetMySchedule({ commit }) {
      commit("SET_IS_LOADING", true);
      await axios
        .get("/students/schedule", {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
          },
        })
        .then((res) => {
          commit("SET_MESSAGE", {
            message: res.data.message,
            status: res.status,
          });
          console.log("success");
          commit("SET_SCHEDULE", res.data.schedule);
        })
        .catch((err) => {
          console.log("error");
          commit("SET_ERROR", true);
          commit("SET_MESSAGE", {
            message: err.response,
            status: err.response.status,
          });
        });

      commit("SET_IS_LOADING", false);
    },
  },
  getters: {},
};

export default student;
