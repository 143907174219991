<template>
  <div>
    <div class="pagetitle">
      <h1>Class Rooms</h1>
    </div>
    <div class="section dashboard">
      <div class="row">
        <div v-for="(c, index) in classes" :key="index" class="col-md-4">
          <router-link :to="{ name: 'classRoom', params: { id: c.id } }">
            <div class="card info-card sales-card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div
                    class="
                      card-icon
                      rounded-circle
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <i class="bi bi-book-half"></i>
                  </div>
                  <div>
                    <h6 v-if="c.class_type == 'b'" class="ps-3">Begena</h6>
                    <h6 v-else-if="c.class_type == 'k'" class="ps-3">Kirar</h6>
                    <h6 v-else-if="c.class_type == 'm'" class="ps-3">
                      Masinko
                    </h6>
                    <h6 v-else class="ps-3">Not Assigned</h6>
                    <span class="pt-1 text-muted small">Taught By</span>
                    <span class="pt-2 text-primary small fw-bold ps-1"
                      >{{ c.f_name }} {{ c.m_name }} {{ c.l_name }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <b-alert v-if="classes.length == 0" variant="warning"
          ><center>No class assgined yet</center></b-alert
        >
      </div>
    </div>
    <div class="row">
      <div class="pb-1 col-md-12">
        <h4>Notices</h4>
      </div>
      <div v-if="notices.length != 0" class="col-md-6">
        <div v-for="(notice, index) in notices" :key="index">
          <div class="shadow card">
            <div class="px-4 mx-2 card-body">
              <div class="flex-row d-flex">
                <p class="m-0 display-4">
                  <i class="bi bi-chat-dots-fill"></i>
                </p>
                <div class="p-2">
                  <h4 class="p-0 m-0">{{ notice.title }}</h4>
                  <small class="p-0 m-0 text-secondary">
                    <i class="ri-time-fill"></i> {{ notice.created_at }}</small
                  >
                </div>
              </div>
              <div v-html="notice.body" class="py-3 text-justify">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col-md-12">
        <b-alert variant="warning" show
          ><center>No notices posted yet</center></b-alert
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    if (!this.$store.state.auth.me.f_name) {
      this.$router.push({ name: "getStarted" });
    } else {
      this.$store.dispatch("student/GetNotices");
      this.$store.dispatch("student/GetMyClasses");
    }
  },

  computed: {
    classes() {
      return this.$store.state.student.classes;
    },
    notices() {
      return this.$store.state.student.notices;
    },
  },
};
</script>
<style>
a {
  text-decoration: none;
}
</style>