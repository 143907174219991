<template>
  <section id="why-us" class="why-us">
    <div class="container aos-init aos-animate" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-4 d-flex align-items-stretch">
          <div class="content">
            <h3>ለምን እኛን ይመርጣሉ?</h3>
            <p>
              የኢትዮጵያ የዜማ መሳሪያዎች በማሰልጠን ዘርፍ የነገ ሀገር ተረካቢ ለሚሆኑ ህፃናት እና ወጣቶች ከፍተኛ ትኩረት በመስጠት ”ባህል የማንነት መገለጫ ነው፡፡” በሚል ትልቅ መርህ ለሀገር በቀል የዜማ መሳሪያዎች ትልቅ ክብር እና እንክብካቤ ኖሯቸው እንዲድጉ ጥረት ያደርጋል፡፡ይህም ሲሳይ በገና የዜማ መሳሪያዎች ማሰልጠኛ ተቋም ሀገራችን ኢትዮጵያ ካሏት በርካታ ባህላዊ የአኗኗር ዘይቤዎች አንዱን ባህላዊ የዜማ መሳሪያዎችን ለመጠበቅ እና ለተተኪው ትውልድ ለማስተላለፍ ከሚያደርጋቸው ጥረቶች ተጠቃሽ ነው፡
            </p>
            <div class="text-center">
              <router-link :to="{ name: 'about' }" class="more-btn"
                >ተጨማሪ እወቅ <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
        </div>
        <div
          class="col-lg-8 d-flex align-items-stretch aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="icon-boxes d-flex flex-column justify-content-center">
            <div class="row">
              <div class="col-xl-4 d-flex align-items-stretch">
                <div class="icon-box mt-4 mt-xl-0">
                  <i class="bx bx-book"></i>
                  <h4>ማሰልጠን</h4>
                  <p>
                    ተቋሙ በአጫጭር ስልጠና ዘርፍ በኦንላይን እና በአካል፡- በበገና ፤ በመሰንቆ ፤ በክራር
                    እንዲሁም በመለከት ብቁ የሆነ ስልጠና አሰልጥነን እናስመርቃለን፡፡
                  </p>
                </div>
              </div>
              <div class="col-xl-4 d-flex align-items-stretch">
                <div class="icon-box mt-4 mt-xl-0">
                  <i class="bx bx-cube-alt"></i>
                  <h4>መሸጥ</h4>
                  <p>
                    በማምረቻችን የተመረቱ የዜማ መሣሪያዎች መያዣ ማህደርዎች (ቦርሣዎች) እንዲሁም የመማሪያ
                    መፀሐፎችን እንሸጣለን፡፡
                  </p>
                </div>
              </div>
              <div class="col-xl-4 d-flex align-items-stretch">
                <div class="icon-box mt-4 mt-xl-0">
                  <i class="bx bx-images"></i>
                  <h4>ጥናት እና ምርምር</h4>
                  <p>
                    ተቆማችን በተለያዩ ጊዜያት ጥናታና ምርምር በማድረግ  በፌደራል እና በከተማ ደረጃ በተዘጋጁ የውይይት መድረኮች ፤ ኤግዚቢሽን እና ዐውደ ጥናቶች ላይ ተሳትፎ ያድርጋል፡፡
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- End .content-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
