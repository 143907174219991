var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{staticClass:"row g-3 needs-validation",attrs:{"name":"registerStudent"},on:{"submit":_vm.submitHandler}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"name":"f_name","value":_vm.student.f_name,"validation":"required","alternative":"","label":"First Name","placeholder":"First Name","input-classes":"mb-3"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"name":"m_name","validation":"required","value":_vm.student.m_name,"alternative":"","label":"Father Name","placeholder":"Father Name","input-classes":"mb-3"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('FormulateInput',{attrs:{"value":_vm.student.l_name,"disabled":_vm.isLoading,"validation":"required","alternative":"","label":"Grand Father Name","placeholder":"Grand Father Name","input-classes":"mb-3","name":"l_name"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"type":"select","name":"sex","value":_vm.student.sex,"label":"Sex","validation":"required","options":{
            '': 'Select your sex',
            f: 'Female',
            m: 'Male',
          }}})],1),_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"validation":"required","alternative":"","label":"Age","name":"age","value":_vm.student.age,"placeholder":"Age","input-classes":"mb-3"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"type":"select","name":"education_level","label":"Education Level","value":_vm.student.education_level,"validation":"required","options":{
            '': 'Select your education level',
            'No formal education': 'No formal education',
            'Primary education': 'Primary education',
            'Secondary education or high school':
              'Secondary education or high school',
            'Vocational qualification': 'Vocational qualification',
            'Bachelor degree': 'Bachelor degree',
            'Masters degree': 'Masters degree',
            'Doctorate or higher': ' Doctorate or higher',
          }}})],1),_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"value":_vm.student.profession,"validation":"required","alternative":"","name":"profession","label":"Profession","placeholder":"Profession","input-classes":"mb-3"}})],1)])]),_c('h4',{staticClass:"heading-small text-muted"},[_vm._v("Contact information")]),_c('div',{staticClass:"pl-lg-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('FormulateInput',{attrs:{"value":_vm.student.address,"disabled":_vm.isLoading,"validation":"required","alternative":"","label":"Address","placeholder":"Home Address","input-classes":"mb-3","name":"address"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"value":_vm.student.city,"validation":"required","alternative":"","label":"City","name":"city","placeholder":"City","input-classes":"mb-3"}})],1),_c('div',{staticClass:"my-2 col-lg-6"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"validation":"required","name":"country","value":_vm.student.country,"alternative":"","label":"Country","placeholder":"Country","input-classes":"mb-3"}})],1)])]),_c('div',[_c('div',{staticClass:"row"},[_c('h4',{staticClass:"text-muted"},[_vm._v("Lesson Information")]),_c('div',{staticClass:"my-2 col-lg-12"},[_c('FormulateInput',{attrs:{"disabled":_vm.isLoading,"validation":"required","alternative":"","label":"What time do you prefer to learn?","placeholder":"Time","type":"time","name":"time_of_the_day","value":_vm.student.preferred_time,"input-classes":"mb-3"}})],1)])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('FormulateInput',{attrs:{"type":"submit","input-class":"btn btn-primary w-100","disabled":_vm.isLoading,"label":_vm.isLoading ? 'Loading...' : 'Update'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }