import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Post from "../views/Post.vue";
import Services from "../views/Services.vue";
import Blog from "../views/Blog.vue";
import notFound from "../views/404.vue";
import Resource from "../views/Resource.vue";

import { adminRoute } from "./routes/admin";
import { authRoute } from "./routes/auth";
import { studentRoute } from "./routes/student";
import { teacherRoute } from "./routes/teacher";
import { registrarRoute } from "./routes/registrar";

// import { USER_ROLE_GUEST,USER_ROLE_STUDENT,USER_ROLE_TEACHER, USER_ROLE_ADMIN, USER_ROLE_REGISTRAR } from "../libs/roles";

export const routes = [
  {
    path: "*",
    component: notFound,
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/services",
    name: "services",
    component: Services,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/post/:id",
    name: "post",
    component: Post,
  },
  {
    path: "/resource/:id",
    name: "resource",
    component: Resource,
  },
  authRoute,
  adminRoute,
  studentRoute,
  teacherRoute,
  registrarRoute,
];
