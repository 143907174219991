var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-announcement","centered":"","hide-footer":"","title":"Create announcement"}},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":"Create Announcement","active":""}},[_c('FormulateForm',{staticClass:"row g-3 needs-validation",on:{"submit":_vm.newAnnouncementA},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"text","name":"title","validation":"required","label":"Announcement title"},model:{value:(_vm.newAnnouncment.title),callback:function ($$v) {_vm.$set(_vm.newAnnouncment, "title", $$v)},expression:"newAnnouncment.title"}}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Body")]),_c('quill-editor',{ref:"myQuillEditor",model:{value:(_vm.newAnnouncment.body),callback:function ($$v) {_vm.$set(_vm.newAnnouncment, "body", $$v)},expression:"newAnnouncment.body"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"students"}},[_vm._v("Students the announcements is for?")]),_c('multiselect',{attrs:{"options":_vm.students,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Pick some","label":"name","track-by":"name","preselect-first":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('div',{staticClass:"pt-3 form-group d-flex justify-content-end"},[_c('FormulateInput',{attrs:{"type":"submit","input-class":"btn btn-primary w-100","disabled":isLoading,"label":isLoading ? 'Loading...' : 'create'}})],1)]}}]),model:{value:(_vm.newAnnouncment),callback:function ($$v) {_vm.newAnnouncment=$$v},expression:"newAnnouncment"}})],1),_c('b-tab',{attrs:{"title":"Create Class"}},[_c('FormulateForm',{staticClass:"row g-3 needs-validation",on:{"submit":_vm.submitHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"text","name":"title","validation":"required","label":"Announcement title"},model:{value:(_vm.newAnnouncment.title),callback:function ($$v) {_vm.$set(_vm.newAnnouncment, "title", $$v)},expression:"newAnnouncment.title"}}),_c('FormulateInput',{attrs:{"type":"datetime-local","name":"startTime","label":"Class Start Time","validation":"required"}}),_c('FormulateInput',{attrs:{"type":"datetime-local","name":"endTime","label":"Class End time","validation":"required"}}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Body")]),_c('quill-editor',{ref:"myQuillEditor",model:{value:(_vm.newAnnouncment.body),callback:function ($$v) {_vm.$set(_vm.newAnnouncment, "body", $$v)},expression:"newAnnouncment.body"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"students"}},[_vm._v("Students the announcement is for? ")]),_c('multiselect',{attrs:{"options":_vm.students,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Pick some","label":"name","track-by":"name","preselect-first":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('div',{staticClass:"pt-3 form-group d-flex justify-content-end"},[_c('FormulateInput',{attrs:{"type":"submit","input-class":"btn btn-primary w-100","disabled":isLoading,"label":isLoading ? 'Loading...' : 'create'}})],1)]}}]),model:{value:(_vm.newAnnouncment),callback:function ($$v) {_vm.newAnnouncment=$$v},expression:"newAnnouncment"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }