<template>
  <div>
    <h4>Announcements</h4>

    <div class="p-0">
      <manage-announcements />
    </div>

    <div class="row">
      <div class="pb-1 col-md-12">
        <h4>Notices</h4>
      </div>
      <div v-if="notices.length != 0" class="col-md-6">
        <div v-for="(notice, index) in notices" :key="index">
          <div class="shadow card">
            <div class="px-4 mx-2 card-body">
              <div class="flex-row d-flex">
                <p class="m-0 display-4">
                  <i class="bi bi-chat-dots-fill"></i>
                </p>
                <div class="p-2">
                  <h4 class="p-0 m-0">{{ notice.title }}</h4>
                  <small class="p-0 m-0 text-secondary">
                    <i class="ri-time-fill"></i> {{ notice.created_at }}</small
                  >
                </div>
              </div>
              <div v-html="notice.body" class="py-3 text-justify"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col-md-12">
        <b-alert variant="warning" show
          ><center>No notices posted yet</center></b-alert
        >
      </div>
    </div>
  </div>
</template>

<script>
import ManageAnnouncements from "../../../components/teacher/manageAnnouncment.vue";
export default {
  components: {
    ManageAnnouncements,
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("teacher/GetNotices");
  },

  computed: {
    notices() {
      return this.$store.state.teacher.notices;
    },
  },
};
</script>

<style>
</style>