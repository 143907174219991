<template>
  <div>
    <div class="pagetitle">
      <h1>Fill Out this form before you begin class</h1>
    </div>
    <div class="row">
      <div class="mb-4 col-md-4">
        <div class="shadow card">
          <div class="card-header">Profile Picture</div>
          <div class="card-body" v-if="!checkUploadImage">
            <div class="">
              <div
                v-show="$refs.upload && $refs.upload.dropActive"
                class="drop-active"
              >
                <h3>Drop files to upload</h3>
              </div>
              <div class="p-2 text-center">
                <label for="avatar">
                  <img
                    :src="
                      files.length
                        ? files[0].url
                        : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
                    "
                    class="rounded-circle img-fluid"
                  />
                  <h4 class="pt-2">or<br />Drop files anywhere to upload</h4>
                </label>
              </div>
              <div class="p-2 text-center" v-show="files.length !== 0">
                <button
                  type="submit"
                  class="mx-2 btn btn-success btn-sm"
                  @click.prevent="confirmProfile"
                >
                  Confirm Profile
                </button>
                <button
                  type="button"
                  class="mx-2 btn btn-danger btn-sm"
                  @click.prevent="cancelUpload"
                >
                  Cancel
                </button>
              </div>
            </div>
            <form enctype="multipart/form-data" @submit="upload">
              <h1>Upload images</h1>
              <div class="dropbox">
                <input
                  type="file"
                  name="Picture"
                  :disabled="isSaving"
                  @change="filesChange($event.target.name, $event.target.files)"
                  accept="image/*"
                  class="input-file"
                />
                <!-- <p> -->
                <!-- Drag your file(s) here to begin<br /> -->
                <!-- or click to browse -->
                <!-- </p> -->
                <!-- <p v-if="isSaving">Uploading {{ fileCount }} files...</p> -->

                <button type="submit">upload</button>
              </div>
            </form>

            <!-- <file-upload
              v-show="files.length == 0"
              extensions="jpg,jpeg,png,webp"
              accept="image/png,image/jpeg,image/webp"
              name="avatar"
              class="d-flex justify-content-center btn btn-primary"
              post-action="/upload/post"
              :drop="!edit"
              v-model="files"
              @input-filter="inputFilter"
              @input-file="inputFile"
              ref="upload"
            >
              Upload Profile Picture
            </file-upload> -->
          </div>
          <div v-else>
            <h1>Image Uploaded</h1>
          </div>
        </div>
      </div>
      <div v-if="checkUploadImage" class="col-md-8">
        <div class="shadow card">
          <div class="card-header">Fill Out Your Information</div>
          <div class="card-body d-flex align-items-start">
            <FormulateForm
              @submit="submitHandler"
              #default="{ isLoading }"
              class="row g-3 needs-validation"
            >
              <h4 class="heading-small text-muted">User information</h4>
              <div>
                <div class="row">
                  <div class="col-lg-4">
                    <FormulateInput
                      name="f_name"
                      validation="required"
                      alternative=""
                      label="First Name"
                      placeholder="First Name"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="col-lg-4">
                    <FormulateInput
                      name="m_name"
                      validation="required"
                      alternative=""
                      label="Father Name"
                      placeholder="Father Name"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="col-lg-4">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      label="Grand Father Name"
                      placeholder="Grand Father Name"
                      input-classes="mb-3"
                      name="l_name"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      type="select"
                      name="sex"
                      label="Sex"
                      validation="required"
                      :options="{
                        '': 'Select your sex',
                        f: 'Female',
                        m: 'Male',
                      }"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      label="Age"
                      name="age"
                      placeholder="Age"
                      input-classes="mb-3"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      type="select"
                      name="education_level"
                      label="Education Level"
                      validation="required"
                      :options="{
                        '': 'Select your education level',
                        'No formal education': 'No formal education',
                        'Primary education': 'Primary education',
                        'Secondary education or high school':
                          'Secondary education or high school',
                        'Vocational qualification': 'Vocational qualification',
                        'Bachelor degree': 'Bachelor degree',
                        'Masters degree': 'Masters degree',
                        'Doctorate or higher': ' Doctorate or higher',
                      }"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      name="profession"
                      label="Profession"
                      placeholder="Profession"
                      input-classes="mb-3"
                    />
                  </div>
                </div>
              </div>
              <!-- Address -->
              <h4 class="heading-small text-muted">Contact information</h4>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      label="Address"
                      placeholder="Home Address"
                      input-classes="mb-3"
                      name="address"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      label="City"
                      name="city"
                      placeholder="City"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      validation="required"
                      name="country"
                      alternative=""
                      label="Country"
                      placeholder="Country"
                      input-classes="mb-3"
                    />
                  </div>
                </div>
              </div>
              <h4 class="text-muted">Lesson Information</h4>
              <div>
                <div class="row">
                  <div class="my-2 col-lg-6">
                    <FormulateInput
                      validation="required"
                      alternative=""
                      label="What time do you prefer to learn?"
                      placeholder="Time"
                      type="time"
                      name="time_of_the_date"
                      input-classes="mb-3"
                    />
                  </div>
                  <div class="my-2 col-lg-6">
                    <label for="lesson_type">What you will learn?</label>
                    <Multi-Select
                      :disabled="isLoading"
                      v-model="lesson_type"
                      label="name"
                      track-by="code"
                      :taggable="true"
                      :options="options"
                      :multiple="true"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} lessons selected</span
                        ></template
                      >
                    </Multi-Select>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <FormulateInput
                  type="submit"
                  input-class="btn btn-primary w-100"
                  :disabled="
                    isLoading || lesson_type == null || lesson_type == ''
                  "
                  :label="isLoading ? 'Loading...' : 'Register'"
                />
              </div>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MultiSelect from "vue-multiselect";
// import FileUpload from "vue-upload-component";
export default {
  components: {
    // FileUpload,
    MultiSelect,
  },
  data() {
    return {
      files: [],
      edit: false,
      options: [
        { name: "Begena", code: "b" },
        { name: "Krar", code: "k" },
        { name: "Masenqo", code: "m" },
      ],
      lesson_type: [],
      isSaving: false,

      f: [],
      status: null,
    };
  },
  created() {
    this.status = this.$store.state.auth.me.profile_url;
    console.log("this.$store.state.auth.me.profile_url");
    console.log(this.$store.state.auth.me.profile_url);
  },
  computed: {
    checkUploadImage() {
      return this.status;
    },
  },
  mounted() {},
  methods: {
    alert(message) {
      alert(message);
    },
    async submitHandler(data) {
      let lessons = this.lesson_type.map((lessonType) => lessonType.code);

      let q = { ...data, lessons, type: "c" };
      this.$store.dispatch("student/getStartStudent", q).then((res) => {
        res;
        this.$router.push({
          name: "classRooms",
        });
        this.$store.dispatch("auth/GetMe").then((res) => {
          res;
          // this.$router.back();
        });
      });
    },
    // eslint-disable-next-line no-unused-vars
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert("Your choice is not a picture");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    confirmProfile() {
      alert("yes");
    },
    cancelUpload() {
      this.$refs.upload.clear();
      this.files = [];
    },
    filesChange(e, f) {
      console.log(f);
      this.f = f;
    },

    upload(e) {
      e.preventDefault();

      let a = new FormData();

      a.append("profileImg", this.f[0]);
      // this.$store.dispatch("student/UploadProfile", a);
      this.$store.dispatch("student/getStarted", a).then((e) => {
        e;
        this.status = true;
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>