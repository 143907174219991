import Vuex from 'vuex';
import utils from './utils'
import student from './student'
import auth from './auth'
import registrar from './registrar'
import teacher from './teacher'
import admin from './admin'
import Vue from 'vue';
Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        utils,
        student,
        auth,
        registrar,
        teacher,
        admin
    }
})

export default store;  