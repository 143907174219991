<template>
  <div>
    <manage-resources />
  </div>
</template>

<script>
import ManageResources from "../../../components/teacher/manageResources.vue";
export default {
  components: {
    ManageResources,
  },
  data() {
    return {
      value: [],
      options: [
        "student 1",
        "student 2",
        "student 3",  
        "student 4",
        "student 5",
      ],
      announcement: {
        title: "ነገ ክፍለጊዜ አይኖረንም!!",
      },
      content: "<h2>There wont be class for tomrrow's section</h2>",
      editorOption: {
        // Some Quill options...
      },
      modules: {
        name: "blotFormatter",
        // module: BlotFormatter,
        options: {},
      },
      fields: ["file_type", "first_name", "Date", "age"],
      items: [
        { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
        { age: 21, first_name: "Larsen", last_name: "Shaw" },
        { age: 89, first_name: "Geneva", last_name: "Wilson" },
      ],
      tableVariants: [
        "primary",
        "secondary",
        "info",
        "danger",
        "warning",
        "success",
        "light",
        "dark",
      ],
      striped: false,
      bordered: false,
      borderless: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      headVariant: null,
      tableVariant: "",
      noCollapse: false,
    };
  },
  methods: {
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
  },
  computed: {
    // resources() {
    //   return this.$store.state.teacher.resources;
    // }
  },

  mounted() {
  },
};
</script>

<style>
</style>
